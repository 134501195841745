function padLeftZero(str: string): string {
    return ('00' + str).substr(str.length)
}

export function formatDate(date: Date, fmt: string): string {
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    let o: Object = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
    }
    Object.keys(o).forEach(k => {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str: string = o[k] + ''
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
        }
    })
    return fmt
}

// 起始时间转为时间戳格式
export function searchDateStart(time: Date | number): number | string {
    if (time) {
        let date: Date = new Date(time)
        date.setHours(0, 0, 0)
        return new Date(date).getTime()
    }
    return ''
}

// 结束时间转为时间戳格式
export function searchDateEnd(time: Date | number): number | string {
    if (time) {
        let date: Date = new Date(time)
        date.setHours(23, 59, 59)
        return new Date(date).getTime()
    }
    return ''
}

// 转成当前对应的时分秒
export function MinuteAndSeconds(time: Date): number | string {
    if (time) {
        let date: string = formatDate(new Date(time), 'yyyy-MM-dd hh:mm:ss')
        return new Date(date).getTime()
    }
    return ''
}

// 时间戳转时间
export function LongToDateTime(time: Date | number): number | string {
    if (time) {
        return formatDate(new Date(time), 'dd/MM/yyyy hh:mm:ss')
    }
    return ''
}

// 时间戳转日期
export function LongToDate(time: Date | number): number | string {
    if (time) {
        return formatDate(new Date(time), 'dd/MM/yyyy')
    }
    return ''
}

// 改变月份
export function changeDate(n: number): void {
    let selectedDate: Date = this.queryParams.minLedgerDate
    if (!selectedDate) {
        selectedDate = new Date()
        selectedDate.setDate(1)
    }
    let firstDate: Date | number
    let lastDate: Date | number
    let month: number = new Date(selectedDate).getMonth() + 1 + n
    let year: number = new Date(selectedDate).getFullYear()
    if (month === 0) {
        month = 12
        year -= 1
    } else if (month === 13) {
        month = 1
        year += 1
    }
    firstDate = selectedDate.setFullYear(year)
    firstDate = selectedDate.setMonth(month - 1)
    firstDate = selectedDate.setDate(1)
    let myDate: Date = new Date(year, month, 0)
    lastDate = selectedDate.setFullYear(year)
    lastDate = selectedDate.setMonth(month - 1)
    lastDate = selectedDate.setDate(myDate.getDate())
    if (lastDate > new Date().getTime()) {
        lastDate = new Date().getTime()
    }

    this.queryParams.minLedgerDate = new Date(firstDate)
    this.queryParams.maxLedgerDate = new Date(lastDate)
    this.search()
}

/**
* 将时间戳转换为语义化年月日
* @param datetime             要转换的值
* @param lang  {String}       语言环境
* @param simple {Boolean}     日期格式
* @returns      {string}  格式化后的字符串 | empty
*/
export function stringifyDateFilter(datetime, lang, simple = false) {
    let weekMap
    let today
    let yesterday
    let monthMap
    if (lang === 'id') {
        weekMap = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']
        monthMap = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'Nopember', 'Desember']
        today = 'Hari ini'
        yesterday = 'Kemarin'
    } else if (lang === 'th-TH') {
        weekMap = ['วันอาทิตย์', 'วันจันทร์', 'วันอังคาร', 'วันพุธ', 'วันพฤหัสบดี', 'วันศุกร์', 'วันเสาร์']
        monthMap = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม']
        today = 'วันนี้'
        yesterday = 'เมื่อวาน'
    } else if (lang === 'zh') {
        weekMap = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
        monthMap = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
        today = '今天'
        yesterday = '昨天'
    } else if (lang === 'fil') {
        weekMap = ['Linggo', 'lunes', 'martes', 'martesday', 'huwebes', 'Biyernes', 'sabado']
        monthMap = ['Enero', 'pebrero', 'Marso', 'april', 'maaari', 'Si Jun', 'july', 'Agosto', 'september', 'Oktubre', 'Nobyembre', 'Disyembre']
        today = 'Ngayon'
        yesterday = 'Kahapon'
    } else if (lang === 'ms') {
        weekMap = ['hari ahad', 'isnin', 'selasa', 'hari rabu', 'khamis', 'jumaat', 'sabtu']
        monthMap = ['januari', 'Februari', 'Mac', 'april', 'mungkin', 'Jun', 'julai', 'ogos', 'september', 'oktober', 'november', 'disember']
        today = 'Hari ini'
        yesterday = 'Semalam'
    } else if (lang === 'vi') {
        weekMap = ['chủ nhật', 'thứ hai', 'thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'ngày thứ bảy']
        monthMap = ['tháng Giêng', 'tháng 2', 'tháng Ba', 'tháng tư', 'có thể', 'Tháng sáu', 'tháng bảy', 'tháng Tám', 'Tháng Chín', 'Tháng Mười', 'tháng mười một', 'tháng mười hai']
        today = 'Hôm nay'
        yesterday = 'Hôm qua'
    } else if (lang === 'ko') {
        weekMap = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일']
        monthMap = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월']
        today = '오늘'
        yesterday = '어제'
    } else {
        weekMap = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        monthMap = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        today = 'Today'
        yesterday = 'Yesterday'
    }
    datetime = new Date(datetime)
    let year = datetime.getFullYear()
    let simpleYear = datetime.getYear() - 100
    let month = datetime.getMonth() + 1
    let monthFormat = monthMap[month - 1]
    month = month > 9 ? month : '0' + month
    let day = datetime.getDate()
    day = day > 9 ? day : '0' + day
    let hour = datetime.getHours()
    hour = hour > 9 ? hour : '0' + hour
    let min = datetime.getMinutes()
    min = min > 9 ? min : '0' + min
    let week = datetime.getDay()
    week = weekMap[week]
    let thatDay = (new Date(year, month - 1, day, 0, 0, 0)).getTime()
    if (simple) {
        return {
            withYear: `${day}/${month}/${simpleYear}`,
            withMonth: `${month}-${day}`,
            withDay: `${week}`,
            withLastDay: `${yesterday}`,
            withHour: `${hour}:${min}`,
            withNoWeekAll: `${monthFormat} ${day}, ${year} ${hour}:${min}`,
            withAll: `${monthFormat} ${day}, ${year}(${week}) ${hour}:${min}`,
            thatDay,
            monthMap,
        }
    }
    return {
        withYear: `${day}/${month}/${year} ${hour}:${min}`,
        withMonth: `${month}-${day} ${hour}:${min}`,
        withDay: `${week} ${hour}:${min}`,
        withLastDay: `${yesterday} ${hour}:${min}`,
        withHour: `${today} ${hour}:${min}`,
        withNoWeekAll: `${monthFormat} ${day}, ${year} ${hour}:${min}`,
        withAll: `${monthFormat} ${day}, ${year}(${week}) ${hour}:${min}`,
        thatDay,
        monthMap,
    }
}

// 获取倒计时小时数
export function getCountdownHours(validityPeriod, nowData = new Date(), timeZone = 0) {
    let time: number = validityPeriod - new Date(nowData).getTime()
    if (time > 0) {
        time = parseInt(String(time / 1000), 10) // 相差的秒数
        let hours: number = 0
        let minutes: number = 0
        let seconds: number = 0
        if (time > 3600) {
            hours = Math.floor(time / 60 / 60);
            minutes = Math.floor(time / 60 - (60 * hours))
            seconds = Math.round(time - (60 * 60 * hours) - (60 * minutes))
            return (+hours > 9 ? hours : '0' + hours) + ':' + (+minutes > 9 ? minutes : '0' + minutes) + ':' + (+seconds > 9 ? seconds : '0' + seconds)
        }
        if (time > 60 && time <= 3600) {
            minutes = Math.floor(time / 60)
            seconds = Math.round(time - (60 * minutes))
            return '00:' + (+minutes > 9 ? minutes : '0' + minutes) + ':' + (+seconds > 9 ? seconds : '0' + seconds)
        }
        seconds = Math.round(time)
        return '00:00' + ':' +(+seconds > 9 ? seconds : '0' + seconds)
    }
    return '00:00:00'
}


// 获取倒计时天数
export function countDown(time) {
    var nowTime = +new Date(); // 返回的是当前时间总的毫秒数
    var inputTime = +new Date(time); // 返回的是用户输入时间总的毫秒数
    var times = (inputTime - nowTime) / 1000; // times是剩余时间总的秒数
    var d = parseInt(times / 60 / 60 / 24); // 把秒数转换成天数  （parselnt把得到的数转换为整数）
    d = d < 10 ? '0' + d : d;//这里为了让时间数好看一点，比如把4天改成04天，所以加了三元判定，下面也是如此；
    var h = parseInt(times / 60 / 60 % 24); //时
    h = h < 10 ? '0' + h : h;
    var m = parseInt(times / 60 % 60); //分
    m = m < 10 ? '0' + m : m;
    var s = parseInt(times % 60); // 秒
    s = s < 10 ? '0' + s : s;
    return {
        d,
        h,
        m,
        s
    }
}