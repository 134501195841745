try {
    window.localStorage.getItem('yunxinAccountInfo')
} catch (e) {
    global.window = {
        document: {documentElement: {}},
        addEventListener() {
        },
        removeEventListener() {
        },
    }
    global.document = {}
}

export const state = () => ({
    // 常量start
    locales: ['en-TH', 'en', 'th-TH', 'id', 'ms', 'zh', 'vi', 'fil', 'ko'],    // 语言列表
    paymentMethodMap: {                // 支付方式id和信息映射对象
        1: {
            id: 1,
            label: 'label_credit_term_payment',
            desc: 'label_credit_payment_desc',
        },
        2: {
            id: 2,
            label: 'label_pay_on_delivery',
            desc: 'label_pay_on_delivery_desc',
        },
        3: {
            id: 3,
            label: 'label_pay_on_delivery',
            desc: 'label_pay_on_delivery_desc',
        },
        4: {
            id: 4,
            label: 'label_pay_on_delivery',
            desc: 'label_pay_on_delivery_desc',
        },
        5: {
            id: 5,
            label: 'label_pay_on_delivery',
            desc: 'label_pay_on_delivery_desc',
        },
        6: {
            id: 6,
            label: 'label_pay_before_delivery',
            desc: 'label_pay_before_delivery_desc',
        },
        7: {
            id: 7,
            label: 'label_credit_card',
            desc: 'label_pay_online_desc',
        },
        8: {
            id: 8,
            label: 'label_pay_counter',
            desc: 'label_pay_counter_desc',
        },
    },
    expectedDeliveryTimeMap: {      // 可配送时间id和信息映射对象
        1: 'label_express',
        2: 'label_express',
        3: 'label_normal',
    },
    orderStatusMap: {           // 订单状态映射对象
        CREATED: 'label_order_created',
        CONFIRMED: 'label_order_confirmed',
        INVOICED: 'label_order_shipped',
        SETTLED: 'label_order_received',
        CANCELLED: 'label_order_cancelled',
        PACKED: 'label_order_packed',
        PICKED: 'label_order_packing',
        SHIPPED: 'label_order_delivered',
        RETURN_GOODS: 'label_return_goods',
        CANCEL_APPLYING: 'label_cancel_applying',
        CANCEL_REFUSED: 'label_cancel_refused',
    },
    orderThreeThirdStatusMap: {           // 订单状态映射对象
        CREATED: 'label_order_created',
        CONFIRMED: 'label_order_confirmed',
        CANCELLED: 'label_order_cancelled',
        SHIPPED: 'label_order_shipped',
        RECEIVED: 'label_order_received',
        COMPLETED: 'label_order_completed',
        CANCEL_APPLYING: 'label_cancel_applying',
        CANCEL_REFUSED: 'label_cancel_refused',
    },
    partsTypeFilter: [  // IAM/OE过滤选项
        {
            value: '',
            label: 'label_all',
        },
        {
            value: 'OE',
            label: 'label_OE',
        },
        {
            value: 'IAM',
            label: 'label_IAM',
        },
    ],
    priceSortFilter: [
        { // 价格排序过滤项
            value: 0,
            label: 'label_default',
        },
        {
            value: 1,
            label: 'label_HToL',
        },
        {
            value: 2,
            label: 'label_LToH',
        },
    ],
    cancelOrderStatus: {
        CREATE: 'label_cancel_step1',
        PENDING: 'label_cancel_step2',
        REFUSED: 'label_cancel_refused',
        FINISH: 'label_cancel_step3',
        CANCEL: 'label_cancel_step4',
    },
    // 常量end
    langCode: 'th-TH',              // 当前语言
    cookie: '',                     // 网站cookie，用于区分是否登录
    userId: '',
    userInfo: {},                   // 其中verified字段含义：0是B客户  1是C客户未认证  2是C客户已认证
    token: '',
    countryId: 1001,
    countryCode: 'TH',
    countryCurrency: '',             // 国家货币符号
    currentCountry: {},              // 当前国家
    loginModalVisible: false,
    verifyModalVisible: false,
    guideVerifyModalVisible: false, // 引导认证为B客户弹框，首次注册用户登录成功后弹出
    protocolModalVisible: false,    // 协议弹窗
    isChangePassword: false,        // 是否是修改密码场景
    protocolList: [],               // 协议列表
    hasUpdatedProtocol: false,      // 是否有协议更新
    selectedProtocolTermsType: '',    // 要查看的协议对象
    selectedPolicyId: '',            // 具体的协议编号
    curOrderProcedure: 1,           // 当前订单流程：1->购物车; 2->下单页; 3->下单完成; 4->支付信息
    priceItem: {},                  // 当前的priceId信息
    curCartQuantity: 0,             // 当前购物车数量
    hotLineMap: {
        1001: '02-0263298',
        1102: '62-2130056517',
        1171: '02-0263298',
        1192: '02-0263298',
        1129: '02-0263298',
        1234: '028-54329068 – EXT: 144',
    },
    contactEmailMap: {
        1001: 'customerservice@whats-egg.com',
        1102: 'customerservice.id@whats-egg.com',
        1171: 'customerservice@whats-egg.com',
        1192: 'customerservice@whats-egg.com',
        1129: 'customerservice@whats-egg.com',
        1234: 'Customerservice.vn@whats-egg.com',
        1198: 'info.aakorea@kr.bosch.com',
    },
    unreadNim: null,
    yunxinAccountInfo: {},          // 云信账号信息
    chatUnread: 0,
    is3PScene: false,               // 是否是从repair-web跳转进入eggmall-web,走3P库存搜索逻辑场景
    urlToken: '',
    isQuotationOrder: false, // 是否是Quotation转订单
    quotationParams: null, // Quotation转订单所需参数
    acceptCookiesStatus: false, // 是否接受cookies条款
    protocolListNet: [],
    cusotmerUUID: '',
    canUseWebpFormat: false, // 判断浏览器是否支持webp格式
    loginAddressVisible: false, // 地址登录弹窗
    firstLogin: true, // 是否第一次登录
    isAgreePolicy: true,
    subscribeDialog: false, // knowledge的订阅弹窗,
    isShowFAQ: false,
    loginAuditResultVisible: false, // bosch注册审查结果,
    countryList: [],
    auditStatus: false,
    applyAgainData: {},
    isRepeatApply: false,
    landingPageInfo: {
        totalAmount: 0,
        totalDiscount: 0
    },
    openSearchKeywords: false,
    oePartsBrand: {},
    searchFeedbackModalVisible: false,
    searchFeedbackContent: {},
    customerType: 'Consumer',
})

// export const getters = {
//     currency: states => {
//         return states.countryId == 1001 ? '฿' : 'Rp'
//     },
// }
