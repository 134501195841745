

































































































































































































































































































































import {
    Vue, Component, Watch,
} from 'vue-property-decorator'
import {State} from 'vuex-class'
import filter from '~/utils/filter'
import '~/utils/directive'
import api from '~/api'
import CommonHeader from '~/components/common-header.vue'
import CommonFooter from '~/components/common-footer.vue'
import common from '~/utils/common.ts'
import Cookies from 'js-cookie'
import storage from '~/utils/storage'
import policyData from '~/utils/policy.ts'
import utils from '~/utils'

Object.keys(filter).forEach((k: any): void => {     // 定义全局过滤器
    Vue.filter(k, filter[k])
})

function generateUUID() {
    let d = new Date().getTime()
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        let r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
    return uuid
}

@Component(<any>{
    components: {
        CommonHeader,
        CommonFooter,
    },

    head(vm) {
        const head: any = {
            script: [],
            noscript: [],
        }
        if (process.env.CUSTOM_ENV === 'production') {
            const googleTagManagerScript = `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-TCBCD4Z');
            `
            const googleTagManagerNoScript = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TCBCD4Z" height="0" width="0" style="display:none;visibility:hidden">'
            const googleGA4Script = `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-J2N8GTHN1M');
                gtag('config', 'AW-11190145782');
            `
            const tiktokPixel = `!function (w, d, t) { w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)}; ttq.load('CHS0QHJC77U441D0HK60'); ttq.page(); }(window, document, 'ttq');`
            head.script.push({
                innerHTML: `window.googleCountryId = ${+vm.$store.state.countryId}`,
                type: 'text/javascript',
                charset: 'utf-8',
            }, {
                innerHTML: googleTagManagerScript,
                type: 'text/javascript',
                charset: 'utf-8',
            }, {
                innerHTML: googleGA4Script,
                type: 'text/javascript',
                charset: 'utf-8',
            }, {
                innerHTML: tiktokPixel,
                type: 'text/javascript',
                charset: 'utf-8',
            })
            head.noscript.push({
                innerHTML: googleTagManagerNoScript,
                pbody: true,
            })
        } else { // 避免其他环境报错
            head.script.push({
                innerHTML: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                `,
                type: 'text/javascript',
                charset: 'utf-8',
            })

            // 本地开发调试GA4容器
            // const googleGA4Script = `
            //     window.dataLayer = window.dataLayer || [];
            //     function gtag(){dataLayer.push(arguments);}
            //     gtag('js', new Date());
            //     gtag('config', 'G-66VV5BNC3V');
            // `
            // head.script.push({
            //         src: 'https://www.googletagmanager.com/gtag/js?id=G-66VV5BNC3V',
            //         async: true,
            //         type: 'text/javascript',
            //         charset: 'utf-8',
            //     }, {
            //         innerHTML: googleGA4Script,
            //         type: 'text/javascript',
            //         charset: 'utf-8',
            // })
        }
        // console.log(head)
        return head
    },
})
export default class Root extends Vue {
    @State('langCode') langCode

    @State('userInfo') userInfo

    @State('token') token

    @State('chatUnread') chatUnread

    @State('curCartQuantity') curCartQuantity

    @State('loginModalVisible') loginModalVisible

    @State('acceptCookiesStatus') acceptCookiesStatus

    @State('isAgreePolicy') isAgreePolicy

    @State('isShowFAQ') isShowFAQ

    @State('countryCurrency') currency

    @State('landingPageInfo') landingPageInfo

    @State('countryId') countryId

    watchQuery: boolean = true

    queryInterval:any = null

    protocolList:any = []

    selectedPolicyId:any

    termVis:boolean = false

    showPopTips:boolean = true

    loginApiVerified: number = 0

    getPoints: number = 0

    showCalculatePromotion: boolean = false

    koPolicy: any = []

    showRightNav = true

    get currentTab() {
        let tab = 'cart'
        const name = this.$route.name || '';
        if (name.startsWith('orderProcedurePage___')) {
            tab = 'cart'
        } else if (name.startsWith('orderCouponPage___')) {
            tab = 'coupon'
        } else if (name.startsWith('myFavoriteListPage___')) {
            tab = 'favorite'
        } else if (name.startsWith('myPointPreview___') || name.startsWith('redeemDetail___') || name.startsWith('myPointTH___')) {
            tab = 'point'
        } else if (name.startsWith('FAQDetail___')) {
            tab = 'FAQ'
        } else if (/^\/[^\/]*\/order\//.test(this.$route.path)) {
            tab = 'order'
        }
        return tab
    }

    created() {
        if ((<any> process).client) {
            // 未登录的游客标识
            let cusotmerUUID = localStorage.getItem('cusotmerUUID') as any
            
            if (!cusotmerUUID) {
                cusotmerUUID = generateUUID()
                localStorage.setItem('cusotmerUUID', cusotmerUUID)     
            }
            this.$store.commit('SET_CUSOTMER_UUID', cusotmerUUID)
            // 在页面加载完成前同步lang.js里的countryId/countryCode组合,防止在客户端的请求中传参错误
            // 未知问题：created里面store.commit会导致页面报错
            this.$store.commit('INIT_DATA')
            if (!common.getToken()) {
                // 这里再运行
                const temp = window.localStorage.getItem('acceptCookiesStatus') || false
                const tempList = window.localStorage.getItem('protocolList')
                const tempId = window.localStorage.getItem('selectedPolicyId')
                const tempUUID = window.localStorage.getItem('cusotmerUUID')
                window.localStorage.clear()
                window.localStorage.setItem('acceptCookiesStatus', temp)
                window.localStorage.setItem('protocolList', tempList)
                window.localStorage.setItem('selectedPolicyId', tempId)
                window.localStorage.setItem('cusotmerUUID',tempUUID)
                common.removeAccountInfo()
            }

            Cookies.remove('urlToken', { path: '' })
            if (this.$store.state.urlToken) {
                let token = this.$store.state.urlToken.replace(/%20/g, '%2B')
                this.$store.commit('SET_LOGIN_TOKEN', token)  // url上获取的参数需要转义后传给后端
                this.$store.dispatch('setUserId')
                api.client_profile().then(userData => {
                    this.$store.commit('SET_LOGIN_USERINFO', userData.data.profile)
                    this.$store.commit('INIT_DATA')
                    // this.$cookies.remove('urlToken') // res.setHeader('Set-Cookie')设置cookie会指定path,cookie-universal-nuxt插件remove所有path下的cookie不方便
                    // window.location.reload()
                }).catch(() => {
                    this.$notify({
                        title: this.$t('tips_error'),
                        message: 'Login failed !',
                        type: 'error',
                        position: 'top-right',
                    })
                }).finally(() => {
                })
            } else {
                this.$store.commit('INIT_DATA')
            }
            if(this.$route.query.isFromRepair && this.$route.query.firstEntry === 'true'){
                this.$store.commit('SET_LOGIN_TOKEN', encodeURIComponent(this.$route.query.token)) 
            }
        }
    }

    mounted() {
        this.showRightNav = ['0', 0].includes(this.$route?.query?.showRightNav) ? false : true
        const goPage = ['privacyPolicy','termsAndConditions','shippingPolicy','warrantyPolicy',
        'returnPolicy','products/details','knowledgeCenter','FAQDetail','ConsentToCollectionAndUseOfPersonalInformation',
        'ConsentToOverseasAndUseOfPersonalInformation']
        let isStayCurrentPage = false
        goPage.map((item)=>{
            if(this.$route.path && this.$route.path.includes(item) || this.$route.name && this.$route.name.includes('index')){
                isStayCurrentPage = true
            }
        })
        api.get_customer_policy_status().then((res)=>{
            this.$store.commit('SET_POLICY_STATUS', res.data)
            if((!isStayCurrentPage && !this.isAgreePolicy)){
                this.$router.push(this.localePath({ name: 'index' }))
            }
        })

        // 先判断是否同意标识,如果是非首页/非商品详情页/ 非协议页面就跳转到首页
        if(this.$route.fullPath.includes('newPromotion.html') && this.token){
            api.get_customer_discount({promotionLandingPageId:this.$route.query.id}).then((res)=>{
                if(res.data.totalAmount){
                    this.showCalculatePromotion = true
                    this.$store.commit('SET_LANDING_PAGE_INFO',res.data)
                }
            }) 
        }else{
            this.showCalculatePromotion = false
        }
        
        
        console.log(process.env.NODE_ENV)
        console.log(process.env.CUSTOM_ENV)
        // this.setCurrentCountry()
        storage.setLocal('currentCountry', this.$store.state.currentCountry)
        this.$store.dispatch('updateCartQuantity')
        if(this.token){
            if(sessionStorage.getItem('showPopTips') == 'no'){
                this.showPopTips = false
            }
            // 登录状态重新刷新登录信息接口
            api.get_logined_info().then(res=>{
                this.$store.commit('SET_CUSTOMER_TYPE', res.data.customerTypeName)
            })
        }
        this.loginApiVerified = Number(localStorage.getItem("loginApiVerified")) || 0
        if(this.loginApiVerified != 0 && this.token){
            api.get_points_behavior({type:11}).then((res)=>{
                this.getPoints = res.data
            })
        }

        // 未登录，登录之后要跳转到购物车的情况
        // 先判断是否alai,再判断是否跳到购物车/是否登录
        if(this.$route.query.source && this.$route.query.source == 'Alai'){
            // 存到session,0是首页，1是购物车页面，2是搜索页面
            sessionStorage.setItem('source', 'Alai')
            if(this.token){
                let alaiUserId = this.$route.query.userId
                let eggmallUserId = this.$store.state.userId
                if(alaiUserId == eggmallUserId || !alaiUserId){
                    switch (this.$route.query.targetPage){
                        case '2':  
                            this.$router.push(this.localePath({ name: 'orderProcedurePage' }))
                            break;
                        case '3': 
                            let externalParams = JSON.parse(this.$route.query.externalParams)
                            let keywords = externalParams.keywords
                            let query = {
                                searchResultInfo: JSON.stringify({keywords}),
                                oeNumberOrBrandSku: keywords,
                                isAccurateSearch: true,
                                brandId: externalParams.brandId
                            }

                            this.$router.push(this.localePath({
                                name: 'multiConditionSearchPage',
                                query
                            }))
                            break;
                    }
                }else{
                    // 给个提示
                    this.$message.warning({
                        message: this.$t('label_alai_login_tips'),
                        duration: 6000
                    })
                }
            }

            if(!this.token){
                this.$store.commit('SET_LOGIN_MODAL', true);
                switch (this.$route.query.targetPage){
                    case '2': 
                        sessionStorage.setItem('targetPage','2')
                        break;
                    case '3': 
                        sessionStorage.setItem('targetPage','3')
                        break;
                    default:  
                        break;
                }
            }
        }

    }

    i18nReplace(s, o) {
        return utils.i18nReplace(s, o)
    }
    gotoCouponList() {
        if(!this.isAgreePolicy){
            return
        }
        if (!this.token) {
            this.$store.commit('SET_LOGIN_MODAL', true)
            return
        }
        if (this.$route.name.indexOf('orderCouponPage') > -1) {
            window.location.reload()
        } else {
            this.$router.push(this.localePath({ name: 'orderCouponPage' }))
        }
    }

    gotoShoppingCart() {
        if(!this.isAgreePolicy){
            return
        }
        if (this.$route.name.indexOf('orderProcedurePage') > -1 && !this.$store.state.isQuotationOrder) {
            window.location.reload()
        } else {
            this.$store.commit('SET_IS_QUOTATION_ORDER_STATUS', false)
            this.$router.push(this.localePath({ name: 'orderProcedurePage' }))
        }
        this.$store.commit('SET_CUR_ORDER_STEP', 1)
    }

    gotoOrderList() {
        if(!this.isAgreePolicy){
            return
        }
        if (!this.token) {
            this.$store.commit('SET_LOGIN_MODAL', true)
            return
        }
        if (this.$route.name.indexOf('orderListPage') > -1) {
            window.location.reload()
        } else {
            this.$router.push(this.localePath({ name: 'orderListPage' }))
        }
    }

    gotoFavoriteList() {
        if(!this.isAgreePolicy){
            return
        }
        if (!this.token) {
            this.$store.commit('SET_LOGIN_MODAL', true)
            return
        }
        if (this.$route.name.indexOf('myFavoriteListPage') > -1) {
            window.location.reload()
        } else {
            this.$router.push(this.localePath({ name: 'myFavoriteListPage' }))
        }
    }

    gochat(){
        if(!this.isAgreePolicy){
            return
        }
        this.$gochat()
    }

    backToTop() {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
    }

    @Watch('langCode')
    onLangCode(val) {
        this.$i18n.locale = val
    }

    @Watch('token', { immediate: true })
    onToken(newVal) {
        if (newVal && !(<any> process).server) {
            if (window.dataLayer) {
                window.dataLayer.push({'userId': this.$store.state.userId})
            }
            api.get_updated_list_agreement().then(res => {
                let data = res && res.data || []
                if (data.length > 0 && !['0', 0].includes(this.$route.query.showRightNav)) {
                    this.$store.commit('SET_PROTOCOL_LIST_NET', data)
                    this.$store.commit('SET_PROTOCOL_MODAL', true)
                    this.$store.commit('SET_PROTOCOL_UPDATED_FLAG', true)
                    // this.$store.commit('SET_SELECTED_PROTOCOL_ID', data[0].termsConditionList[0].termsType)
                }
            })
            api.get_yunxin_info().then(res => {
                this.$store.commit('updateAccountInfo', res.data)
                this.$store.dispatch('connectUnreadNim')
            })
            api.get_common_config().then(res => {
                this.$store.commit('SET_LOGIN_USERINFO', {
                    isTestAccount: res.data.testUserIdList.includes(this.$store.state.userId),
                })
            })
        }
    }

    @Watch('userInfo', { deep: true, immediate: true })
    onUserInfo(val, oldVal) {
        if (!this.token) {
            clearInterval(this.queryInterval)
            return
        }
        if (val && val.userId && val.shopAlliance !== 'BOSCH') {
            this.$store.commit('SET_3P_SCENE', false)
        }
        // 注册后的用户未设置密码时需要弹出设置密码弹窗,登录成功后会reload页面,需要存储到storage中标志是否需要弹出弹窗
        if (val && val.passwordStatus == 2 && window.localStorage.getItem('setUpBox') && !val.triggerByLogin) {
            this.$store.commit('SET_LOGIN_MODAL', true)
        }
        // 未认证的C客户引导认证成B客户：!val.triggerByLogin && !oldVal.userId这两个条件是为了防止在登录弹窗中修改userInfo但还未重载前触发引导认证弹窗出现
        // if (val && val.loginApiVerified == 1 && !this.$store.state.loginModalVisible && window.localStorage.getItem('guideVerifyBox') !== 'hide' && !val.triggerByLogin && !oldVal.userId) {
        //     this.$store.commit('SET_GUIDE_VERIFY_MODAL', true)
        // }
        if (val && val.verified == 2 && this.queryInterval === null) {
            this.queryInterval = setInterval(() => {
                api.client_profile().then(userData => {
                    if (userData.code == '401') {
                        clearInterval(this.queryInterval)
                        this.$notify({
                            title: this.$t('tips_warning'),
                            message: this.$t('label_invalid_logon_tip'),
                            type: 'warning',
                            position: 'top-right',
                        })
                        api.client_auth_logout().then(() => {
                            this.$store.commit('CLEAR_DATA', this)
                        })
                    } else {
                        // this.$store.commit('SET_LOGIN_USERINFO', userData.data.profile)
                    }
                })
            }, 5000)
        }
    }

    @Watch('$route')
    checkPath(newVal){
        if(newVal.fullPath.includes('newPromotion.html') && this.token){
            api.get_customer_discount({promotionLandingPageId:this.$route.query.id}).then((res)=>{
                if(res.data.totalAmount){
                    this.showCalculatePromotion = true
                    this.$store.commit('SET_LANDING_PAGE_INFO',res.data)
                }
            }) 
        }else{
            this.showCalculatePromotion = false
        }
    }

    hideCookiesTerm() {
        if(this.countryId == 1198){
            if(this.koPolicy.length != 3){
                this.$message.warning(this.$t('label_policy_tips_3'))
            }else{
                api.policy_agree({isAgree: 1 }).then(()=>{
                    this.$store.commit('SET_SELECTED_POLICY_ID', true)
                    window.location.reload()
                })      
            }
        }else{
            api.policy_agree({isAgree: 1 }).then(()=>{
                this.$store.commit('SET_SELECTED_POLICY_ID', true)
                window.location.reload()
            })
        }
    }

    hideCookiesTermNo(){
        this.$store.commit('SET_POLICY_STATUS', true)
        this.termVis = true
    }

    rejectPolicy() {
        this.termVis = false
        window.location.href = 'about:blank'
    }

    acceptPolicy(){
        this.$store.commit('SET_POLICY_STATUS', false)
        this.termVis = false
        this.hideCookiesTerm()
    }

    showProtocolModal(termsType) {
        if(this.$store.state.countryId == 1234){
            this.$router.push(this.localePath({
                name: "vietnamPolicy",
                query: {
                    selectedPolicyId: termsType == 13 ? 1 : 2
                },
            })) 
            return
        }
        let name
        const res = policyData
        this.$store.commit('SET_PROTOCOL_LIST', res && res.data || [])
        this.$store.commit('SET_SELECTED_PROTOCOL_ID', termsType)
        this.$store.commit('SET_SELECTED_POLICY_ID', termsType + '')
        this.protocolList = res.data
        this.selectedPolicyId = termsType
        // 存到localstorage
        window.localStorage.setItem('protocolList', JSON.stringify(this.protocolList))
        window.localStorage.setItem('selectedPolicyId', termsType)
        switch(termsType){
            case 7:
                name = 'privacyPolicy'
                break 
            case 27:
                name = 'ConsentToCollectionAndUseOfPersonalInformation'
                break 
            case 28:
                name = 'ConsentToOverseasAndUseOfPersonalInformation'
                break 
            default:
                name = 'termsAndConditions'
                break               
        }
        this.$router.push(this.localePath({
            name,
        }))
}
    closeTipsPop(){
       sessionStorage.setItem('showPopTips','no')
       this.showPopTips = false
    }
    gotoVerify(){
        api.update_log_toB().then(res => {
            if (+res.code === 200) {
                this.$store.commit('SET_VERIFY_MODAL', true)
            }
        })
    }
    handleClose(){
        this.termVis = false
        this.$store.commit('SET_POLICY_STATUS', false)
    }
    jumpToFAQDetail(){
        this.$router.push(this.localePath({
            name: 'FAQDetail',
        }))
    }
    jumpToPointDetail(){
        if(!this.isAgreePolicy){
            return
        }
        if (!this.token) {
            this.$store.commit('SET_LOGIN_MODAL', true)
            return
        }
        if(this.$store.state.countryId == 1198){
            this.$router.push(this.localePath({
                name: 'myPoint',
            }))   
        }else{
            this.$router.push(this.localePath({
                name: 'myPointPreview',
            }))   
        }
    }
    addGA4Event(){
        utils.ga4('event', 'conversion', {
            send_to: 'AW-11190145782/z-mMCKyskqMYEPal8Ncp',
        })
    }
}
