






    import {
        Vue, Component, Prop,
    } from 'vue-property-decorator'
    import {State} from 'vuex-class'

    function isInViewPort(el) {
        if (!el) return false
        const viewPortWidth = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth
        const viewPortHeight = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight
        const rect = el.getBoundingClientRect()
        if (rect) {
            const {
                top, bottom, left, right,
            } = rect
            return (
                bottom > 0
                && top <= viewPortHeight
                && left <= viewPortWidth
                && right > 0
            )
        }
        return false
    }

    const imageIsExist = url => new Promise(resolve => {
        let img = <any> new Image()
        img.onload = () => {
            if (img.complete === true) {
                resolve(true)
            }
        }
        img.onerror = () => {
            resolve(false)
        }
        img.src = url
    })

@Component
    export default class ImgLazyLoad extends Vue {
        @Prop({required: true}) defaultImgSrc!: string

        @Prop({required: true}) alt!: string

        @Prop({required: true}) src!: string

        @State('imgIdCount') imgIdCount

        isLoaded: boolean = false

        async mounted() {
            if (isInViewPort(this.$el) && !this.isLoaded) {
                if (this.src) {
                    let exist = await imageIsExist(this.src)
                    if (exist) {
                        this.$el.setAttribute('src', this.src)
                        this.isLoaded = true
                    }
                }
            }
            const observer = new IntersectionObserver(async entries => {
                entries.forEach(async entry => {
                    if (entry.isIntersecting && !this.isLoaded) {
                        if (this.src) {
                            let exist = await imageIsExist(this.src)
                            if (exist) {
                                this.$el.setAttribute('src', this.src)
                                this.isLoaded = true
                            }
                            observer.unobserve(this.$el)
                            observer.disconnect()
                        }
                    }
                })
            })
            observer.observe(this.$el)
        }
    }
