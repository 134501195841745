<template>
  <div>
	<p style="margin-bottom:5.0000pt;text-align:center;background:rgb(255,255,255);"><strong><span style="font-family:Helvetica;color:rgb(56,56,56);font-weight:bold;font-size:16px;">Warranty Policy</span></strong></p>
	<p style="margin-bottom:5.0000pt;text-align:center;background:rgb(255,255,255);"><span style="font-family:'PingFang SC';color:rgb(166,166,166);font-size:16px;">Updated</span><span style="font-family:Tahoma;color:rgb(166,166,166);font-size:16px;">:</span><span style="font-family:Calibri;color:rgb(166,166,166);font-size:16px;">23-08-2020</span></p>
	<p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;">EGGMall is the open market place. Each SHOP is the representative of product brand owner.&nbsp;</span></p>
	<p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;">To be eligible for them claim, please ensure the follow:</span></p>
	<ul style="list-style-type: undefined;margin-left:26px;">
		<li><span style="font-family:Symbol;color:rgb(56,56,56);font-size:13px;;color:rgb(56,56,56);">&middot;&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;color:rgb(56,56,56);">Different brands will have different warranty policy, Seller will claim back to brand owner after they inspect the products.</span></li>
		<li><span style="font-family:Symbol;color:rgb(56,56,56);font-size:13px;;color:rgb(56,56,56);">&middot;&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;color:rgb(56,56,56);">Period of claim and conditions are align and within brand&rsquo;s claim policy.</span></li>
		<li><span style="font-family:Symbol;color:rgb(56,56,56);font-size:13px;;color:rgb(56,56,56);">&middot;&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;color:rgb(56,56,56);">No misuse of the products.</span></li>
		<li><span style="font-family:Symbol;color:rgb(56,56,56);font-size:13px;;color:rgb(56,56,56);">&middot;&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;color:rgb(56,56,56);">Attach invoice or delivery note of the goods to be claimed.</span></li>
	</ul>
	<p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;">Please contact seller directly for warranty claim. You will be responsible for paying for your own shipping costs.&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;background:rgb(255,255,255);">Once supplier receive your item for inspection, we will keep you updated for the process and result</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;background:rgb(255,255,255);">&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;background:rgb(255,255,255);">a</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;background:rgb(255,255,255);">fter the claim is approved.&nbsp;</span></p>
	<p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;background:rgb(255,255,255);">If you purchase from WhatsEGG SHOP, please provide the claims with above mentioned documents via EGGMall chat, we will advise you the warranty claim result within 30 working days.&nbsp;</span></p>
	<p><span style="font-family:Calibri;font-size:15px;">&nbsp;</span></p>
  </div>
</template>

<script>
export default {
  name:'warranty-policy'
}
</script>