<template>
    <div class="container">
        <img
            alt="page not found"
            src="~/assets/images/sorry.png" >
        <p>{{ $t('label_page_error') }}</p>
    </div>
</template>

<script>
    export default {
        props: ['error'],
        layout: 'blog',

        mounted() {
            setTimeout(() => {
                // window.location.href = '/'
            }, 500)
        },
    }
</script>

<style lang="less" scoped>
.container {
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 20% 0;
    background: rgba(245,245,245,1);

    p {
        font-size: 17px;
        font-family: SFProDisplay-Regular;
        color: rgba(161, 161, 161, 1);
        line-height: 22px;
    }
}
</style>
