





































    import {
        Vue,
        Component,
        Prop,
    } from 'vue-property-decorator'
    import {State} from 'vuex-class'
    import common from '~/utils/common'
    import api from '~/api'

    const reloadPageList = ['/aboutUs.html', 'privacyPolicy.html', 'termsAndConditions.html', 'shippingPolicy.html', 'warrantyPolicy.html', 'returnPolicy.html']
@Component({
    name: 'CountryBox',
})
    export default class extends Vue {
    @State('langCode') langCode

    @State('countryId') countryId

    @State('token') token

    @Prop(String)
    routeName!: string;

    countryList: any = []

    countryListObj = {
        TH: 'th-TH',
        ID: 'id',
        MY: 'ms',
        PH: 'fil',
        VN: 'vi',
        KR: 'ko'
    }

    get curCountryIcon() {
        let countryInfo = common.getCountryInfo()
        let curCountry = this.countryList.find((item: any) => countryInfo.countryId === item.id)
        if (curCountry) {
            return curCountry.countryFlag
        }
        return ''
    }

    mounted() {        
        this.countryList = this.$store.state.countryList
    }

    changeCountry(countryId) {
        if (this.$store.state.countryId === countryId) {
            return
        }
        const finlayItem = this.$store.state.countryList.find(item => item.id == countryId)
        const finalyPath = this.countryListObj[finlayItem.countryCode] || 'en-TH'
        localStorage.setItem('langCode', finalyPath)
        let curCountry = this.countryList.find((item: any) => countryId === item.id)
        this.$store.commit('SET_COUNTRY_INFO', { countryId, countryCode: curCountry.countryCode })
        // console.log(this.$route.path)
        if (reloadPageList.some(item => this.$route.path.includes(item))) {
            let pathHtml = this.$route.matched[0].path.split('/')[2]
            window.location.href = '/' + finalyPath + '/' + pathHtml
        } else {
            sessionStorage.setItem('showChangeCountryTips', '1')
            window.location.href = '/' + localStorage.getItem('langCode')
        }
    }
    }
