


































    import {
        Vue, Component, Watch, Prop,
    } from 'vue-property-decorator'
    import { State } from 'vuex-class'
    import { cloneDeep } from 'lodash'
    import api from '~/api'

@Component
    export default class CheckPasswordInput extends Vue {
    @State('langCode') langCode

    private isFirstMount: boolean = true;

    private isFocus: boolean = false;

    private isShowPwdDetail: boolean = false;

    private inputPattern: any = '';

    private inputMaxSize: number = 18;

    private password: any = '';

    @Prop({
        required: false,
        type: String,
    })
    username!: string;

    // 对规则列表进行样式的格式化
    get ruleComputedList() {
        const result = cloneDeep(this.ruleList)
        result.map(i => {
            i.className = this.getCurrentClass(i.expression)
            i.spanClassName = this.getCurrentSpanClass(i.expression)
        })
        return result
    }

    // 获取总的校验结果
    get validatorResult() {
        let passCount = 0
        this.ruleList.forEach(rule => {
            if (new RegExp(rule.expression).test(this.password)) {
                passCount++
            }
        })
        return passCount === this.ruleList.length
    }

    /**
     * 校验规则列表显示状态
     * 判断是否是首次渲染
     * 如果是首次渲染则不显示，聚焦的时候才显示
     * 否则根据校验结果显示
     */
    get validatorWrapperShow() {
        if (this.isFirstMount) {
            return false
        }
            return !this.validatorResult
    }

    private ruleList: any = [];

    @Watch('langCode', { immediate: true })
    onLangCode(newValue, oldValue) {
        if (newValue && oldValue !== undefined) {
            this.initPattern()
        }
    }

    mounted() {
        this.initPattern()
    }

    // 供父组件使用
    private async checkResult() {
        if (this.validatorResult) {
            return true
        }
        this.isFirstMount = false
        this.isFocus = false
        return false
    }

    private getCurrentClass(rule) {
        if (!this.isFirstMount) {
            if (this.isFocus) {
                return new RegExp(rule).test(this.password) ? 'success-style el-icon-success' : 'default-style el-icon-success'
            }
            return new RegExp(rule).test(this.password) ? 'success-style el-icon-success' : 'danger-style el-icon-error'
        }
    }

    private getCurrentSpanClass(rule) {
        if (!this.isFirstMount) {
            if (this.isFocus) {
                return new RegExp(rule).test(this.password) ? '' : ''
            }
                return new RegExp(rule).test(this.password) ? '' : 'danger-style'
        }
    }

    private handleInput() {
        this.password = this.password.replace(new RegExp(this.inputPattern, 'g'), '')
        if (this.password.length > this.inputMaxSize) {
            this.password = this.password.substring(0, this.inputMaxSize)
        }
        this.$emit('input', this.password)
    }

    private handleFocusInput() {
        this.isFocus = true
        this.isFirstMount = false
    }

    private handleBlurInput() {
        this.isFocus = false
    }

    private changePasswordType() {
        this.isShowPwdDetail = !this.isShowPwdDetail
    }

    private async initPattern() {
        const res = await api.get_password_pattern()
        this.ruleList = res.data.checkPatternList || []
        this.inputPattern = res.data.inputPattern
        this.inputMaxSize = res.data.inputMaxSize
    }
    }
