import api from '~/api'
import { initUnreadNimSDK } from '../utils/chat/initNimSDK'

export default {
    updateCartQuantity({commit}) {
        api.shopping_cart_quantity().then(res => {
            commit('SET_CART_QUANTITY', (res && res.data && res.data.cartQuantity) || 0)
        })
    },
    connectUnreadNim({state, commit, dispatch}, obj) {
        let accountInfo = window.localStorage.getItem('yunxinAccountInfo')
        accountInfo = JSON.parse(accountInfo) || {}
        dispatch('initUnreadNimSDK', accountInfo)
    },
    setUserId({state, commit, dispatch}) {
        api.client_profile().then(({ data }) => {
            commit('SET_USER_ID', data.profile.userId)
        })
    },
    initUnreadNimSDK,
}
