import { render, staticRenderFns } from "./guide-verify-box.vue?vue&type=template&id=9b960112&scoped=true&"
import script from "./guide-verify-box.vue?vue&type=script&lang=ts&"
export * from "./guide-verify-box.vue?vue&type=script&lang=ts&"
import style0 from "./guide-verify-box.vue?vue&type=style&index=0&id=9b960112&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b960112",
  null
  
)

export default component.exports