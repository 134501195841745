import Cookies from 'js-cookie'
import urlencode from 'urlencode'
import common from '~/utils/common'
import utils from '~/utils'

export default async function ({
    route, store, req, app, redirect, res,
}) {
    if (process.server) {
        // 如果是根路径就走ip查询，不是就取url的langCode
        const ipRestRst = await app.$axios.get('/platform/web-api/v1/index/get/countryId/from/ip') || {}
        let countryCode = (ipRestRst.data && ipRestRst.data.data && ipRestRst.data.data.countryCode) || 'TH'
        let countryId = (ipRestRst.data && ipRestRst.data.data && ipRestRst.data.data.countryId) || 1001
        let langCode = 'th-TH'
        if (route.path === '/') {
            langCode = utils.changeLangCode(countryId)
        } else {
            langCode = route.path.split('/')[1]
        }
        // console.log('trigger this ');

        let cookies = req.headers.cookie        // 获取已登录账号的国家信息以及选择的语言
        let cookieObj = {}
        if (cookies) {
            cookieObj = cookies.split(';').reduce((obj, item) => ({ ...obj, [item.split('=')[0].trim()]: item.split('=')[1].trim() }), {})
        }
        /*
        1. 首先，判断cookie中有没有countryId，没有就从接口取
        2. 其次，已登录就使用用户所属的国家
        3. 最后，非商品详情页也从cookie取，否则从接口取
        */
        if ((cookieObj.countryId && cookieObj.countryId !== 'NaN') && (cookieObj.token || !req.url.includes('/products/details/'))) {
            countryId = cookieObj.countryId || 1001
            countryCode = cookieObj.countryCode || 'TH'
        } else {
            const ipUrl = req.url.includes('/products/details/') ? `/platform/web/v1/product/getCountryIdBySkuOrgId?skuOrgId=${route.params.id}` // 商品详情页用商品id确定国家
                : '/platform/web-api/v1/index/get/countryId/from/ip' // 其他页面根据ip确定国家
            const ipRes = await app.$axios.get(ipUrl) || {}
            countryCode = (ipRes.data && ipRes.data.data && ipRes.data.data.countryCode) || 'TH'
            countryId = (ipRes.data && ipRes.data.data && ipRes.data.data.countryId) || 1001
        }
        const acceptLangs = ['en', 'en-TH', 'th', 'th-TH', 'id', 'ms', 'zh', 'fil', 'vi', 'ko']
        if (route.query.langCode && acceptLangs.indexOf(route.query.langCode) > -1) {
            langCode = route.query.langCode
        }

        // 增加参数
        if (route.query.isFromRepairNew) {
            if (route.query.token) {
                const {token, origin = ''} = route.query
                res.setHeader('Set-Cookie', `urlToken=${encodeURIComponent(token)}`)
                cookieObj.urlToken = token
                if (origin === 'REPAIR_WEB') {
                    return redirect(`/${langCode}`)
                }
                console.log('fullPath', langCode)
                return redirect(route.fullPath.replace(/(\?|&)token=[^&]*(&)?/g, (p0, p1, p2) => ((p1 === '?') || p2 ? p1 : '')))
            }
        }
        if (route.query.token && route.query.tokenType === 'CIAM') {
            const { token, origin = '' } = route.query
            // res.setHeader('Set-Cookie', `urlToken=${)};token=${encodeURIComponent(token)};tokenType=${route.query.tokenType}`)
            // res.cookie('urlToken', encodeURIComponent(token))
            res.cookie('token', encodeURIComponent(token))
            res.cookie('tokenType', route.query.tokenType)
            cookieObj.urlToken = token
            if (origin === 'REPAIR_WEB') {
                return redirect(`/${langCode}?origin=${origin}`)
            }
            return redirect(route.fullPath.replace(/(\?|&)token=[^&]*(&)?/g, (p0, p1, p2) => ((p1 === '?') || p2 ? p1 : '')))
        }

        // if (route.path === '/') {
        //     if (route.query.errorMsg) redirect(langCode, route.query)
        //     else redirect(langCode)
        // }
        if (['/'].includes(route.path)) {
            return redirect(route.fullPath.replace('/', '/' + langCode))
        }
        /**
         * 如果用户是首次进入，则不会修改路由，但需要在路由的query增加标识
         * 由于用户在页面跳转的时候都会经过router.ts的路由守卫，放行带标识的路由
         * 对于其他路由则走/en的逻辑
         */
        if (route.fullPath.startsWith('/en-TH') && route.fullPath.indexOf('changeRoute') === -1) {
            let url = route.fullPath
            url = url.indexOf('?') > -1 ? `${url}&changeRoute=no` : `${url}?changeRoute=no`
            return redirect(`${url}`)
        }

        if (cookieObj.urlToken) {
            store.commit('SET_URL_TOKEN', cookieObj.urlToken)
            // 路径上带token的需要提前通过token解析用户的countryId,防止服务端渲染的请求countryId传值不是跳转的用户所在的国家id
            const customerRes = await app.$axios.get('/platform/client/profile', {
                headers: {
                    token: urlencode(cookieObj.urlToken),
                },
            })
            if (customerRes && customerRes.data && customerRes.data.data && customerRes.data.data.profile && customerRes.data.data.profile.countryId) {
                countryId = customerRes.data.data.profile.countryId
                countryCode = customerRes.data.data.profile.countryCode || ''
            }
        }
        if (route.query.origin === 'REPAIR_WEB') {
            store.commit('SET_3P_SCENE', true)
        }
        const _query = req.query || {}
        if (_query.countryId && _query.showRightNav) { // 从erp-web传入过的 （Quotation 详情的send to customer by line 按钮）
            countryId = _query.countryId
            countryCode = _query.langCode
        }
        app.i18n.locale = langCode

        store.commit('SET_LANG', langCode)
        store.commit('SET_COUNTRY_INFO', { countryId, countryCode, res })
        // 判断
        if (req.headers.accept.includes('image/webp') || req.headers.accept.includes('q=0.9,*/*')) {
            store.commit('SET_WEBP', true)
        }
        if (route.query.isFromRepair) {
            store.commit('SET_COUNTRY_INFO', { countryId: +route.query.countryId, countryCode: route.query.countryCode, res })
        }
    } else {
        let countryInfo = common.getCountryInfo()
        if (Number.isNaN(countryInfo.countryId)) {
            store.commit('SET_COUNTRY_INFO', { countryId: 1001, countryCode: 'TH' })
        }
    }
    if (store.state.countryList.length) {
        return
    }
    let countryListRes = {}
    if (process.server) {
        countryListRes = await app.$axios.get('platform/app-api/v1/country/list') || {}
    } else {
        countryListRes = await app.$axios.get('app-api/v1/country/list') || {}
    }
    let countryList = countryListRes.data.data
    store.commit('SET_COUNTRY_LIST', countryList)
    let currentCountry = countryList.find(country => +country.id === +store.state.countryId)
    store.commit('SET_CURRENT_COUNTRY', currentCountry)
    store.commit('SET_COUNTRY_CURRENCY', currentCountry.currencyUnit)
}
