import $config from '~/config'

const g = global || window

export default {
    /**
     * 深拷贝函数（不拷贝函数、、promise，拷贝对象的getter属性的当前值）
     * @param  {*} val 需要拷贝的值
     * @return {*}    深拷贝后得到的新值
     */
    deepCopy(val, hash = new WeakMap()) {
        if (hash.has(val)) {
            return hash.get(val)
        }
        let result
        let type = Object.prototype.toString.call(val)
        switch (type) {
            case '[object String]':
            case '[object Number]':
            case '[object Boolean]':
            case '[object Null]':
            case '[object Undefined]':
            case '[object Symbol]':
            case '[object Math]':
            case '[object Error]':
                result = val                         // 基本类型和不需要处理的直接返回
                break
            case '[object RegExp]':
                result = new RegExp(val)             // 处理RegExp
                break
            case '[object Date]':
                result = new Date(val)               // 处理Date
                break
            case '[object Float32Array]':
            case '[object Float64Array]':
            case '[object Uint8Array]':
            case '[object Uint8Clamped​Array]':
            case '[object Uint16Array]':
            case '[object Uint32Array]':
            case '[object Int8Array]':
            case '[object Int16Array]':
            case '[object Int32Array]':
                result = g[type.slice(8, -1)].from(val)                                   // 处理****Array
                break
            case '[object Array]':                      // 处理Array
                result = []
                hash.set(val, result)
                val.forEach(v => {
                    result.push(this.deepCopy(v, hash))
                })
                break
            case '[object Set]':
            case '[object WeakSet]':
                result = type === '[object WeakSet]' ? new WeakSet() : new Set()          // 处理Set
                hash.set(val, result)
                val.forEach(v => {
                    result.add(this.deepCopy(v, hash))
                })
                break
            case '[object Map]':
            case '[object WeakMap]':
                result = type === '[object WeakMap]' ? new WeakMap() : new Map()          // 处理Map
                hash.set(val, result)
                val.forEach((v, k) => {
                    result.set(k, this.deepCopy(v, hash))
                })
                break
            case '[object Object]':
                result = {}
                hash.set(val, result)
                Reflect.ownKeys(val).forEach(k => {     // 获取所有属性
                    let des = Object.getOwnPropertyDescriptor(val, k) || {}
                    if (des.get || des.set) {        // 对于get、set，获取当前get的值
                        try {
                            des.value = this.deepCopy(des.get ? des.get.call(val) : null, hash)
                        } catch (e) {
                            des.value = undefined
                        }
                        delete des.get
                        delete des.set
                    } else {
                        des.value = this.deepCopy(des.value, hash)  // 不是get、set就继续深拷贝
                    }
                    Object.defineProperty(result, k, des)
                })
                break
            case '[object Function]':   // 不拷贝函数，涉及闭包, 可能影响原对象
            case '[object GeneratorFunction]':
            case '[object Promise]':    // 不拷贝Promise，涉及闭包, 可能影响原对象
                break
            default:
                break
        }
        return result
    },
    mapUrl(list, pictureName, size) {
        return list.map(item => {
            if (item[pictureName] && item[pictureName].includes($config.imgDomainName)) {
                item.pictureResize = item[pictureName] + '?x-oss-process=image/resize,w_' + size
            } else if (item[pictureName] && item[pictureName].charAt(0) === '/') {
                item[pictureName] = $config.imgPrefix + item[pictureName]
                item.pictureResize = item[pictureName] + '?x-oss-process=image/resize,w_' + size
            } else {
                item[pictureName] = $config.imgDomainName + item[pictureName]
                item.pictureResize = item[pictureName] + '?x-oss-process=image/resize,w_' + size
            }
            return item
        })
    },

    /**
     * @param func 输入完成的回调函数
     * @param delay 延迟时间
     */
    debounce(func: Function, delay: number): Function {
        let timer: number
        return (scope: Object, ...args: []): void => {
            if (timer) {
                clearTimeout(timer)
            }
            timer = window.setTimeout(() => {
                func.apply(scope, args)
            }, delay)
        }
    },

    /**
     * @param func 输入完成的回调函数
     * @param delay 延迟时间
     */
    throttle(func: Function, delay: number): Function {
        let timer: number
        return (scope: Object, ...args: []): void => {
            if (timer) {
                return
            }
            timer = window.setTimeout(() => {
                func.apply(scope, args)
                timer = 0
            }, delay)
        }
    },

    /**
     * 如果date不超过今天的limit, 则返回date + additional，否则返回明天的final
     * @param date                可转化为Date的数据
     * @param limit      {String} 时间，格式为'17:30:00'
     * @param additional {Number} 分钟数
     * @param final      {String} 时间，格式为'10:00:00'
     * @returns          {undefined|Date} undefined或Date
     */
    getFitTime(date: any, limit: string, additional: number, final: string): Date | void {
        if (!date) {
            return
        }
        date = new Date(date)
        let hour: number = date.getHours()
        let minute: number = date.getMinutes()
        let second: number = date.getSeconds()
        let timeStr: string = (hour > 9 ? '' : '0') + hour
            + ':' + (minute > 9 ? '' : '0') + minute
            + ':' + (second > 9 ? '' : '0') + second
        if (timeStr <= limit) {
            date.setMinutes(minute + additional)
        } else {
            date.setHours(hour + 24)
            date.setHours(...final.split(':'))
        }
        return date
    },

    /**
     * 根据链式的key，从对象中取到对应的值
     * @param obj   {Object} 要取值的对象
     * @param path  {String} 取值的路径，例如'a.b.c'
     * @returns     {any}    undefined或取到的值
     */
    getValueByPath(obj: any, path: string): any {
        if (!obj || typeof obj !== 'object' || !path) {
            return
        }
        let result: any = obj
        let arr: string[] = path.split('.')
        let len: number = arr.length
        let i: number = 0
        for (; i < len; i++) {
            result = result[arr[i]]
            if (result == null) {       // null和undefined避免停止循环，返回undefined，防止报错
                break
            }
        }
        return i === len ? result : undefined
    },

    /**
     * 根据链式的key，对对象中某个key赋值
     * @param obj   {Object}  要设置值的对象
     * @param path  {String}  设置值的路径，例如'a.b.c'
     * @param newVal {any}    要拷贝的值
     * @param deep  {Boolean} 是否深拷贝
     * @returns     {undefined}     undefined
     */
    setValueByPath(obj: any, path: string, newVal: any, deep = true): void {
        if (!obj || typeof obj !== 'object' || !path) {
            return
        }
        let result: any = obj
        let arr: string[] = path.split('.')
        let len: number = arr.length - 1
        let i: number = 0
        for (; i < len; i++) {
            result = result[arr[i]]
            if (result == null) {       // null和undefined避免停止循环，防止报错
                break
            }
        }
        if (i === len) {
            result[arr[i]] = deep ? this.deepCopy(newVal) : newVal
        }
    },

    /**
     * 将obj拼接成url里的query字符串
     * @param obj     {Object}  要转化的对象
     * @param encode  {Boolean} 是否对每个字段编码
     * @returns       {string}  query字符串'a=1&b=2'
     */
    getQueryParams(obj: any, encode = true): string {
        return Object.keys(obj)
            .map(k => (k + '=' + (encode ? encodeURIComponent(obj[k]) : obj[k])))
            .join('&')
    },

    /**
     * 获取类型
     * @param val {*}      要获取类型的值
     * @returns   {string} 类型
     */
    getTypeString(val: any): string {
        return Object.prototype.toString.call(val)
    },

    /**
     * 根据类型是否相同决定是否设置默认值 (暂时只针对有JSON转化的对象)
     * @param dest {Object}        要设置默认值的对象
     * @param key  {String|Number} 要设置默认值的键
     * @param type {String}        期望的类型
     * @param data {*}             默认值
     */
    setValueByType(dest: any, key: string | number, type: string, data: any): void {
        if (this.getTypeString(dest[key]) !== type) {
            dest[key] = this.deepClearArray(data)
        }
    },

    /**
     * 深度清空数组或对象中所有数组的子元素(暂时只针对有JSON转化的对象)
     * @param data     {Array|Object} 要清理的对象或数组
     * @returns        {Array|Object} 清理后的对象或数组
     */
    deepClearArray(data: any): any {
        switch (this.getTypeString(data)) {
            case '[object Object]':
            case '[object Array]':
                /* eslint-disable */
                // 这个正则重复(([^\[\]]*\[[^\[\]]*\][^\[\]]*)*|[^\[\]]*)，将这个正则替换自身\[[^\[\]]*\]中的[^\[\]]*,以达到匹配多层数组嵌套的问题，目前支持6层数组嵌套,设计接口时应尽量避免
                // 清空data内数组内容
                return JSON.parse(JSON.stringify(data)
                    .replace(/\[(([^\[\]]*\[(([^\[\]]*\[(([^\[\]]*\[(([^\[\]]*\[(([^\[\]]*\[[^\[\]]*\][^\[\]]*)*|[^\[\]]*)\][^\[\]]*)*|[^\[\]]*)\][^\[\]]*)*|[^\[\]]*)\][^\[\]]*)*|[^\[\]]*)\][^\[\]]*)*|[^\[\]]*)\]/g, '[]'))
            /* eslint-disable */
            default:
                return data
        }
    },

    /**
     * 给后端返回JSON字符串转化的对象设置默认值 (暂时只针对有JSON转化的对象)
     * @param dest  {Object|Array}  要设置默认值的对象
     * @param key   {string|Number} 要设置默认值的键
     * @param data  {*}             默认值
     * @returns     {*}             无返回，直接在函数中改变dest，以data为主要参照，保证data中有的字段在dest[key]中都有
     */
    setDefaultData(dest: any, key: string | number, data: any): void {
        let defaultType: string = this.getTypeString(data)
        if (dest[key] == null || this.getTypeString(dest[key]) !== defaultType) {   // 如果dest中没有这个字段或为null，或者和默认值类型不同，直接赋值为默认值
            return (dest[key] = this.deepClearArray(data))
        }
        if (defaultType === '[object Array]' && data.length) {             // 如果默认值是数组
            let childType: string = this.getTypeString(data[0])     // 数组默认值只要写第一个元素的默认值，其他元素与其一致
            if (childType === '[object Array]') {           // 数组的元素还是数组的情况
                dest[key].forEach((v: [], k: number) => {               // 对数组的每个元素调用设置默认值函数
                    this.setDefaultData(dest[key], k, data[0])
                })
            } else if (childType === '[object Object]') {   // 如果数组元素是对象
                dest[key].forEach((item: object) => {                 // 遍历目标数组，对比每个子对象中data中存在的键的类型
                    Object.keys(data[0])
                        .forEach(v => {
                            this.setDefaultData(item, v, data[0][v])
                        })
                })
            } else {                                        // 如果数组元素是String,Number,Boolean,Null
                dest[key].forEach((v: any, k: number) => {
                    this.setValueByType(dest[key], k, childType, data[0])
                })
            }
        } else if (defaultType === '[object Object]') {     // 如果默认值是对象
            Object.keys(data)
                .forEach(v => {
                    this.setDefaultData(dest[key], v, data[v])   // 遍历对象的每个键调用设置默认值函数
                })
        } else {                                            // 如果默认值是String,Number,Boolean,Null
            this.setValueByType(dest, key, defaultType, data[0])
        }
    },

    /**
     * 将数据转换成特定类型(暂时只针对有JSON转化的对象)
     * @param dest    {Object|Array}  要转换的对象
     * @param key     {String|Number} 要转换的键
     * @param typeFn  {function}      类型转换函数（String，Number，Boolean）
     */
    translateDataType(dest: any, key: string | number, typeFn: any): void {
        if (typeFn === Boolean) {
            dest[key] = dest[key] === true || dest[key] === 'true'
        } else {
            dest[key] = typeFn(dest[key])
        }
    },

    /**
     * 将传给后台的数据字段格式化为要求的类型(暂时只针对有JSON转化的对象)
     * @param dest   {Object|Array}  要格式化的对象
     * @param key    {string|Number} 要格式化的键
     * @param format {*}             格式化参数
     * @returns      {*}             无返回，直接在函数中改变dest，以format为主要参照，保证format,dest[key]中都有的字段符合format中声明的类型要求
     */
    formatParamsType(dest: any, key: string | number, format: any): void {
        let formatType: string = this.getTypeString(format)
        if (dest[key] == null || (formatType !== '[object Function]' && this.getTypeString(dest[key]) !== formatType)) {   // 如果dest中没有这个字段或为null，直接返回
            return
        }
        if (formatType === '[object Function]') {
            formatType = `[object ${format.name}]`
        }
        if (formatType === '[object Array]' && format.length) {              // 如果格式化参数是数组
            let childType: string = this.getTypeString(format[0])   // 数组格式化参数只要写第一个元素的类型，其他元素与其一致
            if (childType === '[object Array]') {           // 数组的元素还是数组的情况
                dest[key].forEach((v: [], k: number) => {               // 对数组的每个元素调用格式化函数
                    this.formatParamsType(dest[key], k, format[0])
                })
            } else if (childType === '[object Object]') {   // 如果数组元素是对象
                dest[key].forEach((item: any) => {                 // 遍历目标数组，对比每个子对象中format中存在的键的类型
                    Object.keys(format[0])
                        .forEach(v => {
                            this.formatParamsType(item, v, format[0][v])
                        })
                })
            } else if (childType === '[object Function]') { // 如果数组元素是构造函数String,Number,Boolean
                dest[key].forEach((v: any, k: number) => {
                    this.translateDataType(dest[key], k, format[0])
                })
            }
        } else if (formatType === '[object Object]') {     // 如果格式化参数是对象
            Object.keys(format)
                .forEach(v => {
                    this.formatParamsType(dest[key], v, format[v])   // 遍历对象的每个键调用格式化函数
                })
        } else {                                            // 如果格式化参数是构造函数String,Number,Boolean
            this.translateDataType(dest, key, format)
        }
    },
    /**
     * 模拟lodash的merge函数(不拷贝继承属性)
     * @param dest      目标对象
     * @param source    来源对象
     */
    merge(dest, ...source) {
        if (!dest) {
            return dest
        }
        source.forEach(item => {
            Object.keys(item)
                .forEach(v => {
                    if ((v in dest)) {      // 来源对象的键在目标对象中存在
                        if (item[v] !== undefined) {    // 如果目标值存在，被解析为undefined的source 来源对象属性将被跳过
                            let sourceType = this.getTypeString(item[v])
                            let destType = this.getTypeString(dest[v])
                            if (sourceType !== destType) {          // 类型不同时，source覆盖dest
                                dest[v] = this.cloneDeep(item[v])
                            } else {
                                let result
                                switch (sourceType) {
                                    case '[object String]':
                                    case '[object Number]':
                                    case '[object Boolean]':
                                    case '[object Null]':
                                    case '[object Undefined]':
                                    case '[object Symbol]':
                                        result = item[v]
                                        break
                                    case '[object RegExp]':
                                        result = new RegExp(item[v])             // 处理RegExp
                                        break
                                    case  '[object Date]':
                                        result = new Date(item[v])               // 处理Date
                                        break
                                    case '[object Array]':                       // 处理Array
                                        result = this.merge(dest[v], item[v])
                                        break
                                    case '[object Set]':
                                    case '[object WeakSet]':
                                        result = dest[v]          // 处理Set
                                        for (let k of item[v].values()) {
                                            result.add(v)
                                        }
                                        break
                                    case '[object Map]':
                                    case '[object WeakMap]':
                                        result = dest[v]          // 处理Map
                                        for (let k of item[v].keys()) {
                                            result.set(k, item[v].get(k))
                                        }
                                        break
                                    case '[object Object]':
                                        result = this.merge(dest[v], item[v])
                                        break
                                    default:
                                        break
                                }
                                dest[v] = result
                            }
                        }
                    } else {    // 来源对象不存在这个键直接赋值
                        dest[v] = this.cloneDeep(item[v])
                    }
                })
        })
        return dest
    },

    /**
     * 替换国际化词条中的变量
     * @param s    词条
     * @param o    词条中的需要替换的变量名和变量值的映射对象
     */
    i18nReplace(s, o) {
        var subRegRex = /\{\s*([^\|\}]+?)\s*(?:\|([^\}]*))?\s*\}/g;
        s = s || "";
        o = o || {};
        return s.replace ? s.replace(subRegRex, function (match, key) {
            return (o[key] === undefined) ? match : o[key]
        }) : s
    },

    /**
     * 新开页签打开链接
     * @param url      地址
     */
    openNewWindow(url: string, langCode: string): void {
        let newWindow: any = window.open()
        newWindow.opener = null
        newWindow.location = `${window.location.origin}/${langCode}${url}`
        newWindow.target = '_blank'
    },

    /**
     * 对数据进行前端分页
     * @param allData 原始数据列表
     * @param pageInfo 分页信息，用于分割原始数组
     * @returns [data] 前端分页后的数据
     *
     */
    pagingTableFronted(allData, pageInfo) {
        let curPage = pageInfo.page
        let size = pageInfo.limit
        let start = (curPage - 1) * size
        let total = allData.length
        let end = curPage * size > total ? total : curPage * size
        return allData.slice(start, end)
    },
    openBannerPromotionPage(_this, activityUrl) {
        const urlReg = new RegExp('((http[s]{0,1}|ftp|rtsp)://[a-zA-Z0-9\\.\\-]+\\.([a-zA-Z]{2,4})(:\\d+)?(/[a-zA-Z0-9\\.\\-~!@#$%^&*+?:_/=<>]*)?)|(www.[a-zA-Z0-9\\.\\-]+\\.([a-zA-Z]{2,4})(:\\d+)?(/[a-zA-Z0-9\\.\\-~!@#$%^&*+?:_/=<>]*)?)')
        if (!urlReg.test(activityUrl)) {
            return
        }
        // touristId
        let touristId = localStorage.getItem("cusotmerUUID")
        let url = `${activityUrl}&token=${_this.$store.state.token ? _this.$store.state.token : ''}&langCode=${_this.$store.state.langCode}&priceId=${_this.$store.state.priceItem.priceId ? _this.$store.state.priceItem.priceId : ''}&businessPlatform=EGG_MALL_WEB&touristId=${touristId}&countryId=${_this.$store.state.countryId}&countryCode=${_this.$store.state.countryCode}`
        _this.$router.push(_this.localePath({
            name: 'bannerPromotionPage',
            query: { url },
        }))
    },
    fbq(...args) {
        if ((process as any).client && window.fbq && !window.$nuxt.$store.state.userInfo.isTestAccount) {
            window.fbq(...args)
        }
    },
    ga4(...args) {
        if ((process as any).client && window.gtag && !window.$nuxt.$store.state.userInfo.isTestAccount) { 
            let customerId: any = '0'
            let customerType: any = 'Consumer'
            if(window.localStorage.getItem('userId')){
                customerId = window.localStorage.getItem('userId')
            }
            if(window.localStorage.getItem('customerType')){
                customerType = window.localStorage.getItem('customerType')
            }
            // const customerId = window.localStorage.getItem('userId')
            // const customerType = window.localStorage.getItem('customerType')
            // if (customerId) {
            //     if (args[2]) {
            //         args[2].customerId = customerId
            //     } else {
            //         args[2] = { customerId }
            //     }
            // }
            if (args[2]) {
                args[2].customerId = customerId
                args[2].customerType = customerType
            } else {
                args[2] = { customerId,customerType }
            }
            args[2].dtp_id = '8a5c8464-a15a-4ee7-8798-9d6e32474097'
            args[2].container_id = 'GTM-TCBCD4Z'
            window.gtag(...args)
        }
    },
    tiktok(...args) {
        if (window && window.ttq && window.ttq.track) {
            window.ttq.track(...args)
        }
    },
    changeLangCode(val){
        let langCode
        switch (val) { 
            case 'en-TH':
            case '1192':
            case 1192:
                langCode = 'en-TH'
                break
            case 'en':
                langCode = 'en'
                break
            case 'th-TH':
            case 'th':
            case '1001':
            case 1001:
                langCode = 'th-TH'
                break
            case 'id':
            case '1102':
            case 1102:
                langCode = 'id'
                break
            case 'zh':
                langCode = 'zh'
                break
            case 'ms':
            case '1129':
            case 1129:
                langCode = 'ms'
                break
            case 'fil':
            case '1171':
            case 1171:
                langCode = 'fil'
                break
            case 'vi':
            case '1234':
            case 1234:
                langCode = 'vi'
                break
            case 'ko':
            case '1198':
            case 1198:
                langCode = 'ko'
            break
            default:
                langCode = 'th-TH'
        }
        return langCode
    },
    dataFormat(date, format) {
        if (!(date instanceof Date)) {
          date = new Date(parseInt(date))
        }
        format = format || 'yyyy-MM-dd HH:mm:ss'
        var o = {
          'M+': date.getMonth() + 1,
          'd+': date.getDate(),
          'H+': date.getHours(),
          'm+': date.getMinutes(),
          's+': date.getSeconds(),
          'q+': Math.floor((date.getMonth() + 3) / 3), //季度
          S: date.getMilliseconds() //毫秒
        }
        if (/(y+)/.test(format)) {
          format = format.replace(
            RegExp.$1,
            (date.getFullYear() + '').substr(4 - RegExp.$1.length)
          )
        }
        for (var k in o) {
          if (new RegExp(`(${k})`).test(format)) {
            format = format.replace(
              RegExp.$1,
              RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
            )
          }
        }
        return format
    },
}
