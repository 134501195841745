import NP from './number-precision'
import {formatDate} from './date-format'
import storage from '~/utils/storage'

export default {
    /**
     * 格式化日期
     * @param time             要转换的值
     * @param empty  {String}  time为假值时的返回值
     * @param timeZone  {Number}  指定时区
     * @param format {String}  日期格式
     * @returns      {string}  格式化后的字符串 | empty
     */
    dateFilter(time: any, empty = '', format = 'dd/MM/yyyy'): string {
        if (time) {
            return formatDate(new Date(time), format)
        }
        return empty
    },
    /**
     * 格式化日期时间
     * @param time            要转换的值
     * @param empty  {String} time为假值时的返回值
     * @param timeZone  {Number}  指定时区
     * @param format {String} 日期格式
     * @returns      {string} 格式化后的字符串 | empty
     */
    dateTimeFilter(time: any, empty = '', format = 'dd/MM/yyyy hh:mm:ss'): string {
        if (time) {
            return formatDate(new Date(time), format)
        }
        return empty
    },
    /**
     * 格式化数字，保留number位小数
     * @param number          要格式化的数字
     * @param empty  {String} number不能被转换为Number时的返回值
     * @param digits {Number} 小数位数
     * @returns      {Number|String} 格式化后的数字 | empty
     */
    fixedFilter(number: any, empty = '', digits = 2): string {
        if (number == null) {   // null强转Number的值为0，先处理
            return empty
        }
        number = +number
        return Number.isNaN(number) ? empty : NP.toFixed(number, digits)
    },

    /**
     * 格式化多选下拉框的显示内容
     * @param value     {Array}         多选选中的数组
     * @param list      {Array}         多选下拉框list数据
     * @param valueKey  {String}        value的键
     * @param nameKey   {String}        name的键
     * @param empty     {String}        空值
     * @returns         {Number|String} 格式化后的数字 | empty
     */
    multiSelectString(value:any[], list:any[], valueKey = 'value', nameKey = 'name', empty = ''): string {
        if (!Array.isArray(value) || !Array.isArray(list)) {
            return empty
        }
        return value.reduce((s, v) => s + ',' + (list.find(item => item[valueKey] === v) || {})[nameKey], '').slice(1)
    },

    /**
     * 格式化%数字，保留number位小数
     * @param number          要格式化的数字
     * @param res {String} number不能被转换为Number时的返回值
     * @param digits {Number} 小数位数
     * @returns      {Number|String} 格式化后的数字加% | res
     */
    percentageFilter(number: any, res = '--', digits = 2) {
        if (number == null) {   // null强转Number的值为0，先处理
            return res
        }
        number = +number
        return Number.isNaN(number) ? res : (NP.toFixed(number, digits) + '%')
    },

    /**
     * 对undefined,null,NaN等假值进行处理
     * @param value          要处理的值
     * @param empty {String} value为假值时的返回值
     * @returns     {string} value | empty
     */
    emptyFilter(value: any, empty = ''): string {
        return (value == null || value === '' || Number.isNaN(value)) ? empty : value
    },

    /**
     * 统计数组中每个对象的某个字段之和
     * @param arr    {Array}         要统计的数组
     * @param key    {String}        要统计的字段
     * @param empty  {Number|String} 统计结果为假值时的返回值
     * @param digits {Number}        小数位数
     * @returns      {Number|String} 统计结果 | empty
     */
    countFilter(arr: [], key: string, empty = 0, digits = 2): string | number {
        let sum: number = 0
        if (Array.isArray(arr)) {
            sum = arr.reduce((num, item) => {
                let v: number = +item[key]
                return Number.isNaN(v) ? num : NP.plus(num, v)
            }, 0)
        }
        return sum ? NP.toFixed(sum, digits) : empty
    },

    /**
     * 统计数组中每一个对象的两个字段的乘积之和
     * @param arr    {Array}         要统计的数组
     * @param key1   {String}        要统计的字段
     * @param key2   {String}        要统计的字段
     * @param empty  {Number|String} 统计结果为假值时的返回值
     * @param digits {Number}        小数位数
     * @returns      {Number|String} 统计结果 | empty
     */
    amountFilter(arr: [], key1: string, key2: string, empty = 0, digits = 2): string | number {
        let sum: number = 0
        if (Array.isArray(arr)) {
            sum = arr.reduce((num, item) => {
                let v1: number = +item[key1]
                let v2: number = +item[key2]
                return Number.isNaN(v1) || Number.isNaN(v2) ? num : NP.plus(num, NP.times(v1, v2))
            }, 0)
        }
        return sum ? NP.toFixed(sum, digits) : empty
    },

    /**
     * 根据一个值从数组中找到匹配对象，并返回匹配对象中的另一个字段
     * @param id                        要查找的值
     * @param arr       {Array}         查找的数组
     * @param idKey     {String}        要查找的值对应的key
     * @param targetKey {String}        要返回的值的key
     * @param empty     {Number|String} 未找到匹配对象时的返回值
     * @returns         {Number|String} 匹配对象中idKey的值 | empty
     */
    getValueById(id: string | number, arr: [], idKey = 'value', targetKey = 'name', empty = ''): string | number {
        let item: any
        if (Array.isArray(arr)) {
            item = arr.find(v => v[idKey] === id)
        }
        return item ? item[targetKey] : empty
    },

    /**
   *
   * @param number 金额
   * @param decimals 保留几位小数
   * @param decPoint 小数点符号
   * @param thousandsSep 千分位符号
   */
    thousandBitSeparator(number: any, countryId: number, roundTag?, digits?) {
        if ((<any>process).server) {
            return
        }
        if (number === '***') {
            return number
        }
        const currentCountry = storage.getLocal('currentCountry') || {}
        const { currencyPrecision, decimalSeparator, thousandsSeparator } = currentCountry
        let decimals = currencyPrecision
        let decPoint = decimalSeparator
        let thousandsSep = thousandsSeparator
        if (digits !== undefined) {
            decimals = digits
        }

        number = (number + '').replace(/[^0-9+-Ee.]/g, '')
        roundTag = roundTag || 'round' // "ceil", "floor", "round"
        const n = !isFinite(+number) ? 0 : +number
        const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
        const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
        const dec = (typeof decPoint === 'undefined') ? '.' : decPoint
        let s: any = ''
        const toFixedFix = function (n, prec) {
            const k = Math.pow(10, prec)
            return '' + parseFloat(Math[roundTag](parseFloat((n * k).toFixed(prec * 2))).toFixed(prec * 2)) / k
        }
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
        const re = /(-?\d+)(\d{3})/
        while (re.test(s[0])) {
            s[0] = s[0].replace(re, '$1' + sep + '$2')
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || ''
            s[1] += new Array(prec - s[1].length + 1).join('0')
        }
        return s.join(dec)
    },
    toThousands(number) {
            number = Number(number)
            return number.toString().replace(/\d+/, function(n) {
            return n.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
        })
    }
}
