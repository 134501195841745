
















    import {
        Vue, Component, Prop,
    } from 'vue-property-decorator'
    import api from '~/api'
@Component({
    components: {}
})
    export default class KRVincodeSearch extends Vue {

        @Prop({required: true}) vinCodeList !: Array<any>

        handleClickVincode(item){
            this.$emit('handleClickVinCode',item)
        }
    }
