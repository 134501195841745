import $config from '~/config'

export default {
    data: {
        default: [
            {
                termsConditionsId: 13,
                termsName: 'TermsandConditions',
                termsVersion: 'V1.4',
                termsType: 1,
                termsSubType: null,
                displayPrecedence: 1,
                termsConditionList: [
                    {
                        termsConditionsId: 13,
                        termsName: 'TermsandConditions',
                        termsVersion: 'V1.4',
                        termsUrl: $config.imgDomainName + 'Terms/EGGMall_Terms_And_Condition_EN_V1.4.pdf',
                        iosTermsUrl: $config.imgDomainName + 'Terms/EGGMall_Terms_And_Condition_EN_V1.4.pdf',
                        termsHtmlUrl: 'http://promotion.whats-egg.com/promotion/index_common_view_pdf.html?termsUrl=' + $config.imgDomainName + 'Terms/EGGMall_Terms_And_Condition_EN_V1.4.pdf',
                        termsType: 1,
                        termsSubType: null,
                        displayPrecedence: 1,
                        needAgree: null,
                        pid: 13,
                    },
                    {
                        termsConditionsId: 12,
                        termsName: 'ShippingPolicy',
                        termsVersion: 'V1.2',
                        termsUrl: $config.imgDomainName + 'Terms/Shipping_Policy_2020_V7_en.pdf',
                        iosTermsUrl: $config.imgDomainName + 'Terms/Shipping_Policy_2020_V7_en.pdf',
                        termsHtmlUrl: 'http://promotion.whats-egg.com/promotion/index_common_view_pdf.html?termsUrl' + $config.imgDomainName + 'Terms/Shipping_Policy_2020_V7_en.pdf',
                        termsType: 2,
                        termsSubType: null,
                        displayPrecedence: 3,
                        needAgree: null,
                        pid: 13,
                    },
                    {
                        termsConditionsId: 5,
                        termsName: 'WarrantyPolicy',
                        termsVersion: 'V1.0',
                        termsUrl: $config.imgDomainName + 'Terms/Warranty_Policy_2020_V3_en.pdf',
                        iosTermsUrl: $config.imgDomainName + 'Terms/Warranty_Policy_2020_V3_en.pdf',
                        termsHtmlUrl: 'http://promotion.whats-egg.com/promotion/warranty_policy_en_v3.html?termsUrl' + $config.imgDomainName + 'Terms/Warranty_Policy_2020_V3_en.pdf',
                        termsType: 4,
                        termsSubType: null,
                        displayPrecedence: 4,
                        needAgree: null,
                        pid: 13,
                    },
                    {
                        termsConditionsId: 4,
                        termsName: 'ReturnPolicy',
                        termsVersion: 'V1.0',
                        termsUrl: $config.imgDomainName + 'Terms/Return_Policy_2020_V4_en.pdf',
                        iosTermsUrl: $config.imgDomainName + 'Terms/Return_Policy_2020_V4_en.pdf',
                        termsHtmlUrl: 'http://promotion.whats-egg.com/promotion/return_policy_en_v3.html?termsUrl' + $config.imgDomainName + 'Terms/Return_Policy_2020_V4_en.pdf',
                        termsType: 3,
                        termsSubType: null,
                        displayPrecedence: 5,
                        needAgree: null,
                        pid: 13,
                    },
                    {
                        termsConditionsId: 8,
                        termsName: 'PointsPolicy',
                        termsVersion: 'V1.0',
                        termsUrl: $config.imgDomainName + 'Terms/Return_Policy_2020_V4_en.pdf',
                        iosTermsUrl: $config.imgDomainName + 'Terms/Return_Policy_2020_V4_en.pdf',
                        termsHtmlUrl: 'http://promotion.whats-egg.com/promotion/return_policy_en_v3.html?termsUrl' + $config.imgDomainName + 'Terms/Return_Policy_2020_V4_en.pdf',
                        termsType: 3,
                        termsSubType: null,
                        displayPrecedence: 5,
                        needAgree: null,
                        pid: 13,
                    },
                ],
                pid: 13,
            },
            {
                termsConditionsId: 7,
                termsName: 'PrivacyPolicy',
                termsVersion: 'V1.0',
                termsType: 6,
                termsSubType: null,
                displayPrecedence: 2,
                termsConditionList: [
                    {
                        termsConditionsId: 7,
                        termsName: 'PrivacyPolicy',
                        termsVersion: 'V1.0',
                        termsUrl: $config.imgDomainName + 'Terms/Privacy_Policy_English.pdf',
                        iosTermsUrl: $config.imgDomainName + 'Terms/Privacy_Policy_English.pdf',
                        termsHtmlUrl: 'http://promotion.whats-egg.com/promotion/index_common_view_pdf.html?termsUrl' + $config.imgDomainName + 'Terms/Privacy_Policy_English.pdf',
                        termsType: 6,
                        termsSubType: null,
                        displayPrecedence: 2,
                        needAgree: null,
                        pid: 7,
                    },
                ],
                pid: 7,
            },
        ],
        vi: [
            {
                termsConditionsId: 13,
                termsName: 'TermsandConditions',
                termsVersion: 'V1.4',
                termsType: 1,
                termsSubType: null,
                displayPrecedence: 1,
                termsConditionList: [
                    {
                        termsConditionsId: 13,
                        termsName: 'TermsandConditions',
                        termsVersion: 'V1.4',
                        termsUrl: $config.imgDomainName + 'Terms/EGGMall_Terms_And_Condition_EN_V1.4.pdf',
                        iosTermsUrl: $config.imgDomainName + 'Terms/EGGMall_Terms_And_Condition_EN_V1.4.pdf',
                        termsHtmlUrl: 'http://promotion.whats-egg.com/promotion/index_common_view_pdf.html?termsUrl' + $config.imgDomainName + 'Terms/EGGMall_Terms_And_Condition_EN_V1.4.pdf',
                        termsType: 1,
                        termsSubType: null,
                        displayPrecedence: 1,
                        needAgree: null,
                        pid: 13,
                    },
                    {
                        termsConditionsId: 5,
                        termsName: 'WarrantyPolicy',
                        termsVersion: 'V1.0',
                        termsUrl: $config.imgDomainName + 'Terms/Warranty_Policy_2020_V3_en.pdf',
                        iosTermsUrl: $config.imgDomainName + 'Terms/Warranty_Policy_2020_V3_en.pdf',
                        termsHtmlUrl: 'http://promotion.whats-egg.com/promotion/warranty_policy_en_v3.html?termsUrl' + $config.imgDomainName + 'Terms/Warranty_Policy_2020_V3_en.pdf',
                        termsType: 4,
                        termsSubType: null,
                        displayPrecedence: 4,
                        needAgree: null,
                        pid: 13,
                    },
                    {
                        termsConditionsId: 4,
                        termsName: 'ReturnPolicy',
                        termsVersion: 'V1.0',
                        termsUrl: $config.imgDomainName + 'Terms/Return_Policy_2020_V4_en.pdf',
                        iosTermsUrl: $config.imgDomainName + 'Terms/Return_Policy_2020_V4_en.pdf',
                        termsHtmlUrl: 'http://promotion.whats-egg.com/promotion/return_policy_en_v3.html?termsUrl' + $config.imgDomainName + 'Terms/Return_Policy_2020_V4_en.pdf',
                        termsType: 3,
                        termsSubType: null,
                        displayPrecedence: 5,
                        needAgree: null,
                        pid: 13,
                    },
                ],
                pid: 13,
            },
            {
                termsConditionsId: 7,
                termsName: 'PrivacyPolicy',
                termsVersion: 'V1.0',
                termsType: 6,
                termsSubType: null,
                displayPrecedence: 2,
                termsConditionList: [
                    {
                        termsConditionsId: 7,
                        termsName: 'PrivacyPolicy',
                        termsVersion: 'V1.0',
                        termsUrl: $config.imgDomainName + 'Terms/Privacy_Policy_English.pdf',
                        iosTermsUrl: $config.imgDomainName + 'Terms/Privacy_Policy_English.pdf',
                        termsHtmlUrl: 'http://promotion.whats-egg.com/promotion/index_common_view_pdf.html?termsUrl' + $config.imgDomainName + 'Terms/Privacy_Policy_English.pdf',
                        termsType: 6,
                        termsSubType: null,
                        displayPrecedence: 2,
                        needAgree: null,
                        pid: 7,
                    },
                ],
                pid: 7,
            },
        ],
        ko :[
            {
                termsConditionsId: 13,
                termsName: 'TermsandConditions',
                termsVersion: 'V1.4',
                termsType: 1,
                termsSubType: null,
                displayPrecedence: 1,
                termsConditionList: [
                    {
                        termsConditionsId: 13,
                        termsName: 'TermsandConditions',
                        termsVersion: 'V1.4',
                        termsUrl: $config.imgDomainName + 'Terms/EGGMall_Terms_And_Condition_EN_V1.4.pdf',
                        iosTermsUrl: $config.imgDomainName + 'Terms/EGGMall_Terms_And_Condition_EN_V1.4.pdf',
                        termsHtmlUrl: 'http://promotion.whats-egg.com/promotion/index_common_view_pdf.html?termsUrl' + $config.imgDomainName + 'Terms/EGGMall_Terms_And_Condition_EN_V1.4.pdf',
                        termsType: 1,
                        termsSubType: null,
                        displayPrecedence: 1,
                        needAgree: null,
                        pid: 13,
                    },
                    {
                        termsConditionsId: 5,
                        termsName: 'WarrantyPolicy',
                        termsVersion: 'V1.0',
                        termsUrl: $config.imgDomainName + 'Terms/Warranty_Policy_2020_V3_en.pdf',
                        iosTermsUrl: $config.imgDomainName + 'Terms/Warranty_Policy_2020_V3_en.pdf',
                        termsHtmlUrl: 'http://promotion.whats-egg.com/promotion/warranty_policy_en_v3.html?termsUrl' + $config.imgDomainName + 'Terms/Warranty_Policy_2020_V3_en.pdf',
                        termsType: 4,
                        termsSubType: null,
                        displayPrecedence: 4,
                        needAgree: null,
                        pid: 13,
                    },
                    // {
                    //     termsConditionsId: 8,
                    //     termsName: 'PointsPolicy',
                    //     termsVersion: 'V1.0',
                    //     termsUrl: $config.imgDomainName + 'Terms/Return_Policy_2020_V4_en.pdf',
                    //     iosTermsUrl: $config.imgDomainName + 'Terms/Return_Policy_2020_V4_en.pdf',
                    //     termsHtmlUrl: 'http://promotion.whats-egg.com/promotion/return_policy_en_v3.html?termsUrl' + $config.imgDomainName + 'Terms/Return_Policy_2020_V4_en.pdf',
                    //     termsType: 3,
                    //     termsSubType: null,
                    //     displayPrecedence: 5,
                    //     needAgree: null,
                    //     pid: 13,
                    // }
                ],
                pid: 13,
            },
            {
                termsConditionsId: 27,
                termsName: 'label_collection',
                termsVersion: 'V1.0',
                termsType: 6,
                termsSubType: null,
                displayPrecedence: 2,
                termsConditionList: [
                    {
                        termsConditionsId: 27,
                        termsName: 'label_collection',
                        termsVersion: 'V1.0',
                        termsUrl: $config.imgDomainName + 'Terms/Privacy_Policy_English.pdf',
                        iosTermsUrl: $config.imgDomainName + 'Terms/Privacy_Policy_English.pdf',
                        termsHtmlUrl: 'http://promotion.whats-egg.com/promotion/index_common_view_pdf.html?termsUrl' + $config.imgDomainName + 'Terms/Privacy_Policy_English.pdf',
                        termsType: 6,
                        termsSubType: null,
                        displayPrecedence: 2,
                        needAgree: null,
                        pid: 7,
                    },
                ],
                pid: 7,
            },  
            {
                termsConditionsId: 28,
                termsName: 'label_oversea',
                termsVersion: 'V1.0',
                termsType: 6,
                termsSubType: null,
                displayPrecedence: 2,
                termsConditionList: [
                    {
                        termsConditionsId: 28,
                        termsName: 'label_oversea',
                        termsVersion: 'V1.0',
                        termsUrl: $config.imgDomainName + 'Terms/Privacy_Policy_English.pdf',
                        iosTermsUrl: $config.imgDomainName + 'Terms/Privacy_Policy_English.pdf',
                        termsHtmlUrl: 'http://promotion.whats-egg.com/promotion/index_common_view_pdf.html?termsUrl' + $config.imgDomainName + 'Terms/Privacy_Policy_English.pdf',
                        termsType: 6,
                        termsSubType: null,
                        displayPrecedence: 2,
                        needAgree: null,
                        pid: 7,
                    },
                ],
                pid: 27,
            },  
            {
                termsConditionsId: 7,
                termsName: 'PrivacyPolicy',
                termsVersion: 'V1.0',
                termsType: 6,
                termsSubType: null,
                displayPrecedence: 2,
                termsConditionList: [
                    {
                        termsConditionsId: 7,
                        termsName: 'PrivacyPolicy',
                        termsVersion: 'V1.0',
                        termsUrl: $config.imgDomainName + 'Terms/Privacy_Policy_English.pdf',
                        iosTermsUrl: $config.imgDomainName + 'Terms/Privacy_Policy_English.pdf',
                        termsHtmlUrl: 'http://promotion.whats-egg.com/promotion/index_common_view_pdf.html?termsUrl' + $config.imgDomainName + 'Terms/Privacy_Policy_English.pdf',
                        termsType: 6,
                        termsSubType: null,
                        displayPrecedence: 2,
                        needAgree: null,
                        pid: 7,
                    },
                ],
                pid: 28,
            },    
        ]
    },
}
