const env = process.env.CUSTOM_ENV    // env:development,production,testing,stable

const config = {
    name: 'Egg Mall Web',
    env,
    imgDomainName: env === 'production' ? 'https://image-cdn.eggmalltech.com/' : 'https://image-cdn.jimuitech.com/',
    imgDomainName2: env === 'production' ? 'https://image-cdn.eggmalltech.com' : 'https://image-cdn.jimuitech.com',
    requestHeader: {
        // 自定义的请求头
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
        // 跨域是否带Token
        withCredentials: true,
        // 响应的数据格式 json / blob /document /array/buffer / text / stream
        responseType: 'json',
        xsrfCookieName: 'XSRF-TOKEN',
        xsrfHeaderName: 'X-XSRF-TOKEN',
    },
    imgPrefix: env === 'production' ? 'https://image-cdn.eggmalltech.com' : 'https://image-cdn.jimuitech.com',
    apiUrlPrefix: '/platform',
    eggmallWebService: {
        development: {
            url: 'http://www.test.jimuitech.com',
            host: 'www.test.jimuitech.com',
        },
        test: {
            // url: 'http://www.test.jimuitech.com',
            // host: 'www.test.jimuitech.com',
            url: 'http://springboot-service-api-gateway-test/service-convenient-test',
            host: 'www.test.jimuitech.com',
        },
        testbig: {
            url: 'http://springboot-service-api-gateway-test-big/service-convenient-test-big',
            host: 'www2.test.jimuitech.com',
        },
        uat: {
            url: 'http://springboot-service-api-gateway-uat/service-convenient-uat',
            host: 'www.uat.jimuitech.com',
        },
        stable: {
            url: 'http://springboot-service-api-gateway-stable/service-convenient-stable',
            host: 'www.stable.jimuitech.com',
        },
        production: {
            url: 'http://springboot-service-api-gateway/service-convenient',
            host: 'www.eggmalltech.com',
        },
    },
    eggmallWeb: {
        development: 'http://www.test.jimuitech.com',
        // development: 'https://www.eggmalltech.com',
        test: 'http://www.test.jimuitech.com',
        testbig: 'http://www2.test.jimuitech.com',
        uat: 'http://www.uat.jimuitech.com',
        stable: 'http://www.stable.jimuitech.com',
        production: 'https://www.eggmalltech.com',
    },
    chatWeb: {
        development: 'http://chat.out.test.jimuitech.com/login',
        test: 'http://chat.out.test.jimuitech.com/login',
        testbig: 'http://chat.out.big.test.jimuitech.com/login',
        uat: 'http://chat.out.uat.jimuitech.com/login',
        stable: 'http://chat.out.stable.jimuitech.com/login',
        production: 'https://chat.eggmalltech.com/login',
    },
    repairWeb: {
        development: 'http://www.repair.test.jimuitech.com',
        test: 'http://www.repair.test.jimuitech.com',
        testbig: 'http://www2.repair.test.jimuitech.com',
        uat: 'http://www.repair.uat.jimuitech.com',
        stable: 'http://www.repair.stable.jimuitech.com',
        production: 'https://www.egg-repair.com',
    },
    sellerCenterWeb: {
        development: 'http://seller.test.jimuitech.com/#/',
        test: 'http://seller.test.jimuitech.com/#/',
        testbig: 'http://seller2.test.jimuitech.com/#/',
        uat: 'http://seller.uat.jimuitech.com/#/',
        stable: 'http://seller.stable.jimuitech.com/#/',
        production: 'https://seller.eggmalltech.com/#/',
    },
    promotionH5: {
        development: 'http://localhost:9000/promotion',
        test: 'http://activity.test.jimuitech.com',
        testbig: 'http://activity.big.test.jimuitech.com',
        uat: 'http://activity.uat.jimuitech.com',
        stable: 'http://activity.stable.jimuitech.com',
        production: 'https://promotion.whats-egg.com/promotion',
    },
}
export default config
