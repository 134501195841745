





































    import {
        Vue, Component, Watch
    } from 'vue-property-decorator'
    import api from '~/api'
    import utils from '~/utils'
    import {State} from 'vuex-class'

@Component({
    components: {
    },
})
    export default class SearchFeedback extends Vue {
        @State('token') token

        @State('searchFeedbackModalVisible') searchFeedbackModalVisible

        @State('searchFeedbackContent') searchFeedbackContent

        loading: boolean = false

        questionList: any = []

        problemType: any = ''

        checkList: any = []

        requiredStyle: any = false

        remark: any = ''

        remarkRequiredStyle: any = false

        openDlg(){
            api.feedback_question_list().then((res)=>{
                this.questionList = res.data || []
            })
            this.problemType = ''
            this.checkList = []
            this.requiredStyle = false
            this.remark = ''
            this.remarkRequiredStyle = false
        }

        closeDlg(){
            this.$store.commit('SET_FEEDBACK_STATUS', false)
        }

        handleSubmit(){
            if(!this.problemType){
                this.requiredStyle = true
                this.$message.error(this.$t('label_search_feedback_tips2'))
                return
            }
            if(this.problemType == 5 && !this.remark.trim()){
                this.remark = ''
                this.remarkRequiredStyle = true
                // this.$message.error(this.$t('label_search_feedback_tips2'))
                return
            }
            let params = {
                "predictionsSource": this.searchFeedbackContent.predictionsSource,
                "problemType": this.problemType,
                "searchText": this.searchFeedbackContent.searchText,//用户输入
                "recommendText": this.checkList.join(';'),//返回推荐词
                "remark": this.remark //其他原因
            }
            // console.log('test',params,this.checkList);
            
            api.feedback_question(params).then((res)=>{
                if(res.code == 200){
                    this.$message.success({message:this.$t('label_submit_success')})
                }else{
                    this.$message.error(res.message)
                }
            }).finally(()=>{
                this.closeDlg()
            })
        }

        @Watch('remark')
        onRemark(val){
            if(this.remarkRequiredStyle && val){
                this.remarkRequiredStyle = false
            }
        }

        @Watch('problemType')
        onProblemType(val){
            if(val){
                this.requiredStyle = false
                this.remarkRequiredStyle = false
            }
        }

    }
