



















    import {
        Vue, Component, PropSync,Watch
    } from 'vue-property-decorator'
    import api from '~/api'
    import { State } from 'vuex-class'


@Component({
    name: 'CommonDistricCascader',
})
    export default class extends Vue {
    @PropSync('district', {required: true}) completeDistrict

    $refs!: {
        cascader: any
    }

    options: any[] = []
    
    @State('token') token
    
   @Watch('completeDistrict', { immediate: false })
    onLangCode(newValue,oldValue) {
        this.completeDistrict = newValue
        console.log(newValue,'new',oldValue,'old');
        console.log(newValue[2] != oldValue[2],123);
        
        if(newValue.length == 3 && (newValue[2] != oldValue[2])){
            this.$emit('changeDistrict', newValue) 
        }
    }

    mounted(){
        this.getProvinceData()
    }
    // cascaderProps: any = {
    //     lazy: true,
    //     lazyLoad(node, resolve) {
    //         const {level, value} = node
    //         if (level === 1) {
    //             api.city_get({
    //                 provinceId: value,
    //             }).then(res => {
    //                 let data = (res && res.data) || []
    //                 let children = data.map(item => ({
    //                         value: item.id,
    //                         label: item.name,
    //                         children: [],
    //                     }))
    //                 resolve(children)
    //             })
    //         } else if (level === 2) {
    //             api.district_get({
    //                 cityId: value,
    //             }).then(res => {
    //                 let data = (res && res.data) || []
    //                 let children = data.map(item => ({
    //                         value: item.id,
    //                         label: item.name,
    //                         leaf: true,
    //                     }))
    //                 resolve(children)
    //             })
    //         }
    //     },
    // }

    getProvinceData() {
        if (this.options.length !== 0) {
            return
        }
        if(this.token){
            api.district_list_get().then(res => {
                let data = (res && res.data) || []
                this.options = data
            })
        }
    }

    // selectDistrict() {
    //     let checkedNodes = this.$refs.cascader.getCheckedNodes(true)
        
    //     if (checkedNodes && checkedNodes[0] && checkedNodes[0].path && checkedNodes[0].path.length === 3) {
    //         let path = checkedNodes[0].path
    //         this.$emit('changeDistrict', path)           
    //     }
    // }
    
    }
