










































import {
    Vue, Component,Prop, PropSync
} from 'vue-property-decorator'
import {State} from 'vuex-class'

@Component({})

export default class CommonIndexBar extends Vue {

    @PropSync('visible', {required: true, default: false}) dlgVisible!: boolean

    @Prop({required: false}) indexList!: object

    @Prop({required: false}) contentList!: object

    @Prop({required: false}) type!: string


    @State('langCode') langCode

    mounted(){
        document.addEventListener('click', () => {
            this.dlgVisible = false;
        })
    }

    jump(id){
        if(id === '#'){
            document.querySelector('#' + this.type + '\\#')?.scrollIntoView({behavior: "smooth"})   
        }else{
            document.querySelector('#' + this.type + id)?.scrollIntoView({behavior: "smooth"})
        }
    }

    handleClick(brand){
        this.$emit('handleClick',brand, this.type)
        this.dlgVisible = false;
    }
}
