import axios from 'axios'
import { merge, cloneDeep } from 'lodash'
import Cookies from 'js-cookie'
import { method } from './var'
import apiConfig from './configs'
import $config from '~/config'
import api from '~/api'
import common from '~/utils/common'

// 请求拦截
// axios.interceptors.request.use(
//     config => {
//         console.log('config',config)
//         // if (config.method === 'post') {
//             // config.data = qs.stringify(config.data)
//         // }
//         return config
//     },
//     error => {
//         return Promise.reject(error)
//     }
// );

// 响应拦截
// axios.interceptors.response.use(function (response) {
//     // 处理响应数据
//     return response;
// }, function (error) {
//     // 处理响应失败
//     return Promise.reject(error);
// });

let defaultAjaxOptions = {}
defaultAjaxOptions[method.Get] = {
    headers: {
        'cache-control': 'no-cache',
    },
}
defaultAjaxOptions[method.Post] = {
    headers: {
        'content-type': 'application/json',
        'cache-control': 'no-cache',
    },
}
defaultAjaxOptions[method.Delete] = {
    headers: {
        'content-type': 'application/json',
        'cache-control': 'no-cache',
    },
}
defaultAjaxOptions[method.Put] = {
    headers: {
        'content-type': 'application/json',
        'cache-control': 'no-cache',
    },
}

/**
 * 继承默认配置
 * @param {any} ops 配置对象
 * @returns 返回继承后的配置
 */
function extendDefaultOptions(ops) {
    let defaultOps = cloneDeep($config.requestHeader)
    const env = process.env.CUSTOM_ENV
    const ifServer = (<any>process).server
    if (ifServer === true) {
        defaultOps.baseURL = $config.eggmallWebService[env].url
    } else {
        defaultOps.headers.touristId = window.localStorage.getItem('cusotmerUUID') || ''

        defaultOps.headers.langCode = window.localStorage.getItem('langCode') || 'en-TH'
        let token = window.localStorage.getItem('token') || Cookies.get('token') || ''
        if (token) {
            defaultOps.headers.token = token
        }

        if (window.localStorage.getItem('priceItem')) {
            let priceItem = window.localStorage.getItem('priceItem') || '{}'
            if (JSON.parse(priceItem).priceId) {
                defaultOps.headers.priceId = JSON.parse(priceItem).priceId
            }
        }

        let countryInfo = common.getCountryInfo()
        defaultOps.headers.countryCode = countryInfo.countryCode
        defaultOps.headers.countryId = countryInfo.countryId
    }
    if (typeof ops != 'object') {
        ops = defaultOps
    }
    return merge({}, defaultAjaxOptions[(ops.method || method.Get)], ops, defaultOps)
}

function isFormatUrl(url) {
    return url.indexOf('{') !== -1 && url.indexOf('}') !== -1
}

function formatUrl(url, dataModel, options) {
    if (!options.hasOwnProperty('removeFormatModelProp')) {
        // 默认移除datamodel中的已经在url中使用的format属性
        options.removeFormatModelProp = true
    }
    if (isFormatUrl(url)) {
        if (dataModel) {
            Object.keys(dataModel).forEach(key => {
                let varName = '{' + key + '}'
                if (url.indexOf(varName) !== -1) {
                    url = url.replace(new RegExp(varName, 'gm'), dataModel[key])
                    // 格式化url后是否将datamodel中的对应属性移除
                    if (options.removeFormatModelProp) {
                        delete dataModel[key]
                    }
                }
            })
        } else {
            url.split('{').forEach(item => {
                if (item.indexOf('}') === item.length - 1) {
                    url = url.replace('{' + item, '')
                }
            })
        }
        return url
    }
    return url
}

/**
 * 根据环境类型将配置对象做不同适应
 * Production：必须获取production对应的url，method也不会变；
 * Development：先获取Development的url，没有在获取Static的url
 * Static：获取Static对应的url，并改变method为GET
 * @param {any} options 配置对象
 * @return 适应后的配置对象
 */
function adaptEnv(options, dataModel) {
    let apiUrlPrefix = options.localApiUrlPrefix ? options.localApiUrlPrefix : $config.apiUrlPrefix
    options.url = apiUrlPrefix + options.url
    if (Object.prototype.toString.call(dataModel) === '[object FormData]') {
        options.data = dataModel
    } else if (typeof dataModel === 'object' && dataModel != null) {
        options.url = formatUrl(options.url, dataModel, options)
        if (options.method !== method.Get && options.headers && options.headers['content-type'] === 'application/json') {
            if (Array.isArray(dataModel)) {
                options.data = dataModel
            } else {
                options.data = options.data || {}
                merge(options.data, dataModel)
            }
            options.data = JSON.stringify(options.data)
        } else if (options.method === method.Get) {
            options.params = options.params || {}
            options.params = merge({}, options.params, dataModel)
        }
    } else if (options.method === method.Get) {
        options.params = options.params || {}
        options.params = merge({}, options.params, dataModel)
    } else {
        options.data = dataModel
    }
    return options
}

/**
 * 根据apiName获取api的配置
 *
 * @param {any} apiName
 * @param {any} otherOptions 其他的配置信息
 * @returns apiName的配置与otherOptions的合并
 */
function getApiOptions(apiName, dataModel?: any, otherOptions?: any): any {
    let options = {}
    if (typeof apiName === 'string') {
        if (!apiConfig.hasOwnProperty(apiName)) {
            console.warn("[Api Warning]没有在API列表中查找到名称为'" + apiName + "'的接口配置，已将'" + apiName + "'作为默认url。")
            options = extendDefaultOptions({
                url: apiName,
            })
        } else {
            options = extendDefaultOptions(apiConfig[apiName])
        }
    } else if (typeof apiName === 'object' && apiName.url) {
        options = extendDefaultOptions(apiName)
    } else {
        throw new TypeError('[Api Error]没有在API列表中查找到' + JSON.stringify(apiName) + '有关的配置')
    }
    if (typeof otherOptions === 'object') {
        options = merge(options, otherOptions)
    }
    options = adaptEnv(options, dataModel)
    return options
}

/**、、
 * 根据API配置创建一个可发送request的函数
 *
 * @param {any} apiName api name
 * @param {any} otherAjaxOptions 其他请求配置
 * @returns
 */
function createApiRequest(apiName, otherOptions?: any) {
    return function (dataModel, headers, serverApp) {
        let ops: any = getApiOptions(apiName, dataModel, otherOptions)
        ops.headers.businessPlatform = 'EGG_MALL_WEB'
        if (headers) {  // 是否存在headers添加
            for (let h in headers) {
                if (headers[h]) {
                    ops.headers[h] = headers[h]
                }
            }
        }
        if ((<any>process).server && serverApp) {
            Object.assign(ops.headers, {
                cookie: (serverApp.req.headers.cookie && serverApp.req.headers.cookie.replace(/%25/g, '%')) || '',
                RequestHost: $config.eggmallWebService[process.env.CUSTOM_ENV].host,
                'X-Forwarded-For': serverApp.req.headers['x-forwarded-for'],
                // RequestHost: 'www.eggmalltech.com',
                // RequestHost: 'www.test.jimuitech.com',
            })
            // ops.url = (serverApp.req.headers['x-forwarded-proto'] || 'http') + '://'
            //     + (serverApp.req.headers['original-host'] ? serverApp.req.headers['original-host'] : serverApp.req.headers.host)
            //     + (serverApp.req.headers['original-port'] ? ':' + serverApp.req.headers['original-port'] : '')
            //     + serverApp.url
        }
        if ((<any>process).server) {
            if (serverApp.req.headers.cookie && serverApp.req.headers.cookie.includes('tokenType=CIAM')) {
                ops.headers.tokenType = 'CIAM'
            }
        } else if (Cookies.get('tokenType') === 'CIAM') {
            ops.headers.tokenType = 'CIAM'
        }

        return sendApiRequest(ops)
    }
}

function sendApiRequest(requestOptions) {
    let ops = requestOptions
    return new Promise(((resolve, reject) => {
        axios(ops).then((response: any) => {
            if(response.data.code != 200){
                console.log('response error',response.data,response.config.url);
            }
            if ((<any>process).server) {
                // 日志打印太多了，注释掉
                // console.log('\n\n\n', ops.url, '\n', JSON.stringify(ops), '\n', JSON.stringify(response.data))
            }
            if (response.data && response.data.code == 401) {
                console.log('response.data',response.data);
                
                if (!(<any> process).server) {
                    window.localStorage.removeItem('token')
                    window.localStorage.removeItem('userInfo')
                    window.localStorage.removeItem('priceItem')
                    common.removeAccountInfo()
                    window.$nuxt.$message.error({ message: window.$nuxt.$t('label_login_expired') })
                    // setTimeout(() => {
                    //     window.location.href = '/'     
                    // }, 2000);
                } else if (api.context) {
                    api.context.res.clearCookie('token')
                    api.context.res.clearCookie('priceId')
                    // api.context.redirect('/')
                }
                reject(response.data)
            } else {
                resolve(response.data)
            }
        }).catch(error => {
            if ((<any>process).server) {
                // console.error('\n\n\nhttp error', ops.url, '\n', error.message, '\n', JSON.stringify(ops))
            }
            reject(error)
        })
    }))
}

const apiHelper = {
    createApiRequest,
    getApiOptions,
}
export default apiHelper
