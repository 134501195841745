import { Message } from 'element-ui'

export default ({app, redirect}) => {
    app.router.beforeEach((to, from, next) => {
        if (to.query.errorMsg) {
            Message({
                message: to.query.errorMsg,
                type: 'error',
                duration: 3 * 1000,
            })
            to.query.errorMsg = ''
            redirect(to.path)
        }
        if ((<any> process).client) {
            if (from.query.origin === 'REPAIR_WEB' && to.query.origin !== 'REPAIR_WEB' && app.router.currentRoute.name !== to.name) {
                to.query.origin = 'REPAIR_WEB'
                // return next(to)
                // redirect(to)    // 用nuxt的redirect防止抛出错误
                return
            }
        }
        if (to.query.changeRoute) {
            next()
        } else if (to.fullPath.startsWith('/en-TH')) {
            const fullPath = to.fullPath.replace('/en-TH', '/en')
            next(fullPath)
        } else {
            next()
        }
    })
}
