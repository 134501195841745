import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e9b98470 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _54d0842c = () => interopDefault(import('../pages/banner-promotion.vue' /* webpackChunkName: "pages/banner-promotion" */))
const _494a69cb = () => interopDefault(import('../pages/brandList.vue' /* webpackChunkName: "pages/brandList" */))
const _3a07e106 = () => interopDefault(import('../pages/category-detail.vue' /* webpackChunkName: "pages/category-detail" */))
const _6e5db6ed = () => interopDefault(import('../pages/FAQDetail.vue' /* webpackChunkName: "pages/FAQDetail" */))
const _108ea482 = () => interopDefault(import('../pages/knowledgeArticle.vue' /* webpackChunkName: "pages/knowledgeArticle" */))
const _5eea240e = () => interopDefault(import('../pages/knowledgeCenter.vue' /* webpackChunkName: "pages/knowledgeCenter" */))
const _a9b45a90 = () => interopDefault(import('../pages/my-favorite-list.vue' /* webpackChunkName: "pages/my-favorite-list" */))
const _3b39244a = () => interopDefault(import('../pages/myPoint.vue' /* webpackChunkName: "pages/myPoint" */))
const _105fbc4e = () => interopDefault(import('../pages/myPointPreview.vue' /* webpackChunkName: "pages/myPointPreview" */))
const _e09d9f84 = () => interopDefault(import('../pages/myPointTH.vue' /* webpackChunkName: "pages/myPointTH" */))
const _0d84e388 = () => interopDefault(import('../pages/new-promotion.vue' /* webpackChunkName: "pages/new-promotion" */))
const _0f223d12 = () => interopDefault(import('../pages/redeemDetail.vue' /* webpackChunkName: "pages/redeemDetail" */))
const _1e609130 = () => interopDefault(import('../pages/search-result.vue' /* webpackChunkName: "pages/search-result" */))
const _3b81e796 = () => interopDefault(import('../pages/select-category.vue' /* webpackChunkName: "pages/select-category" */))
const _61d8c3e0 = () => interopDefault(import('../pages/vietnamPolicy.vue' /* webpackChunkName: "pages/vietnamPolicy" */))
const _4c06a5ca = () => interopDefault(import('../pages/credit/credit-details.vue' /* webpackChunkName: "pages/credit/credit-details" */))
const _4bafc1c8 = () => interopDefault(import('../pages/credit/credit-history.vue' /* webpackChunkName: "pages/credit/credit-history" */))
const _4b668f74 = () => interopDefault(import('../pages/credit/credit-list.vue' /* webpackChunkName: "pages/credit/credit-list" */))
const _21134f10 = () => interopDefault(import('../pages/order/order-coupon.vue' /* webpackChunkName: "pages/order/order-coupon" */))
const _820efc8a = () => interopDefault(import('../pages/order/order-detail.vue' /* webpackChunkName: "pages/order/order-detail" */))
const _1fd1e791 = () => interopDefault(import('../pages/order/order-detail-page.vue' /* webpackChunkName: "pages/order/order-detail-page" */))
const _5c1fd808 = () => interopDefault(import('../pages/order/order-list.vue' /* webpackChunkName: "pages/order/order-list" */))
const _4119148c = () => interopDefault(import('../pages/order/order-payment.vue' /* webpackChunkName: "pages/order/order-payment" */))
const _4ab128f2 = () => interopDefault(import('../pages/order/order-result.vue' /* webpackChunkName: "pages/order/order-result" */))
const _172bf5d0 = () => interopDefault(import('../pages/order/shop-cart-page.vue' /* webpackChunkName: "pages/order/shop-cart-page" */))
const _0666f0b6 = () => interopDefault(import('../pages/policy/Consent-to-collection-and-use-of-personal-information.vue' /* webpackChunkName: "pages/policy/Consent-to-collection-and-use-of-personal-information" */))
const _53c221ac = () => interopDefault(import('../pages/policy/Consent-to-overseas-transfer-of-personal-information.vue' /* webpackChunkName: "pages/policy/Consent-to-overseas-transfer-of-personal-information" */))
const _58cd94a9 = () => interopDefault(import('../pages/policy/points-policy.vue' /* webpackChunkName: "pages/policy/points-policy" */))
const _2c787f25 = () => interopDefault(import('../pages/policy/points-policy-detail.vue' /* webpackChunkName: "pages/policy/points-policy-detail" */))
const _2b8f161a = () => interopDefault(import('../pages/policy/privacy-policy.vue' /* webpackChunkName: "pages/policy/privacy-policy" */))
const _306e723c = () => interopDefault(import('../pages/policy/return-policy.vue' /* webpackChunkName: "pages/policy/return-policy" */))
const _5ac94484 = () => interopDefault(import('../pages/policy/shipping-policy.vue' /* webpackChunkName: "pages/policy/shipping-policy" */))
const _9397dc92 = () => interopDefault(import('../pages/policy/terms-and-conditions.vue' /* webpackChunkName: "pages/policy/terms-and-conditions" */))
const _ed2484a0 = () => interopDefault(import('../pages/policy/warranty-policy.vue' /* webpackChunkName: "pages/policy/warranty-policy" */))
const _0e8e2cf9 = () => interopDefault(import('../pages/product/product-detail.vue' /* webpackChunkName: "pages/product/product-detail" */))
const _18074a1b = () => interopDefault(import('../pages/recommend/recommend-bought-product.vue' /* webpackChunkName: "pages/recommend/recommend-bought-product" */))
const _084c3b24 = () => interopDefault(import('../pages/recommend/recommend-new-product.vue' /* webpackChunkName: "pages/recommend/recommend-new-product" */))
const _1ba94bd9 = () => interopDefault(import('../pages/shop/shop-detail.vue' /* webpackChunkName: "pages/shop/shop-detail" */))
const _57cedcb4 = () => interopDefault(import('../pages/shop/shop-list.vue' /* webpackChunkName: "pages/shop/shop-list" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en/about-us",
    component: _e9b98470,
    name: "about-us___en"
  }, {
    path: "/en-TH/about-us",
    component: _e9b98470,
    name: "about-us___en-TH"
  }, {
    path: "/th-TH/about-us",
    component: _e9b98470,
    name: "about-us___th-TH"
  }, {
    path: "/id/about-us",
    component: _e9b98470,
    name: "about-us___id"
  }, {
    path: "/zh/about-us",
    component: _e9b98470,
    name: "about-us___zh"
  }, {
    path: "/ms/about-us",
    component: _e9b98470,
    name: "about-us___ms"
  }, {
    path: "/vi/about-us",
    component: _e9b98470,
    name: "about-us___vi"
  }, {
    path: "/fil/about-us",
    component: _e9b98470,
    name: "about-us___fil"
  }, {
    path: "/ko/about-us",
    component: _e9b98470,
    name: "about-us___ko"
  }, {
    path: "/en/banner-promotion",
    component: _54d0842c,
    name: "banner-promotion___en"
  }, {
    path: "/en-TH/banner-promotion",
    component: _54d0842c,
    name: "banner-promotion___en-TH"
  }, {
    path: "/th-TH/banner-promotion",
    component: _54d0842c,
    name: "banner-promotion___th-TH"
  }, {
    path: "/id/banner-promotion",
    component: _54d0842c,
    name: "banner-promotion___id"
  }, {
    path: "/zh/banner-promotion",
    component: _54d0842c,
    name: "banner-promotion___zh"
  }, {
    path: "/ms/banner-promotion",
    component: _54d0842c,
    name: "banner-promotion___ms"
  }, {
    path: "/vi/banner-promotion",
    component: _54d0842c,
    name: "banner-promotion___vi"
  }, {
    path: "/fil/banner-promotion",
    component: _54d0842c,
    name: "banner-promotion___fil"
  }, {
    path: "/ko/banner-promotion",
    component: _54d0842c,
    name: "banner-promotion___ko"
  }, {
    path: "/en/brandList",
    component: _494a69cb,
    name: "brandList___en"
  }, {
    path: "/en-TH/brandList",
    component: _494a69cb,
    name: "brandList___en-TH"
  }, {
    path: "/th-TH/brandList",
    component: _494a69cb,
    name: "brandList___th-TH"
  }, {
    path: "/id/brandList",
    component: _494a69cb,
    name: "brandList___id"
  }, {
    path: "/zh/brandList",
    component: _494a69cb,
    name: "brandList___zh"
  }, {
    path: "/ms/brandList",
    component: _494a69cb,
    name: "brandList___ms"
  }, {
    path: "/vi/brandList",
    component: _494a69cb,
    name: "brandList___vi"
  }, {
    path: "/fil/brandList",
    component: _494a69cb,
    name: "brandList___fil"
  }, {
    path: "/ko/brandList",
    component: _494a69cb,
    name: "brandList___ko"
  }, {
    path: "/en/category-detail",
    component: _3a07e106,
    name: "category-detail___en"
  }, {
    path: "/en-TH/category-detail",
    component: _3a07e106,
    name: "category-detail___en-TH"
  }, {
    path: "/th-TH/category-detail",
    component: _3a07e106,
    name: "category-detail___th-TH"
  }, {
    path: "/id/category-detail",
    component: _3a07e106,
    name: "category-detail___id"
  }, {
    path: "/zh/category-detail",
    component: _3a07e106,
    name: "category-detail___zh"
  }, {
    path: "/ms/category-detail",
    component: _3a07e106,
    name: "category-detail___ms"
  }, {
    path: "/vi/category-detail",
    component: _3a07e106,
    name: "category-detail___vi"
  }, {
    path: "/fil/category-detail",
    component: _3a07e106,
    name: "category-detail___fil"
  }, {
    path: "/ko/category-detail",
    component: _3a07e106,
    name: "category-detail___ko"
  }, {
    path: "/en/FAQDetail",
    component: _6e5db6ed,
    name: "FAQDetail___en"
  }, {
    path: "/en-TH/FAQDetail",
    component: _6e5db6ed,
    name: "FAQDetail___en-TH"
  }, {
    path: "/th-TH/FAQDetail",
    component: _6e5db6ed,
    name: "FAQDetail___th-TH"
  }, {
    path: "/id/FAQDetail",
    component: _6e5db6ed,
    name: "FAQDetail___id"
  }, {
    path: "/zh/FAQDetail",
    component: _6e5db6ed,
    name: "FAQDetail___zh"
  }, {
    path: "/ms/FAQDetail",
    component: _6e5db6ed,
    name: "FAQDetail___ms"
  }, {
    path: "/vi/FAQDetail",
    component: _6e5db6ed,
    name: "FAQDetail___vi"
  }, {
    path: "/fil/FAQDetail",
    component: _6e5db6ed,
    name: "FAQDetail___fil"
  }, {
    path: "/ko/FAQDetail",
    component: _6e5db6ed,
    name: "FAQDetail___ko"
  }, {
    path: "/en/knowledgeArticle",
    component: _108ea482,
    name: "knowledgeArticle___en"
  }, {
    path: "/en-TH/knowledgeArticle",
    component: _108ea482,
    name: "knowledgeArticle___en-TH"
  }, {
    path: "/th-TH/knowledgeArticle",
    component: _108ea482,
    name: "knowledgeArticle___th-TH"
  }, {
    path: "/id/knowledgeArticle",
    component: _108ea482,
    name: "knowledgeArticle___id"
  }, {
    path: "/zh/knowledgeArticle",
    component: _108ea482,
    name: "knowledgeArticle___zh"
  }, {
    path: "/ms/knowledgeArticle",
    component: _108ea482,
    name: "knowledgeArticle___ms"
  }, {
    path: "/vi/knowledgeArticle",
    component: _108ea482,
    name: "knowledgeArticle___vi"
  }, {
    path: "/fil/knowledgeArticle",
    component: _108ea482,
    name: "knowledgeArticle___fil"
  }, {
    path: "/ko/knowledgeArticle",
    component: _108ea482,
    name: "knowledgeArticle___ko"
  }, {
    path: "/en/knowledgeCenter",
    component: _5eea240e,
    name: "knowledgeCenter___en"
  }, {
    path: "/en-TH/knowledgeCenter",
    component: _5eea240e,
    name: "knowledgeCenter___en-TH"
  }, {
    path: "/th-TH/knowledgeCenter",
    component: _5eea240e,
    name: "knowledgeCenter___th-TH"
  }, {
    path: "/id/knowledgeCenter",
    component: _5eea240e,
    name: "knowledgeCenter___id"
  }, {
    path: "/zh/knowledgeCenter",
    component: _5eea240e,
    name: "knowledgeCenter___zh"
  }, {
    path: "/ms/knowledgeCenter",
    component: _5eea240e,
    name: "knowledgeCenter___ms"
  }, {
    path: "/vi/knowledgeCenter",
    component: _5eea240e,
    name: "knowledgeCenter___vi"
  }, {
    path: "/fil/knowledgeCenter",
    component: _5eea240e,
    name: "knowledgeCenter___fil"
  }, {
    path: "/ko/knowledgeCenter",
    component: _5eea240e,
    name: "knowledgeCenter___ko"
  }, {
    path: "/en/my-favorite-list",
    component: _a9b45a90,
    name: "my-favorite-list___en"
  }, {
    path: "/en-TH/my-favorite-list",
    component: _a9b45a90,
    name: "my-favorite-list___en-TH"
  }, {
    path: "/th-TH/my-favorite-list",
    component: _a9b45a90,
    name: "my-favorite-list___th-TH"
  }, {
    path: "/id/my-favorite-list",
    component: _a9b45a90,
    name: "my-favorite-list___id"
  }, {
    path: "/zh/my-favorite-list",
    component: _a9b45a90,
    name: "my-favorite-list___zh"
  }, {
    path: "/ms/my-favorite-list",
    component: _a9b45a90,
    name: "my-favorite-list___ms"
  }, {
    path: "/vi/my-favorite-list",
    component: _a9b45a90,
    name: "my-favorite-list___vi"
  }, {
    path: "/fil/my-favorite-list",
    component: _a9b45a90,
    name: "my-favorite-list___fil"
  }, {
    path: "/ko/my-favorite-list",
    component: _a9b45a90,
    name: "my-favorite-list___ko"
  }, {
    path: "/en/myPoint",
    component: _3b39244a,
    name: "myPoint___en"
  }, {
    path: "/en-TH/myPoint",
    component: _3b39244a,
    name: "myPoint___en-TH"
  }, {
    path: "/th-TH/myPoint",
    component: _3b39244a,
    name: "myPoint___th-TH"
  }, {
    path: "/id/myPoint",
    component: _3b39244a,
    name: "myPoint___id"
  }, {
    path: "/zh/myPoint",
    component: _3b39244a,
    name: "myPoint___zh"
  }, {
    path: "/ms/myPoint",
    component: _3b39244a,
    name: "myPoint___ms"
  }, {
    path: "/vi/myPoint",
    component: _3b39244a,
    name: "myPoint___vi"
  }, {
    path: "/fil/myPoint",
    component: _3b39244a,
    name: "myPoint___fil"
  }, {
    path: "/ko/myPoint",
    component: _3b39244a,
    name: "myPoint___ko"
  }, {
    path: "/en/myPointPreview",
    component: _105fbc4e,
    name: "myPointPreview___en"
  }, {
    path: "/en-TH/myPointPreview",
    component: _105fbc4e,
    name: "myPointPreview___en-TH"
  }, {
    path: "/th-TH/myPointPreview",
    component: _105fbc4e,
    name: "myPointPreview___th-TH"
  }, {
    path: "/id/myPointPreview",
    component: _105fbc4e,
    name: "myPointPreview___id"
  }, {
    path: "/zh/myPointPreview",
    component: _105fbc4e,
    name: "myPointPreview___zh"
  }, {
    path: "/ms/myPointPreview",
    component: _105fbc4e,
    name: "myPointPreview___ms"
  }, {
    path: "/vi/myPointPreview",
    component: _105fbc4e,
    name: "myPointPreview___vi"
  }, {
    path: "/fil/myPointPreview",
    component: _105fbc4e,
    name: "myPointPreview___fil"
  }, {
    path: "/ko/myPointPreview",
    component: _105fbc4e,
    name: "myPointPreview___ko"
  }, {
    path: "/en/myPointTH",
    component: _e09d9f84,
    name: "myPointTH___en"
  }, {
    path: "/en-TH/myPointTH",
    component: _e09d9f84,
    name: "myPointTH___en-TH"
  }, {
    path: "/th-TH/myPointTH",
    component: _e09d9f84,
    name: "myPointTH___th-TH"
  }, {
    path: "/id/myPointTH",
    component: _e09d9f84,
    name: "myPointTH___id"
  }, {
    path: "/zh/myPointTH",
    component: _e09d9f84,
    name: "myPointTH___zh"
  }, {
    path: "/ms/myPointTH",
    component: _e09d9f84,
    name: "myPointTH___ms"
  }, {
    path: "/vi/myPointTH",
    component: _e09d9f84,
    name: "myPointTH___vi"
  }, {
    path: "/fil/myPointTH",
    component: _e09d9f84,
    name: "myPointTH___fil"
  }, {
    path: "/ko/myPointTH",
    component: _e09d9f84,
    name: "myPointTH___ko"
  }, {
    path: "/en/new-promotion",
    component: _0d84e388,
    name: "new-promotion___en"
  }, {
    path: "/en-TH/new-promotion",
    component: _0d84e388,
    name: "new-promotion___en-TH"
  }, {
    path: "/th-TH/new-promotion",
    component: _0d84e388,
    name: "new-promotion___th-TH"
  }, {
    path: "/id/new-promotion",
    component: _0d84e388,
    name: "new-promotion___id"
  }, {
    path: "/zh/new-promotion",
    component: _0d84e388,
    name: "new-promotion___zh"
  }, {
    path: "/ms/new-promotion",
    component: _0d84e388,
    name: "new-promotion___ms"
  }, {
    path: "/vi/new-promotion",
    component: _0d84e388,
    name: "new-promotion___vi"
  }, {
    path: "/fil/new-promotion",
    component: _0d84e388,
    name: "new-promotion___fil"
  }, {
    path: "/ko/new-promotion",
    component: _0d84e388,
    name: "new-promotion___ko"
  }, {
    path: "/en/redeemDetail",
    component: _0f223d12,
    name: "redeemDetail___en"
  }, {
    path: "/en-TH/redeemDetail",
    component: _0f223d12,
    name: "redeemDetail___en-TH"
  }, {
    path: "/th-TH/redeemDetail",
    component: _0f223d12,
    name: "redeemDetail___th-TH"
  }, {
    path: "/id/redeemDetail",
    component: _0f223d12,
    name: "redeemDetail___id"
  }, {
    path: "/zh/redeemDetail",
    component: _0f223d12,
    name: "redeemDetail___zh"
  }, {
    path: "/ms/redeemDetail",
    component: _0f223d12,
    name: "redeemDetail___ms"
  }, {
    path: "/vi/redeemDetail",
    component: _0f223d12,
    name: "redeemDetail___vi"
  }, {
    path: "/fil/redeemDetail",
    component: _0f223d12,
    name: "redeemDetail___fil"
  }, {
    path: "/ko/redeemDetail",
    component: _0f223d12,
    name: "redeemDetail___ko"
  }, {
    path: "/en/search-result",
    component: _1e609130,
    name: "search-result___en"
  }, {
    path: "/en-TH/search-result",
    component: _1e609130,
    name: "search-result___en-TH"
  }, {
    path: "/th-TH/search-result",
    component: _1e609130,
    name: "search-result___th-TH"
  }, {
    path: "/id/search-result",
    component: _1e609130,
    name: "search-result___id"
  }, {
    path: "/zh/search-result",
    component: _1e609130,
    name: "search-result___zh"
  }, {
    path: "/ms/search-result",
    component: _1e609130,
    name: "search-result___ms"
  }, {
    path: "/vi/search-result",
    component: _1e609130,
    name: "search-result___vi"
  }, {
    path: "/fil/search-result",
    component: _1e609130,
    name: "search-result___fil"
  }, {
    path: "/ko/search-result",
    component: _1e609130,
    name: "search-result___ko"
  }, {
    path: "/en/select-category",
    component: _3b81e796,
    name: "select-category___en"
  }, {
    path: "/en-TH/select-category",
    component: _3b81e796,
    name: "select-category___en-TH"
  }, {
    path: "/th-TH/select-category",
    component: _3b81e796,
    name: "select-category___th-TH"
  }, {
    path: "/id/select-category",
    component: _3b81e796,
    name: "select-category___id"
  }, {
    path: "/zh/select-category",
    component: _3b81e796,
    name: "select-category___zh"
  }, {
    path: "/ms/select-category",
    component: _3b81e796,
    name: "select-category___ms"
  }, {
    path: "/vi/select-category",
    component: _3b81e796,
    name: "select-category___vi"
  }, {
    path: "/fil/select-category",
    component: _3b81e796,
    name: "select-category___fil"
  }, {
    path: "/ko/select-category",
    component: _3b81e796,
    name: "select-category___ko"
  }, {
    path: "/en/vietnamPolicy",
    component: _61d8c3e0,
    name: "vietnamPolicy___en"
  }, {
    path: "/en-TH/vietnamPolicy",
    component: _61d8c3e0,
    name: "vietnamPolicy___en-TH"
  }, {
    path: "/th-TH/vietnamPolicy",
    component: _61d8c3e0,
    name: "vietnamPolicy___th-TH"
  }, {
    path: "/id/vietnamPolicy",
    component: _61d8c3e0,
    name: "vietnamPolicy___id"
  }, {
    path: "/zh/vietnamPolicy",
    component: _61d8c3e0,
    name: "vietnamPolicy___zh"
  }, {
    path: "/ms/vietnamPolicy",
    component: _61d8c3e0,
    name: "vietnamPolicy___ms"
  }, {
    path: "/vi/vietnamPolicy",
    component: _61d8c3e0,
    name: "vietnamPolicy___vi"
  }, {
    path: "/fil/vietnamPolicy",
    component: _61d8c3e0,
    name: "vietnamPolicy___fil"
  }, {
    path: "/ko/vietnamPolicy",
    component: _61d8c3e0,
    name: "vietnamPolicy___ko"
  }, {
    path: "/en/credit/credit-details",
    component: _4c06a5ca,
    name: "credit-credit-details___en"
  }, {
    path: "/en-TH/credit/credit-details",
    component: _4c06a5ca,
    name: "credit-credit-details___en-TH"
  }, {
    path: "/th-TH/credit/credit-details",
    component: _4c06a5ca,
    name: "credit-credit-details___th-TH"
  }, {
    path: "/id/credit/credit-details",
    component: _4c06a5ca,
    name: "credit-credit-details___id"
  }, {
    path: "/zh/credit/credit-details",
    component: _4c06a5ca,
    name: "credit-credit-details___zh"
  }, {
    path: "/ms/credit/credit-details",
    component: _4c06a5ca,
    name: "credit-credit-details___ms"
  }, {
    path: "/vi/credit/credit-details",
    component: _4c06a5ca,
    name: "credit-credit-details___vi"
  }, {
    path: "/fil/credit/credit-details",
    component: _4c06a5ca,
    name: "credit-credit-details___fil"
  }, {
    path: "/ko/credit/credit-details",
    component: _4c06a5ca,
    name: "credit-credit-details___ko"
  }, {
    path: "/en/credit/credit-history",
    component: _4bafc1c8,
    name: "credit-credit-history___en"
  }, {
    path: "/en-TH/credit/credit-history",
    component: _4bafc1c8,
    name: "credit-credit-history___en-TH"
  }, {
    path: "/th-TH/credit/credit-history",
    component: _4bafc1c8,
    name: "credit-credit-history___th-TH"
  }, {
    path: "/id/credit/credit-history",
    component: _4bafc1c8,
    name: "credit-credit-history___id"
  }, {
    path: "/zh/credit/credit-history",
    component: _4bafc1c8,
    name: "credit-credit-history___zh"
  }, {
    path: "/ms/credit/credit-history",
    component: _4bafc1c8,
    name: "credit-credit-history___ms"
  }, {
    path: "/vi/credit/credit-history",
    component: _4bafc1c8,
    name: "credit-credit-history___vi"
  }, {
    path: "/fil/credit/credit-history",
    component: _4bafc1c8,
    name: "credit-credit-history___fil"
  }, {
    path: "/ko/credit/credit-history",
    component: _4bafc1c8,
    name: "credit-credit-history___ko"
  }, {
    path: "/en/credit/credit-list",
    component: _4b668f74,
    name: "credit-credit-list___en"
  }, {
    path: "/en-TH/credit/credit-list",
    component: _4b668f74,
    name: "credit-credit-list___en-TH"
  }, {
    path: "/th-TH/credit/credit-list",
    component: _4b668f74,
    name: "credit-credit-list___th-TH"
  }, {
    path: "/id/credit/credit-list",
    component: _4b668f74,
    name: "credit-credit-list___id"
  }, {
    path: "/zh/credit/credit-list",
    component: _4b668f74,
    name: "credit-credit-list___zh"
  }, {
    path: "/ms/credit/credit-list",
    component: _4b668f74,
    name: "credit-credit-list___ms"
  }, {
    path: "/vi/credit/credit-list",
    component: _4b668f74,
    name: "credit-credit-list___vi"
  }, {
    path: "/fil/credit/credit-list",
    component: _4b668f74,
    name: "credit-credit-list___fil"
  }, {
    path: "/ko/credit/credit-list",
    component: _4b668f74,
    name: "credit-credit-list___ko"
  }, {
    path: "/en/order/order-coupon",
    component: _21134f10,
    name: "order-order-coupon___en"
  }, {
    path: "/en-TH/order/order-coupon",
    component: _21134f10,
    name: "order-order-coupon___en-TH"
  }, {
    path: "/th-TH/order/order-coupon",
    component: _21134f10,
    name: "order-order-coupon___th-TH"
  }, {
    path: "/id/order/order-coupon",
    component: _21134f10,
    name: "order-order-coupon___id"
  }, {
    path: "/zh/order/order-coupon",
    component: _21134f10,
    name: "order-order-coupon___zh"
  }, {
    path: "/ms/order/order-coupon",
    component: _21134f10,
    name: "order-order-coupon___ms"
  }, {
    path: "/vi/order/order-coupon",
    component: _21134f10,
    name: "order-order-coupon___vi"
  }, {
    path: "/fil/order/order-coupon",
    component: _21134f10,
    name: "order-order-coupon___fil"
  }, {
    path: "/ko/order/order-coupon",
    component: _21134f10,
    name: "order-order-coupon___ko"
  }, {
    path: "/en/order/order-detail",
    component: _820efc8a,
    name: "order-order-detail___en"
  }, {
    path: "/en-TH/order/order-detail",
    component: _820efc8a,
    name: "order-order-detail___en-TH"
  }, {
    path: "/th-TH/order/order-detail",
    component: _820efc8a,
    name: "order-order-detail___th-TH"
  }, {
    path: "/id/order/order-detail",
    component: _820efc8a,
    name: "order-order-detail___id"
  }, {
    path: "/zh/order/order-detail",
    component: _820efc8a,
    name: "order-order-detail___zh"
  }, {
    path: "/ms/order/order-detail",
    component: _820efc8a,
    name: "order-order-detail___ms"
  }, {
    path: "/vi/order/order-detail",
    component: _820efc8a,
    name: "order-order-detail___vi"
  }, {
    path: "/fil/order/order-detail",
    component: _820efc8a,
    name: "order-order-detail___fil"
  }, {
    path: "/ko/order/order-detail",
    component: _820efc8a,
    name: "order-order-detail___ko"
  }, {
    path: "/en/order/order-detail-page",
    component: _1fd1e791,
    name: "order-order-detail-page___en"
  }, {
    path: "/en-TH/order/order-detail-page",
    component: _1fd1e791,
    name: "order-order-detail-page___en-TH"
  }, {
    path: "/th-TH/order/order-detail-page",
    component: _1fd1e791,
    name: "order-order-detail-page___th-TH"
  }, {
    path: "/id/order/order-detail-page",
    component: _1fd1e791,
    name: "order-order-detail-page___id"
  }, {
    path: "/zh/order/order-detail-page",
    component: _1fd1e791,
    name: "order-order-detail-page___zh"
  }, {
    path: "/ms/order/order-detail-page",
    component: _1fd1e791,
    name: "order-order-detail-page___ms"
  }, {
    path: "/vi/order/order-detail-page",
    component: _1fd1e791,
    name: "order-order-detail-page___vi"
  }, {
    path: "/fil/order/order-detail-page",
    component: _1fd1e791,
    name: "order-order-detail-page___fil"
  }, {
    path: "/ko/order/order-detail-page",
    component: _1fd1e791,
    name: "order-order-detail-page___ko"
  }, {
    path: "/en/order/order-list",
    component: _5c1fd808,
    name: "order-order-list___en"
  }, {
    path: "/en-TH/order/order-list",
    component: _5c1fd808,
    name: "order-order-list___en-TH"
  }, {
    path: "/th-TH/order/order-list",
    component: _5c1fd808,
    name: "order-order-list___th-TH"
  }, {
    path: "/id/order/order-list",
    component: _5c1fd808,
    name: "order-order-list___id"
  }, {
    path: "/zh/order/order-list",
    component: _5c1fd808,
    name: "order-order-list___zh"
  }, {
    path: "/ms/order/order-list",
    component: _5c1fd808,
    name: "order-order-list___ms"
  }, {
    path: "/vi/order/order-list",
    component: _5c1fd808,
    name: "order-order-list___vi"
  }, {
    path: "/fil/order/order-list",
    component: _5c1fd808,
    name: "order-order-list___fil"
  }, {
    path: "/ko/order/order-list",
    component: _5c1fd808,
    name: "order-order-list___ko"
  }, {
    path: "/en/order/order-payment",
    component: _4119148c,
    name: "order-order-payment___en"
  }, {
    path: "/en-TH/order/order-payment",
    component: _4119148c,
    name: "order-order-payment___en-TH"
  }, {
    path: "/th-TH/order/order-payment",
    component: _4119148c,
    name: "order-order-payment___th-TH"
  }, {
    path: "/id/order/order-payment",
    component: _4119148c,
    name: "order-order-payment___id"
  }, {
    path: "/zh/order/order-payment",
    component: _4119148c,
    name: "order-order-payment___zh"
  }, {
    path: "/ms/order/order-payment",
    component: _4119148c,
    name: "order-order-payment___ms"
  }, {
    path: "/vi/order/order-payment",
    component: _4119148c,
    name: "order-order-payment___vi"
  }, {
    path: "/fil/order/order-payment",
    component: _4119148c,
    name: "order-order-payment___fil"
  }, {
    path: "/ko/order/order-payment",
    component: _4119148c,
    name: "order-order-payment___ko"
  }, {
    path: "/en/order/order-result",
    component: _4ab128f2,
    name: "order-order-result___en"
  }, {
    path: "/en-TH/order/order-result",
    component: _4ab128f2,
    name: "order-order-result___en-TH"
  }, {
    path: "/th-TH/order/order-result",
    component: _4ab128f2,
    name: "order-order-result___th-TH"
  }, {
    path: "/id/order/order-result",
    component: _4ab128f2,
    name: "order-order-result___id"
  }, {
    path: "/zh/order/order-result",
    component: _4ab128f2,
    name: "order-order-result___zh"
  }, {
    path: "/ms/order/order-result",
    component: _4ab128f2,
    name: "order-order-result___ms"
  }, {
    path: "/vi/order/order-result",
    component: _4ab128f2,
    name: "order-order-result___vi"
  }, {
    path: "/fil/order/order-result",
    component: _4ab128f2,
    name: "order-order-result___fil"
  }, {
    path: "/ko/order/order-result",
    component: _4ab128f2,
    name: "order-order-result___ko"
  }, {
    path: "/en/order/shop-cart-page",
    component: _172bf5d0,
    name: "order-shop-cart-page___en"
  }, {
    path: "/en-TH/order/shop-cart-page",
    component: _172bf5d0,
    name: "order-shop-cart-page___en-TH"
  }, {
    path: "/th-TH/order/shop-cart-page",
    component: _172bf5d0,
    name: "order-shop-cart-page___th-TH"
  }, {
    path: "/id/order/shop-cart-page",
    component: _172bf5d0,
    name: "order-shop-cart-page___id"
  }, {
    path: "/zh/order/shop-cart-page",
    component: _172bf5d0,
    name: "order-shop-cart-page___zh"
  }, {
    path: "/ms/order/shop-cart-page",
    component: _172bf5d0,
    name: "order-shop-cart-page___ms"
  }, {
    path: "/vi/order/shop-cart-page",
    component: _172bf5d0,
    name: "order-shop-cart-page___vi"
  }, {
    path: "/fil/order/shop-cart-page",
    component: _172bf5d0,
    name: "order-shop-cart-page___fil"
  }, {
    path: "/ko/order/shop-cart-page",
    component: _172bf5d0,
    name: "order-shop-cart-page___ko"
  }, {
    path: "/en/policy/Consent-to-collection-and-use-of-personal-information",
    component: _0666f0b6,
    name: "policy-Consent-to-collection-and-use-of-personal-information___en"
  }, {
    path: "/en-TH/policy/Consent-to-collection-and-use-of-personal-information",
    component: _0666f0b6,
    name: "policy-Consent-to-collection-and-use-of-personal-information___en-TH"
  }, {
    path: "/th-TH/policy/Consent-to-collection-and-use-of-personal-information",
    component: _0666f0b6,
    name: "policy-Consent-to-collection-and-use-of-personal-information___th-TH"
  }, {
    path: "/id/policy/Consent-to-collection-and-use-of-personal-information",
    component: _0666f0b6,
    name: "policy-Consent-to-collection-and-use-of-personal-information___id"
  }, {
    path: "/zh/policy/Consent-to-collection-and-use-of-personal-information",
    component: _0666f0b6,
    name: "policy-Consent-to-collection-and-use-of-personal-information___zh"
  }, {
    path: "/ms/policy/Consent-to-collection-and-use-of-personal-information",
    component: _0666f0b6,
    name: "policy-Consent-to-collection-and-use-of-personal-information___ms"
  }, {
    path: "/vi/policy/Consent-to-collection-and-use-of-personal-information",
    component: _0666f0b6,
    name: "policy-Consent-to-collection-and-use-of-personal-information___vi"
  }, {
    path: "/fil/policy/Consent-to-collection-and-use-of-personal-information",
    component: _0666f0b6,
    name: "policy-Consent-to-collection-and-use-of-personal-information___fil"
  }, {
    path: "/ko/policy/Consent-to-collection-and-use-of-personal-information",
    component: _0666f0b6,
    name: "policy-Consent-to-collection-and-use-of-personal-information___ko"
  }, {
    path: "/en/policy/Consent-to-overseas-transfer-of-personal-information",
    component: _53c221ac,
    name: "policy-Consent-to-overseas-transfer-of-personal-information___en"
  }, {
    path: "/en-TH/policy/Consent-to-overseas-transfer-of-personal-information",
    component: _53c221ac,
    name: "policy-Consent-to-overseas-transfer-of-personal-information___en-TH"
  }, {
    path: "/th-TH/policy/Consent-to-overseas-transfer-of-personal-information",
    component: _53c221ac,
    name: "policy-Consent-to-overseas-transfer-of-personal-information___th-TH"
  }, {
    path: "/id/policy/Consent-to-overseas-transfer-of-personal-information",
    component: _53c221ac,
    name: "policy-Consent-to-overseas-transfer-of-personal-information___id"
  }, {
    path: "/zh/policy/Consent-to-overseas-transfer-of-personal-information",
    component: _53c221ac,
    name: "policy-Consent-to-overseas-transfer-of-personal-information___zh"
  }, {
    path: "/ms/policy/Consent-to-overseas-transfer-of-personal-information",
    component: _53c221ac,
    name: "policy-Consent-to-overseas-transfer-of-personal-information___ms"
  }, {
    path: "/vi/policy/Consent-to-overseas-transfer-of-personal-information",
    component: _53c221ac,
    name: "policy-Consent-to-overseas-transfer-of-personal-information___vi"
  }, {
    path: "/fil/policy/Consent-to-overseas-transfer-of-personal-information",
    component: _53c221ac,
    name: "policy-Consent-to-overseas-transfer-of-personal-information___fil"
  }, {
    path: "/ko/policy/Consent-to-overseas-transfer-of-personal-information",
    component: _53c221ac,
    name: "policy-Consent-to-overseas-transfer-of-personal-information___ko"
  }, {
    path: "/en/policy/points-policy",
    component: _58cd94a9,
    name: "policy-points-policy___en"
  }, {
    path: "/en-TH/policy/points-policy",
    component: _58cd94a9,
    name: "policy-points-policy___en-TH"
  }, {
    path: "/th-TH/policy/points-policy",
    component: _58cd94a9,
    name: "policy-points-policy___th-TH"
  }, {
    path: "/id/policy/points-policy",
    component: _58cd94a9,
    name: "policy-points-policy___id"
  }, {
    path: "/zh/policy/points-policy",
    component: _58cd94a9,
    name: "policy-points-policy___zh"
  }, {
    path: "/ms/policy/points-policy",
    component: _58cd94a9,
    name: "policy-points-policy___ms"
  }, {
    path: "/vi/policy/points-policy",
    component: _58cd94a9,
    name: "policy-points-policy___vi"
  }, {
    path: "/fil/policy/points-policy",
    component: _58cd94a9,
    name: "policy-points-policy___fil"
  }, {
    path: "/ko/policy/points-policy",
    component: _58cd94a9,
    name: "policy-points-policy___ko"
  }, {
    path: "/en/policy/points-policy-detail",
    component: _2c787f25,
    name: "policy-points-policy-detail___en"
  }, {
    path: "/en-TH/policy/points-policy-detail",
    component: _2c787f25,
    name: "policy-points-policy-detail___en-TH"
  }, {
    path: "/th-TH/policy/points-policy-detail",
    component: _2c787f25,
    name: "policy-points-policy-detail___th-TH"
  }, {
    path: "/id/policy/points-policy-detail",
    component: _2c787f25,
    name: "policy-points-policy-detail___id"
  }, {
    path: "/zh/policy/points-policy-detail",
    component: _2c787f25,
    name: "policy-points-policy-detail___zh"
  }, {
    path: "/ms/policy/points-policy-detail",
    component: _2c787f25,
    name: "policy-points-policy-detail___ms"
  }, {
    path: "/vi/policy/points-policy-detail",
    component: _2c787f25,
    name: "policy-points-policy-detail___vi"
  }, {
    path: "/fil/policy/points-policy-detail",
    component: _2c787f25,
    name: "policy-points-policy-detail___fil"
  }, {
    path: "/ko/policy/points-policy-detail",
    component: _2c787f25,
    name: "policy-points-policy-detail___ko"
  }, {
    path: "/en/policy/privacy-policy",
    component: _2b8f161a,
    name: "policy-privacy-policy___en"
  }, {
    path: "/en-TH/policy/privacy-policy",
    component: _2b8f161a,
    name: "policy-privacy-policy___en-TH"
  }, {
    path: "/th-TH/policy/privacy-policy",
    component: _2b8f161a,
    name: "policy-privacy-policy___th-TH"
  }, {
    path: "/id/policy/privacy-policy",
    component: _2b8f161a,
    name: "policy-privacy-policy___id"
  }, {
    path: "/zh/policy/privacy-policy",
    component: _2b8f161a,
    name: "policy-privacy-policy___zh"
  }, {
    path: "/ms/policy/privacy-policy",
    component: _2b8f161a,
    name: "policy-privacy-policy___ms"
  }, {
    path: "/vi/policy/privacy-policy",
    component: _2b8f161a,
    name: "policy-privacy-policy___vi"
  }, {
    path: "/fil/policy/privacy-policy",
    component: _2b8f161a,
    name: "policy-privacy-policy___fil"
  }, {
    path: "/ko/policy/privacy-policy",
    component: _2b8f161a,
    name: "policy-privacy-policy___ko"
  }, {
    path: "/en/policy/return-policy",
    component: _306e723c,
    name: "policy-return-policy___en"
  }, {
    path: "/en-TH/policy/return-policy",
    component: _306e723c,
    name: "policy-return-policy___en-TH"
  }, {
    path: "/th-TH/policy/return-policy",
    component: _306e723c,
    name: "policy-return-policy___th-TH"
  }, {
    path: "/id/policy/return-policy",
    component: _306e723c,
    name: "policy-return-policy___id"
  }, {
    path: "/zh/policy/return-policy",
    component: _306e723c,
    name: "policy-return-policy___zh"
  }, {
    path: "/ms/policy/return-policy",
    component: _306e723c,
    name: "policy-return-policy___ms"
  }, {
    path: "/vi/policy/return-policy",
    component: _306e723c,
    name: "policy-return-policy___vi"
  }, {
    path: "/fil/policy/return-policy",
    component: _306e723c,
    name: "policy-return-policy___fil"
  }, {
    path: "/ko/policy/return-policy",
    component: _306e723c,
    name: "policy-return-policy___ko"
  }, {
    path: "/en/policy/shipping-policy",
    component: _5ac94484,
    name: "policy-shipping-policy___en"
  }, {
    path: "/en-TH/policy/shipping-policy",
    component: _5ac94484,
    name: "policy-shipping-policy___en-TH"
  }, {
    path: "/th-TH/policy/shipping-policy",
    component: _5ac94484,
    name: "policy-shipping-policy___th-TH"
  }, {
    path: "/id/policy/shipping-policy",
    component: _5ac94484,
    name: "policy-shipping-policy___id"
  }, {
    path: "/zh/policy/shipping-policy",
    component: _5ac94484,
    name: "policy-shipping-policy___zh"
  }, {
    path: "/ms/policy/shipping-policy",
    component: _5ac94484,
    name: "policy-shipping-policy___ms"
  }, {
    path: "/vi/policy/shipping-policy",
    component: _5ac94484,
    name: "policy-shipping-policy___vi"
  }, {
    path: "/fil/policy/shipping-policy",
    component: _5ac94484,
    name: "policy-shipping-policy___fil"
  }, {
    path: "/ko/policy/shipping-policy",
    component: _5ac94484,
    name: "policy-shipping-policy___ko"
  }, {
    path: "/en/policy/terms-and-conditions",
    component: _9397dc92,
    name: "policy-terms-and-conditions___en"
  }, {
    path: "/en-TH/policy/terms-and-conditions",
    component: _9397dc92,
    name: "policy-terms-and-conditions___en-TH"
  }, {
    path: "/th-TH/policy/terms-and-conditions",
    component: _9397dc92,
    name: "policy-terms-and-conditions___th-TH"
  }, {
    path: "/id/policy/terms-and-conditions",
    component: _9397dc92,
    name: "policy-terms-and-conditions___id"
  }, {
    path: "/zh/policy/terms-and-conditions",
    component: _9397dc92,
    name: "policy-terms-and-conditions___zh"
  }, {
    path: "/ms/policy/terms-and-conditions",
    component: _9397dc92,
    name: "policy-terms-and-conditions___ms"
  }, {
    path: "/vi/policy/terms-and-conditions",
    component: _9397dc92,
    name: "policy-terms-and-conditions___vi"
  }, {
    path: "/fil/policy/terms-and-conditions",
    component: _9397dc92,
    name: "policy-terms-and-conditions___fil"
  }, {
    path: "/ko/policy/terms-and-conditions",
    component: _9397dc92,
    name: "policy-terms-and-conditions___ko"
  }, {
    path: "/en/policy/warranty-policy",
    component: _ed2484a0,
    name: "policy-warranty-policy___en"
  }, {
    path: "/en-TH/policy/warranty-policy",
    component: _ed2484a0,
    name: "policy-warranty-policy___en-TH"
  }, {
    path: "/th-TH/policy/warranty-policy",
    component: _ed2484a0,
    name: "policy-warranty-policy___th-TH"
  }, {
    path: "/id/policy/warranty-policy",
    component: _ed2484a0,
    name: "policy-warranty-policy___id"
  }, {
    path: "/zh/policy/warranty-policy",
    component: _ed2484a0,
    name: "policy-warranty-policy___zh"
  }, {
    path: "/ms/policy/warranty-policy",
    component: _ed2484a0,
    name: "policy-warranty-policy___ms"
  }, {
    path: "/vi/policy/warranty-policy",
    component: _ed2484a0,
    name: "policy-warranty-policy___vi"
  }, {
    path: "/fil/policy/warranty-policy",
    component: _ed2484a0,
    name: "policy-warranty-policy___fil"
  }, {
    path: "/ko/policy/warranty-policy",
    component: _ed2484a0,
    name: "policy-warranty-policy___ko"
  }, {
    path: "/en/product/product-detail",
    component: _0e8e2cf9,
    name: "product-product-detail___en"
  }, {
    path: "/en-TH/product/product-detail",
    component: _0e8e2cf9,
    name: "product-product-detail___en-TH"
  }, {
    path: "/th-TH/product/product-detail",
    component: _0e8e2cf9,
    name: "product-product-detail___th-TH"
  }, {
    path: "/id/product/product-detail",
    component: _0e8e2cf9,
    name: "product-product-detail___id"
  }, {
    path: "/zh/product/product-detail",
    component: _0e8e2cf9,
    name: "product-product-detail___zh"
  }, {
    path: "/ms/product/product-detail",
    component: _0e8e2cf9,
    name: "product-product-detail___ms"
  }, {
    path: "/vi/product/product-detail",
    component: _0e8e2cf9,
    name: "product-product-detail___vi"
  }, {
    path: "/fil/product/product-detail",
    component: _0e8e2cf9,
    name: "product-product-detail___fil"
  }, {
    path: "/ko/product/product-detail",
    component: _0e8e2cf9,
    name: "product-product-detail___ko"
  }, {
    path: "/en/recommend/recommend-bought-product",
    component: _18074a1b,
    name: "recommend-recommend-bought-product___en"
  }, {
    path: "/en-TH/recommend/recommend-bought-product",
    component: _18074a1b,
    name: "recommend-recommend-bought-product___en-TH"
  }, {
    path: "/th-TH/recommend/recommend-bought-product",
    component: _18074a1b,
    name: "recommend-recommend-bought-product___th-TH"
  }, {
    path: "/id/recommend/recommend-bought-product",
    component: _18074a1b,
    name: "recommend-recommend-bought-product___id"
  }, {
    path: "/zh/recommend/recommend-bought-product",
    component: _18074a1b,
    name: "recommend-recommend-bought-product___zh"
  }, {
    path: "/ms/recommend/recommend-bought-product",
    component: _18074a1b,
    name: "recommend-recommend-bought-product___ms"
  }, {
    path: "/vi/recommend/recommend-bought-product",
    component: _18074a1b,
    name: "recommend-recommend-bought-product___vi"
  }, {
    path: "/fil/recommend/recommend-bought-product",
    component: _18074a1b,
    name: "recommend-recommend-bought-product___fil"
  }, {
    path: "/ko/recommend/recommend-bought-product",
    component: _18074a1b,
    name: "recommend-recommend-bought-product___ko"
  }, {
    path: "/en/recommend/recommend-new-product",
    component: _084c3b24,
    name: "recommend-recommend-new-product___en"
  }, {
    path: "/en-TH/recommend/recommend-new-product",
    component: _084c3b24,
    name: "recommend-recommend-new-product___en-TH"
  }, {
    path: "/th-TH/recommend/recommend-new-product",
    component: _084c3b24,
    name: "recommend-recommend-new-product___th-TH"
  }, {
    path: "/id/recommend/recommend-new-product",
    component: _084c3b24,
    name: "recommend-recommend-new-product___id"
  }, {
    path: "/zh/recommend/recommend-new-product",
    component: _084c3b24,
    name: "recommend-recommend-new-product___zh"
  }, {
    path: "/ms/recommend/recommend-new-product",
    component: _084c3b24,
    name: "recommend-recommend-new-product___ms"
  }, {
    path: "/vi/recommend/recommend-new-product",
    component: _084c3b24,
    name: "recommend-recommend-new-product___vi"
  }, {
    path: "/fil/recommend/recommend-new-product",
    component: _084c3b24,
    name: "recommend-recommend-new-product___fil"
  }, {
    path: "/ko/recommend/recommend-new-product",
    component: _084c3b24,
    name: "recommend-recommend-new-product___ko"
  }, {
    path: "/en/shop/shop-detail",
    component: _1ba94bd9,
    name: "shop-shop-detail___en"
  }, {
    path: "/en-TH/shop/shop-detail",
    component: _1ba94bd9,
    name: "shop-shop-detail___en-TH"
  }, {
    path: "/th-TH/shop/shop-detail",
    component: _1ba94bd9,
    name: "shop-shop-detail___th-TH"
  }, {
    path: "/id/shop/shop-detail",
    component: _1ba94bd9,
    name: "shop-shop-detail___id"
  }, {
    path: "/zh/shop/shop-detail",
    component: _1ba94bd9,
    name: "shop-shop-detail___zh"
  }, {
    path: "/ms/shop/shop-detail",
    component: _1ba94bd9,
    name: "shop-shop-detail___ms"
  }, {
    path: "/vi/shop/shop-detail",
    component: _1ba94bd9,
    name: "shop-shop-detail___vi"
  }, {
    path: "/fil/shop/shop-detail",
    component: _1ba94bd9,
    name: "shop-shop-detail___fil"
  }, {
    path: "/ko/shop/shop-detail",
    component: _1ba94bd9,
    name: "shop-shop-detail___ko"
  }, {
    path: "/en/shop/shop-list",
    component: _57cedcb4,
    name: "shop-shop-list___en"
  }, {
    path: "/en-TH/shop/shop-list",
    component: _57cedcb4,
    name: "shop-shop-list___en-TH"
  }, {
    path: "/th-TH/shop/shop-list",
    component: _57cedcb4,
    name: "shop-shop-list___th-TH"
  }, {
    path: "/id/shop/shop-list",
    component: _57cedcb4,
    name: "shop-shop-list___id"
  }, {
    path: "/zh/shop/shop-list",
    component: _57cedcb4,
    name: "shop-shop-list___zh"
  }, {
    path: "/ms/shop/shop-list",
    component: _57cedcb4,
    name: "shop-shop-list___ms"
  }, {
    path: "/vi/shop/shop-list",
    component: _57cedcb4,
    name: "shop-shop-list___vi"
  }, {
    path: "/fil/shop/shop-list",
    component: _57cedcb4,
    name: "shop-shop-list___fil"
  }, {
    path: "/ko/shop/shop-list",
    component: _57cedcb4,
    name: "shop-shop-list___ko"
  }, {
    path: "/en/",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/en-TH/",
    component: _2dfb1658,
    name: "index___en-TH"
  }, {
    path: "/th-TH/",
    component: _2dfb1658,
    name: "index___th-TH"
  }, {
    path: "/id/",
    component: _2dfb1658,
    name: "index___id"
  }, {
    path: "/zh/",
    component: _2dfb1658,
    name: "index___zh"
  }, {
    path: "/ms/",
    component: _2dfb1658,
    name: "index___ms"
  }, {
    path: "/vi/",
    component: _2dfb1658,
    name: "index___vi"
  }, {
    path: "/fil/",
    component: _2dfb1658,
    name: "index___fil"
  }, {
    path: "/ko/",
    component: _2dfb1658,
    name: "index___ko"
  }, {
    path: "/en/search/firstCategory/:firstEggCategoryId.html",
    component: _1e609130,
    name: "firstCategoryPage___en"
  }, {
    path: "/en-TH/search/firstCategory/:firstEggCategoryId.html",
    component: _1e609130,
    name: "firstCategoryPage___en-TH"
  }, {
    path: "/th-TH/search/firstCategory/:firstEggCategoryId.html",
    component: _1e609130,
    name: "firstCategoryPage___th-TH"
  }, {
    path: "/id/search/firstCategory/:firstEggCategoryId.html",
    component: _1e609130,
    name: "firstCategoryPage___id"
  }, {
    path: "/zh/search/firstCategory/:firstEggCategoryId.html",
    component: _1e609130,
    name: "firstCategoryPage___zh"
  }, {
    path: "/ms/search/firstCategory/:firstEggCategoryId.html",
    component: _1e609130,
    name: "firstCategoryPage___ms"
  }, {
    path: "/vi/search/firstCategory/:firstEggCategoryId.html",
    component: _1e609130,
    name: "firstCategoryPage___vi"
  }, {
    path: "/fil/search/firstCategory/:firstEggCategoryId.html",
    component: _1e609130,
    name: "firstCategoryPage___fil"
  }, {
    path: "/ko/search/firstCategory/:firstEggCategoryId.html",
    component: _1e609130,
    name: "firstCategoryPage___ko"
  }, {
    path: "/en/search/subCategory/:secondEggCategoryId.html",
    component: _1e609130,
    name: "subCategoryPage___en"
  }, {
    path: "/en-TH/search/subCategory/:secondEggCategoryId.html",
    component: _1e609130,
    name: "subCategoryPage___en-TH"
  }, {
    path: "/th-TH/search/subCategory/:secondEggCategoryId.html",
    component: _1e609130,
    name: "subCategoryPage___th-TH"
  }, {
    path: "/id/search/subCategory/:secondEggCategoryId.html",
    component: _1e609130,
    name: "subCategoryPage___id"
  }, {
    path: "/zh/search/subCategory/:secondEggCategoryId.html",
    component: _1e609130,
    name: "subCategoryPage___zh"
  }, {
    path: "/ms/search/subCategory/:secondEggCategoryId.html",
    component: _1e609130,
    name: "subCategoryPage___ms"
  }, {
    path: "/vi/search/subCategory/:secondEggCategoryId.html",
    component: _1e609130,
    name: "subCategoryPage___vi"
  }, {
    path: "/fil/search/subCategory/:secondEggCategoryId.html",
    component: _1e609130,
    name: "subCategoryPage___fil"
  }, {
    path: "/ko/search/subCategory/:secondEggCategoryId.html",
    component: _1e609130,
    name: "subCategoryPage___ko"
  }, {
    path: "/en/search/fourCategory/:fourCategoryId.html",
    component: _1e609130,
    name: "fastMovingPage___en"
  }, {
    path: "/en-TH/search/fourCategory/:fourCategoryId.html",
    component: _1e609130,
    name: "fastMovingPage___en-TH"
  }, {
    path: "/th-TH/search/fourCategory/:fourCategoryId.html",
    component: _1e609130,
    name: "fastMovingPage___th-TH"
  }, {
    path: "/id/search/fourCategory/:fourCategoryId.html",
    component: _1e609130,
    name: "fastMovingPage___id"
  }, {
    path: "/zh/search/fourCategory/:fourCategoryId.html",
    component: _1e609130,
    name: "fastMovingPage___zh"
  }, {
    path: "/ms/search/fourCategory/:fourCategoryId.html",
    component: _1e609130,
    name: "fastMovingPage___ms"
  }, {
    path: "/vi/search/fourCategory/:fourCategoryId.html",
    component: _1e609130,
    name: "fastMovingPage___vi"
  }, {
    path: "/fil/search/fourCategory/:fourCategoryId.html",
    component: _1e609130,
    name: "fastMovingPage___fil"
  }, {
    path: "/ko/search/fourCategory/:fourCategoryId.html",
    component: _1e609130,
    name: "fastMovingPage___ko"
  }, {
    path: "/en/search/brand/:brandId.html",
    component: _1e609130,
    name: "brandPage___en"
  }, {
    path: "/en-TH/search/brand/:brandId.html",
    component: _1e609130,
    name: "brandPage___en-TH"
  }, {
    path: "/th-TH/search/brand/:brandId.html",
    component: _1e609130,
    name: "brandPage___th-TH"
  }, {
    path: "/id/search/brand/:brandId.html",
    component: _1e609130,
    name: "brandPage___id"
  }, {
    path: "/zh/search/brand/:brandId.html",
    component: _1e609130,
    name: "brandPage___zh"
  }, {
    path: "/ms/search/brand/:brandId.html",
    component: _1e609130,
    name: "brandPage___ms"
  }, {
    path: "/vi/search/brand/:brandId.html",
    component: _1e609130,
    name: "brandPage___vi"
  }, {
    path: "/fil/search/brand/:brandId.html",
    component: _1e609130,
    name: "brandPage___fil"
  }, {
    path: "/ko/search/brand/:brandId.html",
    component: _1e609130,
    name: "brandPage___ko"
  }, {
    path: "/en/search/multiCondition.html",
    component: _1e609130,
    name: "multiConditionSearchPage___en"
  }, {
    path: "/en-TH/search/multiCondition.html",
    component: _1e609130,
    name: "multiConditionSearchPage___en-TH"
  }, {
    path: "/th-TH/search/multiCondition.html",
    component: _1e609130,
    name: "multiConditionSearchPage___th-TH"
  }, {
    path: "/id/search/multiCondition.html",
    component: _1e609130,
    name: "multiConditionSearchPage___id"
  }, {
    path: "/zh/search/multiCondition.html",
    component: _1e609130,
    name: "multiConditionSearchPage___zh"
  }, {
    path: "/ms/search/multiCondition.html",
    component: _1e609130,
    name: "multiConditionSearchPage___ms"
  }, {
    path: "/vi/search/multiCondition.html",
    component: _1e609130,
    name: "multiConditionSearchPage___vi"
  }, {
    path: "/fil/search/multiCondition.html",
    component: _1e609130,
    name: "multiConditionSearchPage___fil"
  }, {
    path: "/ko/search/multiCondition.html",
    component: _1e609130,
    name: "multiConditionSearchPage___ko"
  }, {
    path: "/en/search/select-category/:vehicleModelId.html",
    component: _3b81e796,
    name: "selectCategoryPage___en"
  }, {
    path: "/en-TH/search/select-category/:vehicleModelId.html",
    component: _3b81e796,
    name: "selectCategoryPage___en-TH"
  }, {
    path: "/th-TH/search/select-category/:vehicleModelId.html",
    component: _3b81e796,
    name: "selectCategoryPage___th-TH"
  }, {
    path: "/id/search/select-category/:vehicleModelId.html",
    component: _3b81e796,
    name: "selectCategoryPage___id"
  }, {
    path: "/zh/search/select-category/:vehicleModelId.html",
    component: _3b81e796,
    name: "selectCategoryPage___zh"
  }, {
    path: "/ms/search/select-category/:vehicleModelId.html",
    component: _3b81e796,
    name: "selectCategoryPage___ms"
  }, {
    path: "/vi/search/select-category/:vehicleModelId.html",
    component: _3b81e796,
    name: "selectCategoryPage___vi"
  }, {
    path: "/fil/search/select-category/:vehicleModelId.html",
    component: _3b81e796,
    name: "selectCategoryPage___fil"
  }, {
    path: "/ko/search/select-category/:vehicleModelId.html",
    component: _3b81e796,
    name: "selectCategoryPage___ko"
  }, {
    path: "/en/search/select-category-details/:vehicleModelId/:secondOeCategoryId.html",
    component: _3a07e106,
    name: "selectCategoryDetailPage___en"
  }, {
    path: "/en-TH/search/select-category-details/:vehicleModelId/:secondOeCategoryId.html",
    component: _3a07e106,
    name: "selectCategoryDetailPage___en-TH"
  }, {
    path: "/th-TH/search/select-category-details/:vehicleModelId/:secondOeCategoryId.html",
    component: _3a07e106,
    name: "selectCategoryDetailPage___th-TH"
  }, {
    path: "/id/search/select-category-details/:vehicleModelId/:secondOeCategoryId.html",
    component: _3a07e106,
    name: "selectCategoryDetailPage___id"
  }, {
    path: "/zh/search/select-category-details/:vehicleModelId/:secondOeCategoryId.html",
    component: _3a07e106,
    name: "selectCategoryDetailPage___zh"
  }, {
    path: "/ms/search/select-category-details/:vehicleModelId/:secondOeCategoryId.html",
    component: _3a07e106,
    name: "selectCategoryDetailPage___ms"
  }, {
    path: "/vi/search/select-category-details/:vehicleModelId/:secondOeCategoryId.html",
    component: _3a07e106,
    name: "selectCategoryDetailPage___vi"
  }, {
    path: "/fil/search/select-category-details/:vehicleModelId/:secondOeCategoryId.html",
    component: _3a07e106,
    name: "selectCategoryDetailPage___fil"
  }, {
    path: "/ko/search/select-category-details/:vehicleModelId/:secondOeCategoryId.html",
    component: _3a07e106,
    name: "selectCategoryDetailPage___ko"
  }, {
    path: "/en/shop.html",
    component: _57cedcb4,
    name: "shopListPage___en"
  }, {
    path: "/en-TH/shop.html",
    component: _57cedcb4,
    name: "shopListPage___en-TH"
  }, {
    path: "/th-TH/shop.html",
    component: _57cedcb4,
    name: "shopListPage___th-TH"
  }, {
    path: "/id/shop.html",
    component: _57cedcb4,
    name: "shopListPage___id"
  }, {
    path: "/zh/shop.html",
    component: _57cedcb4,
    name: "shopListPage___zh"
  }, {
    path: "/ms/shop.html",
    component: _57cedcb4,
    name: "shopListPage___ms"
  }, {
    path: "/vi/shop.html",
    component: _57cedcb4,
    name: "shopListPage___vi"
  }, {
    path: "/fil/shop.html",
    component: _57cedcb4,
    name: "shopListPage___fil"
  }, {
    path: "/ko/shop.html",
    component: _57cedcb4,
    name: "shopListPage___ko"
  }, {
    path: "/en/shop/:id.html",
    component: _1ba94bd9,
    name: "shopDetailPage___en"
  }, {
    path: "/en-TH/shop/:id.html",
    component: _1ba94bd9,
    name: "shopDetailPage___en-TH"
  }, {
    path: "/th-TH/shop/:id.html",
    component: _1ba94bd9,
    name: "shopDetailPage___th-TH"
  }, {
    path: "/id/shop/:id.html",
    component: _1ba94bd9,
    name: "shopDetailPage___id"
  }, {
    path: "/zh/shop/:id.html",
    component: _1ba94bd9,
    name: "shopDetailPage___zh"
  }, {
    path: "/ms/shop/:id.html",
    component: _1ba94bd9,
    name: "shopDetailPage___ms"
  }, {
    path: "/vi/shop/:id.html",
    component: _1ba94bd9,
    name: "shopDetailPage___vi"
  }, {
    path: "/fil/shop/:id.html",
    component: _1ba94bd9,
    name: "shopDetailPage___fil"
  }, {
    path: "/ko/shop/:id.html",
    component: _1ba94bd9,
    name: "shopDetailPage___ko"
  }, {
    path: "/en/recommend/recommend-bought-product.html",
    component: _18074a1b,
    name: "recommendBoughtProduct___en"
  }, {
    path: "/en-TH/recommend/recommend-bought-product.html",
    component: _18074a1b,
    name: "recommendBoughtProduct___en-TH"
  }, {
    path: "/th-TH/recommend/recommend-bought-product.html",
    component: _18074a1b,
    name: "recommendBoughtProduct___th-TH"
  }, {
    path: "/id/recommend/recommend-bought-product.html",
    component: _18074a1b,
    name: "recommendBoughtProduct___id"
  }, {
    path: "/zh/recommend/recommend-bought-product.html",
    component: _18074a1b,
    name: "recommendBoughtProduct___zh"
  }, {
    path: "/ms/recommend/recommend-bought-product.html",
    component: _18074a1b,
    name: "recommendBoughtProduct___ms"
  }, {
    path: "/vi/recommend/recommend-bought-product.html",
    component: _18074a1b,
    name: "recommendBoughtProduct___vi"
  }, {
    path: "/fil/recommend/recommend-bought-product.html",
    component: _18074a1b,
    name: "recommendBoughtProduct___fil"
  }, {
    path: "/ko/recommend/recommend-bought-product.html",
    component: _18074a1b,
    name: "recommendBoughtProduct___ko"
  }, {
    path: "/en/recommend/recommend-new-product.html",
    component: _084c3b24,
    name: "recommendNewProduct___en"
  }, {
    path: "/en-TH/recommend/recommend-new-product.html",
    component: _084c3b24,
    name: "recommendNewProduct___en-TH"
  }, {
    path: "/th-TH/recommend/recommend-new-product.html",
    component: _084c3b24,
    name: "recommendNewProduct___th-TH"
  }, {
    path: "/id/recommend/recommend-new-product.html",
    component: _084c3b24,
    name: "recommendNewProduct___id"
  }, {
    path: "/zh/recommend/recommend-new-product.html",
    component: _084c3b24,
    name: "recommendNewProduct___zh"
  }, {
    path: "/ms/recommend/recommend-new-product.html",
    component: _084c3b24,
    name: "recommendNewProduct___ms"
  }, {
    path: "/vi/recommend/recommend-new-product.html",
    component: _084c3b24,
    name: "recommendNewProduct___vi"
  }, {
    path: "/fil/recommend/recommend-new-product.html",
    component: _084c3b24,
    name: "recommendNewProduct___fil"
  }, {
    path: "/ko/recommend/recommend-new-product.html",
    component: _084c3b24,
    name: "recommendNewProduct___ko"
  }, {
    path: "/en/products/details/:id.html",
    component: _0e8e2cf9,
    name: "productDetailPage___en"
  }, {
    path: "/en-TH/products/details/:id.html",
    component: _0e8e2cf9,
    name: "productDetailPage___en-TH"
  }, {
    path: "/th-TH/products/details/:id.html",
    component: _0e8e2cf9,
    name: "productDetailPage___th-TH"
  }, {
    path: "/id/products/details/:id.html",
    component: _0e8e2cf9,
    name: "productDetailPage___id"
  }, {
    path: "/zh/products/details/:id.html",
    component: _0e8e2cf9,
    name: "productDetailPage___zh"
  }, {
    path: "/ms/products/details/:id.html",
    component: _0e8e2cf9,
    name: "productDetailPage___ms"
  }, {
    path: "/vi/products/details/:id.html",
    component: _0e8e2cf9,
    name: "productDetailPage___vi"
  }, {
    path: "/fil/products/details/:id.html",
    component: _0e8e2cf9,
    name: "productDetailPage___fil"
  }, {
    path: "/ko/products/details/:id.html",
    component: _0e8e2cf9,
    name: "productDetailPage___ko"
  }, {
    path: "/en/order/shopCart.html",
    component: _172bf5d0,
    name: "orderProcedurePage___en"
  }, {
    path: "/en-TH/order/shopCart.html",
    component: _172bf5d0,
    name: "orderProcedurePage___en-TH"
  }, {
    path: "/th-TH/order/shopCart.html",
    component: _172bf5d0,
    name: "orderProcedurePage___th-TH"
  }, {
    path: "/id/order/shopCart.html",
    component: _172bf5d0,
    name: "orderProcedurePage___id"
  }, {
    path: "/zh/order/shopCart.html",
    component: _172bf5d0,
    name: "orderProcedurePage___zh"
  }, {
    path: "/ms/order/shopCart.html",
    component: _172bf5d0,
    name: "orderProcedurePage___ms"
  }, {
    path: "/vi/order/shopCart.html",
    component: _172bf5d0,
    name: "orderProcedurePage___vi"
  }, {
    path: "/fil/order/shopCart.html",
    component: _172bf5d0,
    name: "orderProcedurePage___fil"
  }, {
    path: "/ko/order/shopCart.html",
    component: _172bf5d0,
    name: "orderProcedurePage___ko"
  }, {
    path: "/en/order/orderDetail.html",
    component: _1fd1e791,
    name: "orderDetail___en"
  }, {
    path: "/en-TH/order/orderDetail.html",
    component: _1fd1e791,
    name: "orderDetail___en-TH"
  }, {
    path: "/th-TH/order/orderDetail.html",
    component: _1fd1e791,
    name: "orderDetail___th-TH"
  }, {
    path: "/id/order/orderDetail.html",
    component: _1fd1e791,
    name: "orderDetail___id"
  }, {
    path: "/zh/order/orderDetail.html",
    component: _1fd1e791,
    name: "orderDetail___zh"
  }, {
    path: "/ms/order/orderDetail.html",
    component: _1fd1e791,
    name: "orderDetail___ms"
  }, {
    path: "/vi/order/orderDetail.html",
    component: _1fd1e791,
    name: "orderDetail___vi"
  }, {
    path: "/fil/order/orderDetail.html",
    component: _1fd1e791,
    name: "orderDetail___fil"
  }, {
    path: "/ko/order/orderDetail.html",
    component: _1fd1e791,
    name: "orderDetail___ko"
  }, {
    path: "/en/order/result.html",
    component: _4ab128f2,
    name: "orderResultPage___en"
  }, {
    path: "/en-TH/order/result.html",
    component: _4ab128f2,
    name: "orderResultPage___en-TH"
  }, {
    path: "/th-TH/order/result.html",
    component: _4ab128f2,
    name: "orderResultPage___th-TH"
  }, {
    path: "/id/order/result.html",
    component: _4ab128f2,
    name: "orderResultPage___id"
  }, {
    path: "/zh/order/result.html",
    component: _4ab128f2,
    name: "orderResultPage___zh"
  }, {
    path: "/ms/order/result.html",
    component: _4ab128f2,
    name: "orderResultPage___ms"
  }, {
    path: "/vi/order/result.html",
    component: _4ab128f2,
    name: "orderResultPage___vi"
  }, {
    path: "/fil/order/result.html",
    component: _4ab128f2,
    name: "orderResultPage___fil"
  }, {
    path: "/ko/order/result.html",
    component: _4ab128f2,
    name: "orderResultPage___ko"
  }, {
    path: "/en/order/coupon.html",
    component: _21134f10,
    name: "orderCouponPage___en"
  }, {
    path: "/en-TH/order/coupon.html",
    component: _21134f10,
    name: "orderCouponPage___en-TH"
  }, {
    path: "/th-TH/order/coupon.html",
    component: _21134f10,
    name: "orderCouponPage___th-TH"
  }, {
    path: "/id/order/coupon.html",
    component: _21134f10,
    name: "orderCouponPage___id"
  }, {
    path: "/zh/order/coupon.html",
    component: _21134f10,
    name: "orderCouponPage___zh"
  }, {
    path: "/ms/order/coupon.html",
    component: _21134f10,
    name: "orderCouponPage___ms"
  }, {
    path: "/vi/order/coupon.html",
    component: _21134f10,
    name: "orderCouponPage___vi"
  }, {
    path: "/fil/order/coupon.html",
    component: _21134f10,
    name: "orderCouponPage___fil"
  }, {
    path: "/ko/order/coupon.html",
    component: _21134f10,
    name: "orderCouponPage___ko"
  }, {
    path: "/en/order/payment.html",
    component: _4119148c,
    name: "orderPaymentPage___en"
  }, {
    path: "/en-TH/order/payment.html",
    component: _4119148c,
    name: "orderPaymentPage___en-TH"
  }, {
    path: "/th-TH/order/payment.html",
    component: _4119148c,
    name: "orderPaymentPage___th-TH"
  }, {
    path: "/id/order/payment.html",
    component: _4119148c,
    name: "orderPaymentPage___id"
  }, {
    path: "/zh/order/payment.html",
    component: _4119148c,
    name: "orderPaymentPage___zh"
  }, {
    path: "/ms/order/payment.html",
    component: _4119148c,
    name: "orderPaymentPage___ms"
  }, {
    path: "/vi/order/payment.html",
    component: _4119148c,
    name: "orderPaymentPage___vi"
  }, {
    path: "/fil/order/payment.html",
    component: _4119148c,
    name: "orderPaymentPage___fil"
  }, {
    path: "/ko/order/payment.html",
    component: _4119148c,
    name: "orderPaymentPage___ko"
  }, {
    path: "/en/order/list.html",
    component: _5c1fd808,
    name: "orderListPage___en"
  }, {
    path: "/en-TH/order/list.html",
    component: _5c1fd808,
    name: "orderListPage___en-TH"
  }, {
    path: "/th-TH/order/list.html",
    component: _5c1fd808,
    name: "orderListPage___th-TH"
  }, {
    path: "/id/order/list.html",
    component: _5c1fd808,
    name: "orderListPage___id"
  }, {
    path: "/zh/order/list.html",
    component: _5c1fd808,
    name: "orderListPage___zh"
  }, {
    path: "/ms/order/list.html",
    component: _5c1fd808,
    name: "orderListPage___ms"
  }, {
    path: "/vi/order/list.html",
    component: _5c1fd808,
    name: "orderListPage___vi"
  }, {
    path: "/fil/order/list.html",
    component: _5c1fd808,
    name: "orderListPage___fil"
  }, {
    path: "/ko/order/list.html",
    component: _5c1fd808,
    name: "orderListPage___ko"
  }, {
    path: "/en/order/detail/:id.html",
    component: _820efc8a,
    name: "orderDetailPage___en"
  }, {
    path: "/en-TH/order/detail/:id.html",
    component: _820efc8a,
    name: "orderDetailPage___en-TH"
  }, {
    path: "/th-TH/order/detail/:id.html",
    component: _820efc8a,
    name: "orderDetailPage___th-TH"
  }, {
    path: "/id/order/detail/:id.html",
    component: _820efc8a,
    name: "orderDetailPage___id"
  }, {
    path: "/zh/order/detail/:id.html",
    component: _820efc8a,
    name: "orderDetailPage___zh"
  }, {
    path: "/ms/order/detail/:id.html",
    component: _820efc8a,
    name: "orderDetailPage___ms"
  }, {
    path: "/vi/order/detail/:id.html",
    component: _820efc8a,
    name: "orderDetailPage___vi"
  }, {
    path: "/fil/order/detail/:id.html",
    component: _820efc8a,
    name: "orderDetailPage___fil"
  }, {
    path: "/ko/order/detail/:id.html",
    component: _820efc8a,
    name: "orderDetailPage___ko"
  }, {
    path: "/en/credit/list.html",
    component: _4b668f74,
    name: "creditListPage___en"
  }, {
    path: "/en-TH/credit/list.html",
    component: _4b668f74,
    name: "creditListPage___en-TH"
  }, {
    path: "/th-TH/credit/list.html",
    component: _4b668f74,
    name: "creditListPage___th-TH"
  }, {
    path: "/id/credit/list.html",
    component: _4b668f74,
    name: "creditListPage___id"
  }, {
    path: "/zh/credit/list.html",
    component: _4b668f74,
    name: "creditListPage___zh"
  }, {
    path: "/ms/credit/list.html",
    component: _4b668f74,
    name: "creditListPage___ms"
  }, {
    path: "/vi/credit/list.html",
    component: _4b668f74,
    name: "creditListPage___vi"
  }, {
    path: "/fil/credit/list.html",
    component: _4b668f74,
    name: "creditListPage___fil"
  }, {
    path: "/ko/credit/list.html",
    component: _4b668f74,
    name: "creditListPage___ko"
  }, {
    path: "/en/credit/details/:shopId.html",
    component: _4c06a5ca,
    name: "creditDetailPage___en"
  }, {
    path: "/en-TH/credit/details/:shopId.html",
    component: _4c06a5ca,
    name: "creditDetailPage___en-TH"
  }, {
    path: "/th-TH/credit/details/:shopId.html",
    component: _4c06a5ca,
    name: "creditDetailPage___th-TH"
  }, {
    path: "/id/credit/details/:shopId.html",
    component: _4c06a5ca,
    name: "creditDetailPage___id"
  }, {
    path: "/zh/credit/details/:shopId.html",
    component: _4c06a5ca,
    name: "creditDetailPage___zh"
  }, {
    path: "/ms/credit/details/:shopId.html",
    component: _4c06a5ca,
    name: "creditDetailPage___ms"
  }, {
    path: "/vi/credit/details/:shopId.html",
    component: _4c06a5ca,
    name: "creditDetailPage___vi"
  }, {
    path: "/fil/credit/details/:shopId.html",
    component: _4c06a5ca,
    name: "creditDetailPage___fil"
  }, {
    path: "/ko/credit/details/:shopId.html",
    component: _4c06a5ca,
    name: "creditDetailPage___ko"
  }, {
    path: "/en/credit/history/:shopId.html",
    component: _4bafc1c8,
    name: "creditHistoryPage___en"
  }, {
    path: "/en-TH/credit/history/:shopId.html",
    component: _4bafc1c8,
    name: "creditHistoryPage___en-TH"
  }, {
    path: "/th-TH/credit/history/:shopId.html",
    component: _4bafc1c8,
    name: "creditHistoryPage___th-TH"
  }, {
    path: "/id/credit/history/:shopId.html",
    component: _4bafc1c8,
    name: "creditHistoryPage___id"
  }, {
    path: "/zh/credit/history/:shopId.html",
    component: _4bafc1c8,
    name: "creditHistoryPage___zh"
  }, {
    path: "/ms/credit/history/:shopId.html",
    component: _4bafc1c8,
    name: "creditHistoryPage___ms"
  }, {
    path: "/vi/credit/history/:shopId.html",
    component: _4bafc1c8,
    name: "creditHistoryPage___vi"
  }, {
    path: "/fil/credit/history/:shopId.html",
    component: _4bafc1c8,
    name: "creditHistoryPage___fil"
  }, {
    path: "/ko/credit/history/:shopId.html",
    component: _4bafc1c8,
    name: "creditHistoryPage___ko"
  }, {
    path: "/en/myFavoriteList.html",
    component: _a9b45a90,
    name: "myFavoriteListPage___en"
  }, {
    path: "/en-TH/myFavoriteList.html",
    component: _a9b45a90,
    name: "myFavoriteListPage___en-TH"
  }, {
    path: "/th-TH/myFavoriteList.html",
    component: _a9b45a90,
    name: "myFavoriteListPage___th-TH"
  }, {
    path: "/id/myFavoriteList.html",
    component: _a9b45a90,
    name: "myFavoriteListPage___id"
  }, {
    path: "/zh/myFavoriteList.html",
    component: _a9b45a90,
    name: "myFavoriteListPage___zh"
  }, {
    path: "/ms/myFavoriteList.html",
    component: _a9b45a90,
    name: "myFavoriteListPage___ms"
  }, {
    path: "/vi/myFavoriteList.html",
    component: _a9b45a90,
    name: "myFavoriteListPage___vi"
  }, {
    path: "/fil/myFavoriteList.html",
    component: _a9b45a90,
    name: "myFavoriteListPage___fil"
  }, {
    path: "/ko/myFavoriteList.html",
    component: _a9b45a90,
    name: "myFavoriteListPage___ko"
  }, {
    path: "/en/bannerPromotion.html",
    component: _54d0842c,
    name: "bannerPromotionPage___en"
  }, {
    path: "/en-TH/bannerPromotion.html",
    component: _54d0842c,
    name: "bannerPromotionPage___en-TH"
  }, {
    path: "/th-TH/bannerPromotion.html",
    component: _54d0842c,
    name: "bannerPromotionPage___th-TH"
  }, {
    path: "/id/bannerPromotion.html",
    component: _54d0842c,
    name: "bannerPromotionPage___id"
  }, {
    path: "/zh/bannerPromotion.html",
    component: _54d0842c,
    name: "bannerPromotionPage___zh"
  }, {
    path: "/ms/bannerPromotion.html",
    component: _54d0842c,
    name: "bannerPromotionPage___ms"
  }, {
    path: "/vi/bannerPromotion.html",
    component: _54d0842c,
    name: "bannerPromotionPage___vi"
  }, {
    path: "/fil/bannerPromotion.html",
    component: _54d0842c,
    name: "bannerPromotionPage___fil"
  }, {
    path: "/ko/bannerPromotion.html",
    component: _54d0842c,
    name: "bannerPromotionPage___ko"
  }, {
    path: "/en/aboutUs.html",
    component: _e9b98470,
    name: "aboutUsPage___en"
  }, {
    path: "/en-TH/aboutUs.html",
    component: _e9b98470,
    name: "aboutUsPage___en-TH"
  }, {
    path: "/th-TH/aboutUs.html",
    component: _e9b98470,
    name: "aboutUsPage___th-TH"
  }, {
    path: "/id/aboutUs.html",
    component: _e9b98470,
    name: "aboutUsPage___id"
  }, {
    path: "/zh/aboutUs.html",
    component: _e9b98470,
    name: "aboutUsPage___zh"
  }, {
    path: "/ms/aboutUs.html",
    component: _e9b98470,
    name: "aboutUsPage___ms"
  }, {
    path: "/vi/aboutUs.html",
    component: _e9b98470,
    name: "aboutUsPage___vi"
  }, {
    path: "/fil/aboutUs.html",
    component: _e9b98470,
    name: "aboutUsPage___fil"
  }, {
    path: "/ko/aboutUs.html",
    component: _e9b98470,
    name: "aboutUsPage___ko"
  }, {
    path: "/en/privacyPolicy.html",
    component: _2b8f161a,
    name: "privacyPolicy___en"
  }, {
    path: "/en-TH/privacyPolicy.html",
    component: _2b8f161a,
    name: "privacyPolicy___en-TH"
  }, {
    path: "/th-TH/privacyPolicy.html",
    component: _2b8f161a,
    name: "privacyPolicy___th-TH"
  }, {
    path: "/id/privacyPolicy.html",
    component: _2b8f161a,
    name: "privacyPolicy___id"
  }, {
    path: "/zh/privacyPolicy.html",
    component: _2b8f161a,
    name: "privacyPolicy___zh"
  }, {
    path: "/ms/privacyPolicy.html",
    component: _2b8f161a,
    name: "privacyPolicy___ms"
  }, {
    path: "/vi/privacyPolicy.html",
    component: _2b8f161a,
    name: "privacyPolicy___vi"
  }, {
    path: "/fil/privacyPolicy.html",
    component: _2b8f161a,
    name: "privacyPolicy___fil"
  }, {
    path: "/ko/privacyPolicy.html",
    component: _2b8f161a,
    name: "privacyPolicy___ko"
  }, {
    path: "/en/termsAndConditions.html",
    component: _9397dc92,
    name: "termsAndConditions___en"
  }, {
    path: "/en-TH/termsAndConditions.html",
    component: _9397dc92,
    name: "termsAndConditions___en-TH"
  }, {
    path: "/th-TH/termsAndConditions.html",
    component: _9397dc92,
    name: "termsAndConditions___th-TH"
  }, {
    path: "/id/termsAndConditions.html",
    component: _9397dc92,
    name: "termsAndConditions___id"
  }, {
    path: "/zh/termsAndConditions.html",
    component: _9397dc92,
    name: "termsAndConditions___zh"
  }, {
    path: "/ms/termsAndConditions.html",
    component: _9397dc92,
    name: "termsAndConditions___ms"
  }, {
    path: "/vi/termsAndConditions.html",
    component: _9397dc92,
    name: "termsAndConditions___vi"
  }, {
    path: "/fil/termsAndConditions.html",
    component: _9397dc92,
    name: "termsAndConditions___fil"
  }, {
    path: "/ko/termsAndConditions.html",
    component: _9397dc92,
    name: "termsAndConditions___ko"
  }, {
    path: "/en/shippingPolicy.html",
    component: _5ac94484,
    name: "shippingPolicy___en"
  }, {
    path: "/en-TH/shippingPolicy.html",
    component: _5ac94484,
    name: "shippingPolicy___en-TH"
  }, {
    path: "/th-TH/shippingPolicy.html",
    component: _5ac94484,
    name: "shippingPolicy___th-TH"
  }, {
    path: "/id/shippingPolicy.html",
    component: _5ac94484,
    name: "shippingPolicy___id"
  }, {
    path: "/zh/shippingPolicy.html",
    component: _5ac94484,
    name: "shippingPolicy___zh"
  }, {
    path: "/ms/shippingPolicy.html",
    component: _5ac94484,
    name: "shippingPolicy___ms"
  }, {
    path: "/vi/shippingPolicy.html",
    component: _5ac94484,
    name: "shippingPolicy___vi"
  }, {
    path: "/fil/shippingPolicy.html",
    component: _5ac94484,
    name: "shippingPolicy___fil"
  }, {
    path: "/ko/shippingPolicy.html",
    component: _5ac94484,
    name: "shippingPolicy___ko"
  }, {
    path: "/en/warrantyPolicy.html",
    component: _ed2484a0,
    name: "warrantyPolicy___en"
  }, {
    path: "/en-TH/warrantyPolicy.html",
    component: _ed2484a0,
    name: "warrantyPolicy___en-TH"
  }, {
    path: "/th-TH/warrantyPolicy.html",
    component: _ed2484a0,
    name: "warrantyPolicy___th-TH"
  }, {
    path: "/id/warrantyPolicy.html",
    component: _ed2484a0,
    name: "warrantyPolicy___id"
  }, {
    path: "/zh/warrantyPolicy.html",
    component: _ed2484a0,
    name: "warrantyPolicy___zh"
  }, {
    path: "/ms/warrantyPolicy.html",
    component: _ed2484a0,
    name: "warrantyPolicy___ms"
  }, {
    path: "/vi/warrantyPolicy.html",
    component: _ed2484a0,
    name: "warrantyPolicy___vi"
  }, {
    path: "/fil/warrantyPolicy.html",
    component: _ed2484a0,
    name: "warrantyPolicy___fil"
  }, {
    path: "/ko/warrantyPolicy.html",
    component: _ed2484a0,
    name: "warrantyPolicy___ko"
  }, {
    path: "/en/returnPolicy.html",
    component: _306e723c,
    name: "returnPolicy___en"
  }, {
    path: "/en-TH/returnPolicy.html",
    component: _306e723c,
    name: "returnPolicy___en-TH"
  }, {
    path: "/th-TH/returnPolicy.html",
    component: _306e723c,
    name: "returnPolicy___th-TH"
  }, {
    path: "/id/returnPolicy.html",
    component: _306e723c,
    name: "returnPolicy___id"
  }, {
    path: "/zh/returnPolicy.html",
    component: _306e723c,
    name: "returnPolicy___zh"
  }, {
    path: "/ms/returnPolicy.html",
    component: _306e723c,
    name: "returnPolicy___ms"
  }, {
    path: "/vi/returnPolicy.html",
    component: _306e723c,
    name: "returnPolicy___vi"
  }, {
    path: "/fil/returnPolicy.html",
    component: _306e723c,
    name: "returnPolicy___fil"
  }, {
    path: "/ko/returnPolicy.html",
    component: _306e723c,
    name: "returnPolicy___ko"
  }, {
    path: "/en/pointsPolicy.html",
    component: _58cd94a9,
    name: "pointsPolicy___en"
  }, {
    path: "/en-TH/pointsPolicy.html",
    component: _58cd94a9,
    name: "pointsPolicy___en-TH"
  }, {
    path: "/th-TH/pointsPolicy.html",
    component: _58cd94a9,
    name: "pointsPolicy___th-TH"
  }, {
    path: "/id/pointsPolicy.html",
    component: _58cd94a9,
    name: "pointsPolicy___id"
  }, {
    path: "/zh/pointsPolicy.html",
    component: _58cd94a9,
    name: "pointsPolicy___zh"
  }, {
    path: "/ms/pointsPolicy.html",
    component: _58cd94a9,
    name: "pointsPolicy___ms"
  }, {
    path: "/vi/pointsPolicy.html",
    component: _58cd94a9,
    name: "pointsPolicy___vi"
  }, {
    path: "/fil/pointsPolicy.html",
    component: _58cd94a9,
    name: "pointsPolicy___fil"
  }, {
    path: "/ko/pointsPolicy.html",
    component: _58cd94a9,
    name: "pointsPolicy___ko"
  }, {
    path: "/en/newPromotion.html",
    component: _0d84e388,
    name: "newPromotion___en"
  }, {
    path: "/en-TH/newPromotion.html",
    component: _0d84e388,
    name: "newPromotion___en-TH"
  }, {
    path: "/th-TH/newPromotion.html",
    component: _0d84e388,
    name: "newPromotion___th-TH"
  }, {
    path: "/id/newPromotion.html",
    component: _0d84e388,
    name: "newPromotion___id"
  }, {
    path: "/zh/newPromotion.html",
    component: _0d84e388,
    name: "newPromotion___zh"
  }, {
    path: "/ms/newPromotion.html",
    component: _0d84e388,
    name: "newPromotion___ms"
  }, {
    path: "/vi/newPromotion.html",
    component: _0d84e388,
    name: "newPromotion___vi"
  }, {
    path: "/fil/newPromotion.html",
    component: _0d84e388,
    name: "newPromotion___fil"
  }, {
    path: "/ko/newPromotion.html",
    component: _0d84e388,
    name: "newPromotion___ko"
  }, {
    path: "/en/knowledgeCenter/article/:id.html",
    component: _108ea482,
    name: "ArticleDetails___en"
  }, {
    path: "/en-TH/knowledgeCenter/article/:id.html",
    component: _108ea482,
    name: "ArticleDetails___en-TH"
  }, {
    path: "/th-TH/knowledgeCenter/article/:id.html",
    component: _108ea482,
    name: "ArticleDetails___th-TH"
  }, {
    path: "/id/knowledgeCenter/article/:id.html",
    component: _108ea482,
    name: "ArticleDetails___id"
  }, {
    path: "/zh/knowledgeCenter/article/:id.html",
    component: _108ea482,
    name: "ArticleDetails___zh"
  }, {
    path: "/ms/knowledgeCenter/article/:id.html",
    component: _108ea482,
    name: "ArticleDetails___ms"
  }, {
    path: "/vi/knowledgeCenter/article/:id.html",
    component: _108ea482,
    name: "ArticleDetails___vi"
  }, {
    path: "/fil/knowledgeCenter/article/:id.html",
    component: _108ea482,
    name: "ArticleDetails___fil"
  }, {
    path: "/ko/knowledgeCenter/article/:id.html",
    component: _108ea482,
    name: "ArticleDetails___ko"
  }, {
    path: "/en/pointsPolicyDetail.html",
    component: _2c787f25,
    name: "pointsPolicyDetail___en"
  }, {
    path: "/en-TH/pointsPolicyDetail.html",
    component: _2c787f25,
    name: "pointsPolicyDetail___en-TH"
  }, {
    path: "/th-TH/pointsPolicyDetail.html",
    component: _2c787f25,
    name: "pointsPolicyDetail___th-TH"
  }, {
    path: "/id/pointsPolicyDetail.html",
    component: _2c787f25,
    name: "pointsPolicyDetail___id"
  }, {
    path: "/zh/pointsPolicyDetail.html",
    component: _2c787f25,
    name: "pointsPolicyDetail___zh"
  }, {
    path: "/ms/pointsPolicyDetail.html",
    component: _2c787f25,
    name: "pointsPolicyDetail___ms"
  }, {
    path: "/vi/pointsPolicyDetail.html",
    component: _2c787f25,
    name: "pointsPolicyDetail___vi"
  }, {
    path: "/fil/pointsPolicyDetail.html",
    component: _2c787f25,
    name: "pointsPolicyDetail___fil"
  }, {
    path: "/ko/pointsPolicyDetail.html",
    component: _2c787f25,
    name: "pointsPolicyDetail___ko"
  }, {
    path: "/en/ConsentToCollectionAndUseOfPersonalInformation.html",
    component: _0666f0b6,
    name: "ConsentToCollectionAndUseOfPersonalInformation___en"
  }, {
    path: "/en-TH/ConsentToCollectionAndUseOfPersonalInformation.html",
    component: _0666f0b6,
    name: "ConsentToCollectionAndUseOfPersonalInformation___en-TH"
  }, {
    path: "/th-TH/ConsentToCollectionAndUseOfPersonalInformation.html",
    component: _0666f0b6,
    name: "ConsentToCollectionAndUseOfPersonalInformation___th-TH"
  }, {
    path: "/id/ConsentToCollectionAndUseOfPersonalInformation.html",
    component: _0666f0b6,
    name: "ConsentToCollectionAndUseOfPersonalInformation___id"
  }, {
    path: "/zh/ConsentToCollectionAndUseOfPersonalInformation.html",
    component: _0666f0b6,
    name: "ConsentToCollectionAndUseOfPersonalInformation___zh"
  }, {
    path: "/ms/ConsentToCollectionAndUseOfPersonalInformation.html",
    component: _0666f0b6,
    name: "ConsentToCollectionAndUseOfPersonalInformation___ms"
  }, {
    path: "/vi/ConsentToCollectionAndUseOfPersonalInformation.html",
    component: _0666f0b6,
    name: "ConsentToCollectionAndUseOfPersonalInformation___vi"
  }, {
    path: "/fil/ConsentToCollectionAndUseOfPersonalInformation.html",
    component: _0666f0b6,
    name: "ConsentToCollectionAndUseOfPersonalInformation___fil"
  }, {
    path: "/ko/ConsentToCollectionAndUseOfPersonalInformation.html",
    component: _0666f0b6,
    name: "ConsentToCollectionAndUseOfPersonalInformation___ko"
  }, {
    path: "/en/ConsentToOverseasAndUseOfPersonalInformation.html",
    component: _53c221ac,
    name: "ConsentToOverseasAndUseOfPersonalInformation___en"
  }, {
    path: "/en-TH/ConsentToOverseasAndUseOfPersonalInformation.html",
    component: _53c221ac,
    name: "ConsentToOverseasAndUseOfPersonalInformation___en-TH"
  }, {
    path: "/th-TH/ConsentToOverseasAndUseOfPersonalInformation.html",
    component: _53c221ac,
    name: "ConsentToOverseasAndUseOfPersonalInformation___th-TH"
  }, {
    path: "/id/ConsentToOverseasAndUseOfPersonalInformation.html",
    component: _53c221ac,
    name: "ConsentToOverseasAndUseOfPersonalInformation___id"
  }, {
    path: "/zh/ConsentToOverseasAndUseOfPersonalInformation.html",
    component: _53c221ac,
    name: "ConsentToOverseasAndUseOfPersonalInformation___zh"
  }, {
    path: "/ms/ConsentToOverseasAndUseOfPersonalInformation.html",
    component: _53c221ac,
    name: "ConsentToOverseasAndUseOfPersonalInformation___ms"
  }, {
    path: "/vi/ConsentToOverseasAndUseOfPersonalInformation.html",
    component: _53c221ac,
    name: "ConsentToOverseasAndUseOfPersonalInformation___vi"
  }, {
    path: "/fil/ConsentToOverseasAndUseOfPersonalInformation.html",
    component: _53c221ac,
    name: "ConsentToOverseasAndUseOfPersonalInformation___fil"
  }, {
    path: "/ko/ConsentToOverseasAndUseOfPersonalInformation.html",
    component: _53c221ac,
    name: "ConsentToOverseasAndUseOfPersonalInformation___ko"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
