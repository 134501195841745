// 目前只用来处理GET请求带多个同名参数的场景
import $config from '~/config'
import common from '../utils/common'

export default function ({ $axios, redirect }) {
    $axios.onRequest(config => {
        if ((<any>process).server) {
            config.baseURL = $config.eggmallWebService[process.env.CUSTOM_ENV].url
        } else {
            config.baseURL = '/platform'
            config.headers.common.businessPlatform = 'EGG_MALL_WEB'
            config.headers.common['cache-control'] = 'no-cache'
            config.headers.common.langCode = window.localStorage.getItem('langCode') || 'th-TH'
            let token = window.localStorage.getItem('token') || ''
            if (token) {
                config.headers.common.token = token
            }

            let countryInfo = common.getCountryInfo()
            config.headers.common.countryCode = countryInfo.countryCode
            config.headers.common.countryId = countryInfo.countryId

            if (window.localStorage.getItem('priceItem')) {
                let priceItem = window.localStorage.getItem('priceItem') || '{}'
                config.headers.common.priceId = JSON.parse(priceItem).priceId
            }
        }
    })
    $axios.onResponse(response => {
        // console.log(response)
    })
    $axios.onError(error => {
        // console.log(error)
    })
}
