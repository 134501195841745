// import Cookie from 'js-cookie'

export default function ({
    route, req, res, redirect, error, store,
}) {
    if (process.server) {
        // store.commit('SET_COOKIE', req.headers.cookie)
    }
    // // 获取服务端cookie
    // function getcookiesInServer (reqest) {
    //     let serviceCookie = {}
    //     reqest && reqest.headers.cookie && reqest.headers.cookie.split(';')
    //         .forEach(val => {
    //             let parts = val.split('=')
    //             serviceCookie[parts[0].trim()] = (parts[1] || '').trim()
    //         })
    //     return serviceCookie
    // }
    //
    // // 获取客户端cookie
    // function getcookiesInClient (key) {
    //     return Cookie.get(key) ? Cookie.get(key) : ''
    // }
    //
    // let isClient = process.client
    // let isServer = process.server
    // let redirectURL = '/login'
    // let authUser
    // let path
    //
    // // 在服务端
    // if (isServer) {
    //     let cookies = getcookiesInServer(req)
    //     path = req.originalUrl
    //     authUser = cookies.authUser ? cookies.authUser : ''
    //     store.commit('SET_TOKEN', cookies.CHAT_AUTH_TOKEN)
    // }
    //
    // // 在客户端判读是否需要登陆
    // if (isClient) {
    //     authUser = getcookiesInClient('CHAT_AUTH_TOKEN')
    //     path = route.path
    //     console.log('authUser------', authUser)
    //     console.log('path------', path)
    //     store.commit('SET_TOKEN', authUser)
    // }
    //
    // if (path) {
    //     // redirectURL = '/login'
    // }
    //
    // // 需要进行权限判断的页面开头
    // if (!authUser) {
    //     // redirect(redirectURL)
    // }
}
