























































































import {
    Vue, Component, Watch,
} from 'vue-property-decorator'
import {State} from 'vuex-class'
import api from '~/api'
import PrivacyPolicy from './policy/privacy-policy.vue'
import WarrantyPolicy from './policy/warranty-policy.vue'
import ReturnPolicy from './policy/return-policy.vue'
import ShippingPolicy from './policy/shipping-policy.vue'
import TermsAndConditions from './policy/terms-and-conditions.vue'

@Component({
    components: {
        PrivacyPolicy,
        WarrantyPolicy,
        ReturnPolicy,
        ShippingPolicy,
        TermsAndConditions,
    },
})
export default class ProtocolBox extends Vue {
@State('protocolModalVisible') protocolModalVisible

@State('protocolListNet') protocolListNet

@State('hasUpdatedProtocol') hasUpdatedProtocol

selectedProtocolTermsType: string = ''

selectedProtocolTypeId: number | string = ''

@Watch('protocolModalVisible')
onProtocolModalVisibleChange(newVal) {
    if (newVal) {
        this.$nextTick(() => {
            this.openDlg()
        })
    } else {
        this.closeDlg()
    }
}

closeDlg() {
    this.$store.commit('SET_PROTOCOL_MODAL', false)
    this.$store.commit('SET_PROTOCOL_UPDATED_FLAG', false)
}

openDlg() {
    this.selectedProtocolTermsType = this.$store.state.selectedProtocolTermsType + ''
    this.selectedProtocolTypeId = this.protocolListNet.reduce((arr, cur) => arr.concat(cur.termsConditionList), []).filter(item => +item.termsType === +this.selectedProtocolTermsType)[0].pid
}

operateAction(isAgree) {
    api.operate_agreement({
        isAgree,
        termsConditionsIdList: this.protocolListNet.reduce((arr, cur) => arr.concat(cur.termsConditionList), []).map(item => item.termsConditionsId),
    }).then(res => {
        this.$store.commit('SET_PROTOCOL_MODAL', false)
        if (!isAgree) {
            api.client_auth_logout().then(() => {
                this.$store.commit('CLEAR_DATA', this)
            })
        }
    })
}

selectProtocolType(id) {
    this.selectedProtocolTypeId = id
    this.selectedProtocolTermsType = this.$store.state.protocolListNet.filter(protocol => +protocol.termsConditionsId === +id)[0].termsConditionList[0].termsType + ''
}
}

