import $config from '~/config'
import utils from '~/utils'

export default ({ app,store }, inject) => {
    inject('dealImgUrl', (url, sizeWidth, sizeHeight) => {
        if (!url) {
            return ''
        }
        let size = ''
        if (sizeWidth || sizeHeight) {
            size += '?x-oss-process=image/resize,'
            if (sizeWidth) {
                size += `w_${sizeWidth},`
            }
            if (sizeHeight) {
                size += `h_${sizeHeight}`
            }
        }else{
            size += '?x-oss-process=image'
        }    
        let res
   
        if(store.state.canUseWebpFormat){
            if (url.includes('https://images.jimuitech.com/')) { // 把老域名换成新域名
                url = url.replace('https://images.jimuitech.com/', $config.imgDomainName)
                res = `${url}${size}/format,webp`
            } else if (url.includes('https://images.eggmalltech.com/')) { // 把老域名换成新域名
                url = url.replace('https://images.eggmalltech.com/', $config.imgDomainName)
                res = `${url}${size}/format,webp`
            } else if (url.includes('https://image-cdn.eggmalltech.com/')) { // 把老域名换成新域名
                url = url.replace('https://image-cdn.eggmalltech.com/', $config.imgDomainName)
                res = `${url}${size}/format,webp`
            } else if (url.includes('https://image-cdn.jimuitech.com/')) { // 把老域名换成新域名
                url = url.replace('https://image-cdn.jimuitech.com/', $config.imgDomainName)
                res = `${url}${size}/format,webp`
            } else if (url.startsWith($config.imgDomainName)) { // 绝对路径
                res = `${url}${size}/format,webp`
            } else if (url.charAt(0) === '/') { // 相对路径 /开头
                res = `${$config.imgPrefix}${url}${size}/format,webp`
            } else { // 相对路径 没有/开头
                res = `${$config.imgDomainName}${url}${size}/format,webp`
            }
            if (url.includes('https://egg-images.oss-ap-southeast-1.aliyuncs.com/')) { // 把老域名换成新域名
                url = url.replace('https://egg-images.oss-ap-southeast-1.aliyuncs.com/', $config.imgDomainName)
                res = `${url}${size}/format,webp`
            }
        }else{
            if (url.includes('https://images.jimuitech.com/')) { // 把老域名换成新域名
                url = url.replace('https://images.jimuitech.com/', $config.imgDomainName)
                res = `${url}${size}`
            } else if (url.includes('https://images.eggmalltech.com/')) { // 把老域名换成新域名
                url = url.replace('https://images.eggmalltech.com/', $config.imgDomainName)
                res = `${url}${size}`
            } else if (url.includes('https://image-cdn.eggmalltech.com/')) { // 把老域名换成新域名
                url = url.replace('https://image-cdn.eggmalltech.com/', $config.imgDomainName)
                res = `${url}${size}`
            } else if (url.includes('https://image-cdn.jimuitech.com/')) { // 把老域名换成新域名
                url = url.replace('https://image-cdn.jimuitech.com/', $config.imgDomainName)
                res = `${url}${size}`
            } else if (url.startsWith($config.imgDomainName)) { // 绝对路径
                res = `${url}${size}`
            } else if (url.charAt(0) === '/') { // 相对路径 /开头
                res = `${$config.imgPrefix}${url}${size}`
            } else { // 相对路径 没有/开头
                res = `${$config.imgDomainName}${url}${size}`
            }
            // 处理测试图片
            if (url.includes('https://egg-images.oss-ap-southeast-1.aliyuncs.com/')) { // 把老域名换成新域名
                url = url.replace('https://egg-images.oss-ap-southeast-1.aliyuncs.com/', $config.imgDomainName)
                res = `${url}${size}`
            }
        }

        return res
    })

    inject('gochat', params => {

        
        let token = window.localStorage.getItem('token') || ''
        if (!token) {
            if(app.store.state.countryId == 1198){
                window.open('mailto:info.aakorea@kr.bosch.com')
            }else{
                app.store.commit('SET_LOGIN_MODAL', true)
            }
            return
        }

        let url = $config.chatWeb[$config.env] + '?token=' + token + '&langCode=' + app.store.state.langCode
        if (params) {
            url = url + '&' + Object.entries(params).map(item => `${item[0]}=${item[1]}`).join('&')
        }
        utils.fbq('track', 'Contact')
        console.log(url)
        window.open(url)
    })

    /**
     * 走马灯分页
     * @originData  源数据数组
     * @numPerGroup 走马灯每一页数量
     */
    inject('commonSliceGroupFunc', (originData: any[], numPerGroup: number) => {
        if (!originData || originData.length === 0) {
            return []
        }
        let genData: any[] = []
        for (let i = 0; i < Math.ceil(originData.length / numPerGroup); i++) {
            let end = (i + 1) * numPerGroup > originData.length ? originData.length : (i + 1) * numPerGroup
            genData.push({
                groupItems: originData.slice(i * numPerGroup, end),
            })
        }
        return genData
    })
}
