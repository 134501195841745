























































































































































































    import {
        Vue, Component, Watch,
    } from 'vue-property-decorator'
    import {State} from 'vuex-class'
    import $config from '~/config'
    import api from '~/api'
    import policyData from '~/utils/policy.ts'
    import utils from '~/utils'

@Component
    export default class CommonFooter extends Vue {
    @State('langCode') langCode

    @State('hotLineMap') hotLineMap

    @State('contactEmailMap') contactEmailMap

    @State('countryId') countryId

    protocolList:any = []

    selectedPolicyId:any

    privacySettingsVisible: any = false;

    get env() {
        return process.env.CUSTOM_ENV
    }

    get isHomePage() {
        return this.$route.path === '/en-TH' || this.$route.path === '/th-TH'
    }

    get repairWebUrl() {
        return $config.repairWeb[$config.env]
    }

    get sellerCenterWebUrl() {
        return $config.sellerCenterWeb[$config.env]
    }

    mounted() {
    }

    showProtocolModal(termsType) {
            let name
            const res = policyData
            this.$store.commit('SET_PROTOCOL_LIST', res && res.data || [])
            this.$store.commit('SET_SELECTED_PROTOCOL_ID', termsType)
            this.$store.commit('SET_SELECTED_POLICY_ID', termsType + '')
            this.protocolList = res.data
            this.selectedPolicyId = termsType
            // 存到localstorage
            window.localStorage.setItem('protocolList', JSON.stringify(this.protocolList))
            window.localStorage.setItem('selectedPolicyId', termsType)
            switch(termsType){
                case 7:
                    name = 'privacyPolicy'
                    break 
                case 27:
                    name = 'ConsentToCollectionAndUseOfPersonalInformation'
                    break 
                case 28:
                    name = 'ConsentToOverseasAndUseOfPersonalInformation'
                    break 
                default:
                    name = 'termsAndConditions'
                    break               
            }
            this.$router.push(this.localePath({
                name,
            }))
    }
    openFBLInk(){
        window.open('https://www.facebook.com/eggmallsupport')
    }
    openLineLInk(){
        window.open('https://bit.ly/LINEforweb')
    }
    openEmailLInk(){
        if(this.countryId != 1198){
            window.open('mailto:customerservice@whats-egg.com')
        }else{
            window.open('mailto:info.aakorea@kr.bosch.com')
        }
    }
    openPhone(){
       window.open(`tel:${this.hotLineMap[this.countryId]}` )
    }
    jumpToVietnamPolicy(num){
        this.$router.push(this.localePath({
            name: "vietnamPolicy",
            query: {
                selectedPolicyId: num
            },
        })) 
    }
    addGA4Event(){
        utils.ga4('event', 'conversion', {
            send_to: 'AW-11190145782/z-mMCKyskqMYEPal8Ncp',
        })
    }
    }
