


































    import {
        Vue,
        Component,
        Prop,
        Watch,
    } from 'vue-property-decorator'
    import {State} from 'vuex-class'
    import common from '~/utils/common'
    import api from '~/api'

@Component({
    name: 'LangBox',
})
    export default class extends Vue {
    @State('langCode') langCode

    @State('countryId') countryId

    @State('token') token

    @Prop(String)
    routeName!: string;

    langList: any = []

    get curLanguageIcon() {
        const langCode = this.langCode === 'en' ? 'en-TH' : this.langCode
        let curLang = this.langList.find((lang: any) => langCode === lang.langCode)
        if (curLang) {
            return curLang.countryFlag
        }
        return ''
    }

    mounted() {
        let countryList = this.$store.state.countryList
        let country2langlistMap = {}
        const langList = countryList.reduce((arr, cur) => {
            country2langlistMap[cur.id] = cur.languageList || []
            return arr.concat(cur.languageList || [])
        }, [])
        let langCodeIdhash = {}
        
        this.langList = langList.reduce((item, next) => {
            if (!langCodeIdhash[next.langCode]) {
                langCodeIdhash[next.langCode] = true && item.push(next)
            }
            return item
        }, [])
        
    }

    changeLang(langCode: string) {
        if (this.$i18n.locale === langCode) {
            return
        }
        langCode = langCode === 'en-TH' ? 'en' : langCode
        this.$store.commit('SET_LANG', langCode)
        this.$i18n.locale = langCode
        this.$router.push(this.switchLocalePath(langCode))
    }
    }
