
















































































































    import {
        Vue, Component, Prop,
    } from 'vue-property-decorator'
    import api from '~/api'
    import ImgLazyLoad from '~/components/img-lazy-load.vue'
    import { getSearchHistory } from '~/api/search'

    // 定义面包屑节点对象
    class SelectedLevelNode {
    id: number = 0

    label: string = ''

    type: string = ''

    constructor(node: any) {
        this.id = node.id
        this.label = node.label
        this.type = node.nodeType
    }
    }
@Component({
    components: {
        ImgLazyLoad,
    },
})
    export default class CommonSelectVehicleTypeStep extends Vue {
        @Prop({default: true}) showHistory!: any

        indexMap: any = {}

        indexLetterArr: string[] = []   // 字母索引

        selectedIndexLetter: string = 'Popular Brand'

        optionListLevel1: any[] = []

        optionListLevel2: any[] = []

        optionListLevel3: any[] = []

        optionListLevel4: any[] = []

        vehicleModelList2YearCapacityMap: any = {}

        selectedLevelNodeList: SelectedLevelNode[] = []

        curLevelType: string = 'brand'      // 面板层级标志：brand/serial/yearCapacity/model

        isFromMoreSpecific: boolean = false // 是否是从search products车型关键字联想more specific操作进来

        initialJumpLevelType: string = ''   // 从search products车型关键字联想more specific操作进入的指定层级

        historyDataVehicleType: any[] = []

        occupiedWidth:number = 0

        listElementGroupHtml: any[] = []

        // 获取车型品牌列表
        getVehicleBrand() {
            api.vehicle_brand_get().then(res => {
                this.indexMap = {}
                this.indexLetterArr = []
                let data = (res && res.data) || []
                for (let i = 0; i < data.length; i++) {
                    let obj = data[i]
                    this.indexMap[obj.letter] = obj.vehicleBrandList || []
                    this.indexLetterArr.push(obj.letter)
                    if (this.indexLetterArr[0] !== this.selectedIndexLetter) {
                        this.selectedIndexLetter = this.indexLetterArr[0]
                    }
                    this.optionListLevel1 = this.indexMap[this.selectedIndexLetter]
                }
            })
        }

        // 根据品牌id获取车系列表
        getVehicleSerialListByBrandId(node) {
            this.batchSave({vehicleBrandId: node.vehicleBrandId}, 131);
            if(sessionStorage.getItem('vehicleAdditionalData')){
                sessionStorage.removeItem('vehicleAdditionalData')
            }
            api.vehicle_serial_list_get({
                brandId: node.vehicleBrandId,
            }).then(res => {
                let data = (res && res.data) || {}
                this.optionListLevel2 = data.vehicleSerialList || []
                this.curLevelType = 'serial'
                if (!this.isFromMoreSpecific || this.initialJumpLevelType !== this.curLevelType) {
                    if (!this.noRepeatKeyCheck('brand')) {
                        return
                    }
                    this.selectedLevelNodeList.push(new SelectedLevelNode({
                        nodeType: 'brand',
                        id: node.vehicleBrandId,
                        label: node.vehicleBrandName,
                    }))
                }
            })
        }

        // 根据车系id获取年份列表
        getVehicleYearCapacityListBySerialId(node) {
            this.batchSave({vehicleSerialId: node.vehicleSerialId}, 132);
            api.vehicle_yearCapacity_get({
                vehicleSerialId: node.vehicleSerialId,
            }).then(res => {
                let data = (res && res.data) || {}
                this.optionListLevel3 = data.yearCapacityList || []
                this.vehicleModelList2YearCapacityMap = this.optionListLevel3.reduce((obj, cur) => {
                    obj[cur.yearCapacityId] = cur.vehicleModelBOList
                    return obj
                }, {})
                this.curLevelType = 'yearCapacity'
                if (!this.isFromMoreSpecific || this.initialJumpLevelType !== this.curLevelType) {
                    if (!this.noRepeatKeyCheck('serial')) {
                        return
                    }
                    this.selectedLevelNodeList.push(new SelectedLevelNode({
                        nodeType: 'serial',
                        id: node.vehicleSerialId,
                        label: node.vehicleSerialName,
                    }))
                }
            })
        }

        // 根据车系id获取排量列表
        getVehicleModelList(node) {
            this.batchSave({vehicleYearCapacityId: node.yearCapacityId}, 133);
            this.optionListLevel4 = this.vehicleModelList2YearCapacityMap[node.yearCapacityId]
            this.curLevelType = 'model'
            if (!this.isFromMoreSpecific || this.initialJumpLevelType !== this.curLevelType) {
                if (!this.noRepeatKeyCheck('yearCapacity')) {
                    return
                }
                this.selectedLevelNodeList.push(new SelectedLevelNode({
                    nodeType: 'yearCapacity',
                    id: node.yearCapacityId,
                    label: node.yearCapacity,
                }))
            }
        }

        selectVehicleModel(node, isHistory = false) {
            this.batchSave({vehicleModelId: node.vehicleModelId}, 134);
            let waitParams
            if (!isHistory) {
                waitParams = {
                    words: this.selectedLevelNodeList[0].label + ' ' + node.eggVehicleKeyDescription,
                    node,
                }
            } else {
                waitParams = {
                    words: node.searchValue,
                    node,
                }
            }

            this.$emit('selectVehicleModel', waitParams)
        }

        // 防止快速点击节点重复推入
        noRepeatKeyCheck(key) {
            if (this.selectedLevelNodeList.length === 0) {
                return true
            }
            let node: any = this.selectedLevelNodeList[this.selectedLevelNodeList.length - 1]
            if (node.type === key) {
                return false
            }
            return true
        }

        deleteOptions(option) {
            this.curLevelType = option.type
            let index = this.selectedLevelNodeList.findIndex((node: any) => node.type === option.type && node.id === option.id)  // 找到点击的面包屑节点在数组中的索引
            this.selectedLevelNodeList = this.selectedLevelNodeList.slice(0, index) // 截取数据
        }

        handleSelectIndexLetter(letter) {

            this.selectedIndexLetter = letter
            this.optionListLevel1 = this.indexMap[this.selectedIndexLetter]
        }

        // search products车型关键字联想more specific操作需要手动指定显示的层级
        setCurLevelType(data) {
            this.isFromMoreSpecific = true
            switch (data.jumpToVehicleTypePage) {
                case 1:
                    this.curLevelType = 'brand'
                    this.getVehicleBrand()
                    break
                case 2:
                    this.curLevelType = 'serial'
                    this.getVehicleSerialListByBrandId(data)
                    break
                case 3:
                    this.curLevelType = 'yearCapacity'
                    this.getVehicleYearCapacityListBySerialId(data)
                    break
                default:
                    break
            }
            this.initialJumpLevelType = this.curLevelType
        }

        mounted() {
            this.getSearchHistory()
        }

        getSearchHistory() {
            this.historyDataVehicleType = []
            this.$axios.$get(getSearchHistory('searchTypeList=1&searchTypeList=2'), {}).then(res => {
                if (res && res.data) {
                    this.historyDataVehicleType = res.data.filter(item => item.searchType === 'VEHICLE_TYPE')
                    this.$nextTick(() => {
                        let listElementGroup = document.querySelectorAll('.item-son')
                        this.listElementGroupHtml = [...listElementGroup]
                        this.listElementGroupHtml.map(item => {
                            this.occupiedWidth += parseInt(window.getComputedStyle(item).width)
                        })
                    })
                }
            })
        }

        searchByVinCode(searchValue) {
            this.$emit('searchByVinCodeEmit', searchValue)
        }

        openMoreHistory() {
            let domHistory = document.querySelector('.history-vehicle-code') as HTMLElement
            domHistory.className += ' show-history-more-son'
            let domImg = document.querySelector('.fold-open-son') as HTMLElement
            domImg.style.display = 'none'
        }

        closeMoreHistory() {
            let domHistory = document.querySelector('.history-vehicle-code') as HTMLElement
            domHistory.className = 'history-vehicle-code'
            let domImg = document.querySelector('.fold-open-son') as HTMLElement
            domImg.style.display = 'block'
        }


        batchSave(customizeValue, elementId) {
            let params = {
                datas: [{
                    appId: 'EGG_MALL_WEB',
                    appVersion: 'EGG_MALL_WEB',
                    carrier: 'EGG_MALL_WEB',
                    customizeValue,
                    deviceId: 'EGG_MALL_WEB',
                    dynamicResourceType: 1,
                    elementId,
                    eventTime: 1,
                    eventType: 1,
                    ip: 'EGG_MALL_WEB',
                    isFirstTime: 1,
                    latitude: 'EGG_MALL_WEB',
                    libVersion: 'EGG_MALL_WEB',
                    longitude: 'EGG_MALL_WEB',
                    manufacturer: 'EGG_MALL_WEB',
                    model: 'EGG_MALL_WEB',
                    networkType: 'EGG_MALL_WEB',
                    operatingSystem: 'WEB',
                    operatingSystemVersion: 'EGG_MALL_WEB',
                    referPage: 'EGG_MALL_WEB',
                    screenHeight: 'EGG_MALL_WEB',
                    screenName: 'EGG_MALL_WEB',
                    screenOrientation: 1,
                    screenWidth: 'EGG_MALL_WEB',
                    sessionId: 'EGG_MALL_WEB',
                    timeZone: 'EGG_MALL_WEB',
                    userId: this.$store.state.userId || 0,
                    businessPlatform: 11,
                }],
            }
            api.event_tracking(JSON.stringify(params))
        }
    }
