<template>
    <div style="white-space: pre-line;color: #555252;line-height: 23px">
        <h2 class="text-center" style="color: black">Terms of Use</h2>

        <p style="text-align: center;color: rgb(126,126,126);">Effective Date: [01/01/2024]</p>
        Welcome to the EGG Mall eCommerce platform (WEB page and app). Please read these terms and conditions carefully. The following Terms of Use govern your use and access of the Platform (defined below) and the use of the Services.

        By accessing the Platform and/or using the Services, you agree to be bound by these Terms of Use. If you do not agree to these Terms of Use, do not access and/or use this Platform or the Services.

        Access to and use of password protected and/or secure areas of the Platform and/or use of the Services are restricted to Customers with accounts only. You may not obtain or attempt to obtain unauthorized access to such parts of this Platform and/or Services, or to any other protected information, through any means not intentionally made available by us for your specific use. A breach of this provision may be an offence under the Computer Crime Act B.E. 2550 (2007) of Thailand.

        If you are below 20 years old: you must obtain consent from your parent(s) or legal guardian(s), their acceptance of these Terms of Use and their agreement to take responsibility for: (i) your actions; (ii) any charges associated with your use of any of the Services or purchase of Products; and (iii) your acceptance and compliance with these Terms of Use. If you do not have consent from your parent(s) or legal guardian(s), you must stop using/accessing this Platform and using the Services.

        <div class="bold">1.        Definitions & Interpretation</div>
        Unless otherwise defined, the definitions and provisions in respect of interpretation set out in Schedule 1 will apply to these Terms of Use. EGG Mall in its definition is also referring to a group of services including EGG Mall, EGG Repair, EGG Shop seller center, and other modules owned by WhatsEGG.

        <div class="bold">2.        General use of the Services and/or access of the Platform</div>
        2.1     Guidelines to the use of the Platform and/or the Services: You agree to comply with any and all the guidelines, notices, operating rules and policies and instructions pertaining to the use of the Services and/or access to the Platform, as well as any amendments to the aforementioned, issued by us, from time to time. We reserve the right to revise these guidelines, notices, operating rules and policies and instructions at any time and you are deemed to be aware of and bound by any changes to the foregoing upon their publication on the Platform.

        2.2     Restricted activities: You agree and undertake NOT to:

        (a)     impersonate any person or entity or to falsely state or otherwise misrepresent your affiliation with any person or entity;

        (b)     use the Platform or Services for illegal purposes;

        (c)     attempt to gain unauthorized access to or otherwise interfere or disrupt other computer systems or networks connected to the Platform or Services;

        (d)     post, promote or transmit through the Platform or Services any Prohibited Materials;

        (e)     interfere with another’s utilization and enjoyment of the Platform or Services;

        (f)     use or upload, in any way, any software or material that contains, or which you have reason to suspect that contains, viruses, damaging components, malicious code or harmful components which may impair or corrupt the Platform’s data or damage or interfere with the operation of another Customer’s computer or mobile device or the Platform or the Services; and

        (g)     use the Platform or the Services other than in conformance with the acceptable use policies of any connected computer networks, any applicable Internet standards and any other applicable laws.

        2.3     Availability of the Platform and the Services: We may, from time to time and with prior notice, upgrade, modify, suspend or discontinue the provision of or remove, whether in whole or in part, the Platform or any Services, providing an explanation for such actions and the period during which users can appeal against our decision, and shall not be liable if any such upgrade, modification, suspension or removal prevents you from accessing the Platform or any part of the Services.

        2.4     Right to monitor content: We reserve the right, at its sole discretion, but shall not be obliged to:

        (a)     monitor, screen or otherwise control any activity, content or material on the Platform and/or through the Services. We may, in our sole and absolute discretion, investigate any violation of the terms and conditions contained herein and may take any action it deems appropriate;

        (b)     prevent or restrict access of any Customer to the Platform and/or the Services;

        (c)     report any activity we suspect to be in violation of any applicable law, statute or regulation to the appropriate authorities and to co-operate with such authorities; and/or

        (d)     to request any information and data from you in connection with your use of the Services and/or access of the Platform at any time and to exercise our right under this paragraph if you refuse to divulge such information and/or data or if you provide or if we have reasonable grounds to suspect that you have provided inaccurate, misleading or fraudulent information and/or data.

        2.5     Privacy Policy: Your use of the Services and/or access to the Platform is also subject to the Privacy Policy as set out at https://www.EGGMalltech.com. Please review our Privacy Policy, which informs you what personal data we may collect and how it may be used. We respect your privacy and are committed to protecting it. You acknowledge that your information given to the platform (for example Facebook ID, Line ID) will be saved within the EGG Mall customer database, and can be used to send you regular promotion information with your prior consent.

        2.6     Terms & Conditions of Sale and Voucher Terms & Conditions: Purchases of any Product would be subject to the Terms & Conditions of Sale. If you use a Voucher, the Voucher Terms & Conditions would apply.

        2.7     Additional terms: In addition to these Terms of Use, Shipping Policy, Return Policy, Warranty Policy, the use of specific aspects of the Materials and the Services, more comprehensive or updated versions of the Materials offered by us or our designated sub-contractors, may be subject to additional terms and conditions, which will apply in full force and effect.

        <div class="bold">3.        Use of the Services</div>
        3.1     Application of this Clause: In addition to all other terms and conditions of this Terms of Use, the provisions in this Clause 3 are the additional specific terms and conditions governing your use of the Services.

        3.2     Restrictions: Use of the Services is limited to the authorized Customers who are of legal age and have the legal capacity to enter into and form contracts under any applicable law. The Customers who have breached or are in breach of the terms and conditions contained herein and the Customers who have been permanently or temporarily suspended from using any of the Services may not use the Services even if they satisfy the requirements of this Clause 3.2.

        3.3     General terms of use: You agree:

        (a)     to access and/or use the Services only for lawful purposes and in a lawful manner at all times and further agree to conduct any activity relating to the Services in good faith; and

        (b)     to ensure that any information or data you post or cause to appear on the Platform in connection with the Services is accurate and not violate any applicable law. You also agree to take sole responsibility for such information and data.

        3.4     Product description: While we endeavour to provide an accurate description of the Products, we do not warrant that such description is accurate, current or free from error.

        3.5     Prices of Products: All Listing Prices are including taxes, unless explicitly stated otherwise. We reserve the right to amend the Listing Prices at any time without giving any reason or prior notice, except in case of the controlled-price goods in which case we will give a prior notice as required by applicable law.

        3.6     Third Party Vendors: You acknowledge that parties other than EGG Mall (i.e. Third Party Vendors) list and sell their Products on the Platform. The information regarding whether a particular Product is listed for sale on the Platform by EGG Mall or a Third Party Vendor may be stated on the webpage listing that Product. For the avoidance of doubt, each agreement entered into for the sale of a Third Party Vendor’s Products to a Customer shall be an agreement entered into directly and only between the Third Party Vendor and the Customer and also subjected to the Terms and Conditions of Sale. EGG Mall will not take responsibility of the checking the data quality (including vehicle application) information. You agree that the aftersales service and product responsibility are taken up by third party vendor.

        <div class="bold">4.        Customers with EGG Mall, EGG Repair accounts</div>
        4.1     Username/Password: Certain Services that may be made available on the Platform may require creation of an account with us or for you to provide Personal Data. If you request to create an account with us, a Username and Password may either be: (i) determined and issued to you by us; or (ii) provided by you and accepted by us in our sole and absolute discretion in connection with the use of the Services and/or access to the relevant Platform. We may at any time in our sole and absolute discretion, request that you update your Personal Data or forthwith invalidate the Username and/or Password without giving any reason or prior notice and shall not be liable or responsible for any Losses suffered by or caused by you or arising out of or in connection with or by reason of such request or invalidation. You hereby agree to change your Password from time to time and to keep the Username and Password confidential and shall be responsible for the security of your account and liable for any disclosure or use (whether such use is authorized or not) of the Username and/or Password. You are to notify us immediately if you have knowledge that or have reason for suspecting that the confidentiality of the Username and/or Password has been compromised or if there has been any unauthorized use of the Username and/or Password or if your Personal Data requires updating. The notice will be deemed to be processed by us seven (7) business days after the receipt of such notice.

        4.2     Purported use/access: You agree and acknowledge that any use of the Services and/or any access to the Platform and any information, data or communications referable to your Username and Password shall be deemed to be, as the case may be:

        (a)     access to the relevant Platform and/or use of the Services by you; or

        (b)     information, data or communications posted, transmitted and validly issued by you.
        At all times, you agree to be bound by any access of the Platform and/or use of any Services (whether such access or use are authorized by you or not, except for any unauthorized access of the Platform after the notice of unauthorized use of the Username and/or Password has been received and processed by us, as per clause 4.1 herein). You agree that we shall be entitled (but not obliged) to act upon, rely on or hold you solely responsible and liable in respect thereof as if the same were carried out or transmitted by you. You further agree and acknowledge that you shall be bound by and agree to fully indemnify us against any and all Losses attributable to any use of any Services and/or or access to the Platform referable to your Username and Password.

        <div class="bold">5.Intellectual property</div>
        5.1     Ownership: The Intellectual Property in and to the Platform and the Materials are owned, licensed to or controlled by us, our licensors or our service providers. We reserve the right to enforce the right of the Intellectual Property to the fullest extent of the law.

        5.2     Restricted use: No part or parts of the Platform, or any of the Materials may be reproduced, reverse engineered, decompiled, disassembled, separated, altered, distributed, republished, displayed, broadcast, hyperlinked, mirrored, framed, transferred or transmitted in any manner or by any means or stored in an information retrieval system or installed on any server, system or equipment without our prior written permission or that of the relevant copyright owners. Subject to Clause 5.3, permission will only be granted to you to download, print or use the Materials for personal and non-commercial uses, provided that you do not modify the Materials and that we or the relevant copyright owners retain all copyright and other proprietary notices contained in the Materials.

        5.3     Trademarks: The Trademarks shall have the meaning as set out in the attached schedule herein. Nothing on the Platform and in these Terms of Use shall be construed as granting, by implication, estoppel, or otherwise grant, any license or right to use (including as a meta tag or as a “hot” link to any other website) any Trademarks displayed on the Services, without our written permission or any other applicable trademark owner.

        <div class="bold">6.        Our limitation of responsibility and liability</div>
        6.1     No representations or warranties: The Services, the Platform and the Materials are provided on an “as is” and “as available” basis. All data and/or information contained in the Platform, the Services or the Materials are provided for informational purposes only. No representations or warranties of any kind, implied, express or statutory, including the warranties of non-infringement of third party rights, title, merchantability, satisfactory quality or fitness for a particular purpose, are given in conjunction with the Platform, the Services or the Materials. Without prejudice to the generality of the foregoing, we do not warrant:

        (a)     the accuracy, timeliness, adequacy, commercial value or completeness of all data and/or information contained in the Platform, the Services or the Materials;

        (b)     that the Platform, the Services or that any of the Materials will be provided uninterrupted, secure or free from errors or omissions, or that any identified defect will be corrected;

        (c)     that the Platform, the Services or the Materials are free from any computer virus or other malicious, destructive or corrupting code, agent, program or macros; and

        (d)     the security of any information transmitted by you or to you through the Platform or the Services, and you accept the risk that any information transmitted or received through the Services or the Platform may be accessed by unauthorized third parties and/or disclosed by us or our officers, employees or agents to third parties purporting to be you or purporting to act under your authority. Transmissions over the Internet and electronic mail may be subject to interruption, transmission blackout, delayed transmission due to internet traffic or incorrect data transmission due to the public nature of the Internet.

        6.2     Exclusion of liability: Except for liability resulting from our wilful misconduct or gross negligence, EGG Mall Indemnitees shall not be liable to you for any Losses caused (regardless of the form of action) arising directly or indirectly in connection with:

        (a)     any access, use and/or inability to use the Platform or the Services;

        (b)     reliance on any data or information made available through the Platform and/or through the Services. You should not act on such data or information without first independently verifying its contents;

        (c)     any system, server or connection failure, error, omission, interruption, delay in transmission, computer virus or other malicious, destructive or corrupting code, agent program or macros; and

        (d)     any use of or access to any other website or webpage linked to the Platform, even if we or our officers or agents or employees may have been advised of, or otherwise might have anticipated, the possibility of the same.

        6.3     At your own risk: Any risk of misunderstanding, error, damage, expense or Losses resulting from the use of the Platform is entirely at your own risk and we shall not be liable therefor.

        <div class="bold">7.        Hyperlinks, alerts and advertising</div>
        7.1     Hyperlinks: For your convenience, we may include hyperlinks to other websites or content on the Internet that are owned or operated by third parties. Such linked websites or content are not under our control and we are not liable for any errors, omissions, delays, defamation, libel, slander, falsehood, obscenity, pornography, profanity, inaccuracy or any other objectionable material contained in the contents, or the consequences of accessing, any linked website. Any hyperlinks to any other websites or content are not an endorsement or verification of such websites or content and you agree that your access to or use of such linked websites or content is entirely at your own risk.

        7.2     Advertising: We may attach banners, java applets and/or such other materials to the Platform for the purposes of advertising our or our Third Party Vendors’ products and/or services. For the avoidance of doubt, you shall not be entitled to receive any payment, fee and/or commission in respect of any such advertising or other promotional materials.

        <div class="bold">8.        Your submissions and information</div>
        8.1     Submissions by you: You grant us a non-exclusive license to use the materials or information that you submit to the Platform and/or provide to us, including but not limited to, questions, reviews, comments, and suggestions (collectively, “Submissions”). When you post comments or reviews to the Platform, you also grant us the right to use the name that you submit or your Username in connection with such review, comment, or other content. Your comments or reviews must be based on your personal experience in purchasing the Goods. You agree not to submit any comments, reviews, or otherwise communicate using the Platform in a manner that may violate laws, harass, or engage in other inappropriate actions towards another person or any organization. You shall not use a false e-mail address, pretend to be someone other than yourself or otherwise mislead us or third parties as to the origin of any Submissions. We may publish, remove or edit your Submissions at our discretion or as required by any applicable law.

        8.2     Consent to receive e-mails: We will ask for your consent to the use by us of any information provided by you (including Personal Data) for the purposes of sending informational and promotional e-mails to you. Your consent given to us shall constitute your consent for the purpose of the provisions of any spam control laws (whether in Thailand or elsewhere). You may subsequently opt out of receiving promotional e-mails by clicking on the appropriate hyperlink in any promotional e-mail. You acknowledge that your information given to the platform (for example facebook ID, Line ID) will be saved within EGG Mall customer database and can be used for further marketing activities with your prior consent.

        <div class="bold">9.        Termination</div>
        9.1     Termination by us: In our sole and absolute discretion, we may, with immediate effect and upon giving you advance notice if required by law, terminate your use of the Platform and/or Services and/or disable your Username and Password. We may bar access to the Platform and/or Services (or any part thereof) for any reason whatsoever, including a breach of any of these Terms of Use or where if we believe that you have violated or acted inconsistently with any terms or conditions set out herein, or if in our opinion or the opinion of any regulatory authority, it is not suitable to continue providing the services relating to the Platform.

        9.2     Termination by you: You may terminate these Terms of Use by giving seven (7) days’ advance notice in writing to us at customerservice@whats-egg.com

        <div class="bold">10.       Notices</div>
        10.1        Notices from us: All notices or other communications given to you if:

        (a)     communicated through any print or electronic media as we may select will be deemed to be notified to you on the date of publication or broadcast; or

        (b)     sent by post or left at your last known address will be deemed to be received by you on the day following such posting or on the day when it was so left.

        10.2        Notices from you: You may only give notice to us in writing sent to our designated address or e-mail address at 253 Asoke Building, Floor 10, Sukhumvit 21 Road, Klongtoey Nua, Wattana, Bangkok 10110, Thailand and https://www.EGGMalltech.com and we shall be deemed to have received such notice only upon receipt. While we endeavor to respond promptly to notices from you, we cannot guarantee that we will always respond with consistent speed.

        10.3O       ther modes: Notwithstanding Clauses 10.1 and 10.2, we may from time to time designate other acceptable modes of giving notices (including but not limited to e-mail or other forms of electronic communication) and the time or event by which such notice shall be deemed given.

        <div class="bold">11.       General</div>
        11.1        Cumulative rights and remedies: Unless otherwise provided under these Terms of Use, the provisions of these Terms of Use and our rights and remedies under these Terms of Use are cumulative and are without prejudice and in addition to any rights or remedies we may have in law or in equity, and no exercise by us of any one right or remedy under these Terms of Use, or at law or in equity, shall (save to the extent, if any, provided expressly in these Terms of Use or at law or in equity) operate so as to hinder or prevent our exercise of any other such right or remedy as at law or in equity.

        11.2        No waiver: Our failure to enforce these Terms of Use shall not constitute a waiver of these terms, and such failure shall not affect the right later to enforce these Terms of Use. We would still be entitled to use our rights and remedies in any other situation where you breach these Terms of Use.

        11.3        Severability: If at any time any provision of these Terms of Use shall be or shall become illegal, invalid or unenforceable in any respect, the legality, validity and enforceability of the remaining provisions of this Agreement shall not be affected or impaired thereby, and shall continue in force as if such illegal, invalid or unenforceable provision was severed from these Terms of Use.

        11.4        Rights of third parties: A person or entity who is not a party to these Terms of Use shall have no right under any legislation in any jurisdiction to enforce any term of these Terms of Use, regardless of whether such person or entity has been identified by name, as a member of a class or as answering a particular description. For the avoidance of doubt, nothing in this Clause shall affect the rights of any permitted assignee or transferee of these Terms of Use.

        11.5        Governing law: Use of the Platform and/or the Services and these Terms of Use shall be governed by and construed in accordance with Thailand law and you hereby submit to the exclusive jurisdiction of the Courts of Thailand.

        11.6        Injunctive relief: We may seek immediate injunctive relief if we make a good faith determination that a breach or non-performance is such that a temporary restraining order or other immediate injunctive relief is the only appropriate or adequate remedy.

        11.7        Amendments: We may by notice through the Platform or by such other method of notification as we may designate (which may include notification by way of e-mail), vary the terms and conditions of these Terms of Use. We will provide you with advance notice of material revisions to these Terms of Use. We will not make revisions that have an immediate effect unless we are legally required to do so or to protect you and other users of the Services. If you use the Platform or the Services after such effective date, you are deemed to have accepted such variation. If you do not accept the variation, you must stop access or using the Platform and the Services and terminate these Terms of Use. Our right to vary these Terms of Use in the manner aforesaid will be exercised with may be exercised without the consent of any person or entity who is not a party to these Terms of Use.

        11.8        Correction of errors: Any typographical, clerical or other error or omission in any acceptance, invoice or other document on our part shall be subject to correction without any liability on our part.

        11.9        Currency: Money references under these Terms of Use shall be in Thai Baht.

        11.10       Language: In the event that these Terms of Use is executed or translated in any language other than English (“Foreign Language Version”), the English language version of these Terms of Use shall govern and shall take precedence over the Foreign Language Version.

        11.11       Entire agreement: These Terms of Use shall constitute the entire agreement between you and us relating to the subject matter hereof and supersedes and replaces in full all prior understandings, communications and agreements with respect to the subject matter hereof.

        11.12       Binding and conclusive: You acknowledge and agree that any records (including records of any telephone conversations relating to the Services, if any) maintained by us or our service providers relating to or in connection with the Platform and the Services shall be binding and conclusive on you for all purposes whatsoever and shall be conclusive evidence of any information and/or data transmitted between us and you. You hereby agree that all such records are admissible in evidence and that you shall not challenge or dispute the admissibility, reliability, accuracy or the authenticity of such records merely on the basis that such records are in electronic form or are the output of a computer system, and you hereby waive any of your rights, if any, to so object.

        11.13       Sub-contracting and delegation: We reserve the right to delegate or sub-contract the performance of any of our functions in connection with the Platform and/or Services and reserve the right to use any service providers, subcontractors and/or agents on such terms as we deem appropriate.

        11.14       Assignment: You may not assign your rights under these Terms of Use without our prior written consent. We may assign our rights under these Terms of Use to any third party.

        11.15       Force Majeure: We shall not be liable for non-performance, error, interruption or delay in the performance of its obligations under these Terms of Use (or any part thereof) or for any inaccuracy, unreliability or unsuitability of the Platform's and/or Services’ contents if this is due, in whole or in part, directly or indirectly to an event or failure which is beyond our reasonable control.

        <div class="bold">12.       Personal Data and business data</div>
        12.1        All data (including but not limiting to customers’ Personal Data, transaction details etc.) submitted via EGG Mall system (includes EGG Repair or EGG shop seller center or EGG Alai) will be stored as our confidential information, and this information will be utilized for business purpose only (e.g. to improve service to customers) by WhatsEGG (Thailand) and relevant business partners.

        12.2        Customer will be notified and asked for consent (if required) before any personal data (including name, address, phone number) shared with business partner, and we bind the partner to the same standard that WhatsEGG (Thailand) applies.

        12.3        Customer can access and update personal information in application and can request to delete personal information from our system permanently via customer service mail box at customerservice@whats-egg.com. After receiving such request, EGG Mall will take up reasonable time (up to 60 working days) to delete the information as requested. After the personal information is deleted, the customer account is deemed as cancelled, and the customer will not be accessible to past order history or any other remaining benefit (vouchers, orders, delivery).

        12.4        Customer acknowledges that WhatsEGG (Thailand) will use customer’s transaction data for business purposes. Further details are provided in the Privacy Policy as set out at https://www.EGGMalltech.com.

        <div class="bold">13.       Other Information Obligations</div>
        13.1        We may provide recommended Goods on the Platform based on an algorithm that takes into account the number of Goods ordered and the rating of the Goods.

        13.2        As part of our Services, we may recommend features, products, and services, including third party-ads that may be of interest to you.

        13.3        We only provide our Services through the Platform. Any additional distribution channels (if any) for the Goods will be communicated to you later.

        13.4        We typically do not offer ancillary goods or services to Customers prior to the completion of a transaction.




        <div class="bold underline">Schedule 1</div>
        <div class="bold">Definitions and Interpretation</div>
        1.      Definitions. Unless the context otherwise requires, the following expressions shall have the following meanings in these Terms of Use:

        1.1     “Customer” means an authorised user of the Platform and/or the Services who purchases or uses the products and services from EGG Mall for commercial uses only including but not limited to garage.

        1.2     “Intellectual Property” means all copyright, patents, utility innovations, trademarks and service marks, geographical indications, domain names, layout design rights, registered designs, design rights, database rights, trade or business names, rights protecting trade secrets and confidential information, rights protecting goodwill and reputation, and all other similar or corresponding proprietary rights and all applications for the same, whether presently existing or created in the future, anywhere in the world, whether registered or not, and all benefits, privileges, rights to sue, recover damages and obtain relief or other remedies for any past, current or future infringement, misappropriation or violation of any of the foregoing rights.

        1.3     “EGG Mall Indemnitees” means EGG Mall and all of its respective officers, employees, directors, agents, contractors and assigns.

        1.4     “EGG Mall” means WhatsEGG (Thailand) and also serves as the trade name of WhatsEGG (Thailand).

        1.5     “Listing Price” means the price of Products listed for sale to Customers, as stated on the Platform. Market price “means the reference price of product listed for sales to customers within a certain period, the platform doesn’t take any responsibility of updating the price for every day. Price guarantee is a promotion term, details please refer to the terms listed in the application.

        1.6     “Losses” means all penalties, losses, settlement sums, costs (including legal fees and expenses on a solicitor- client basis), charges, expenses, actions, proceedings, claims, demands and other liabilities, whether foreseeable or not.

        1.7     “Materials” means, collectively, all web pages on the Platform, including the information, images, links, sounds, graphics, video, software, applications and other materials displayed or made available on the Platform and the functionalities or services provided on the Platform.

        1.8     “Order” means your order for Products sent through the Platform in accordance with the Terms & Conditions of Sale.

        1.9     “Password” refers to the valid password that a Customer who has an account with EGG Mall may use in conjunction with the Username to access the relevant Platform and/or Services.

        1.10        “Personal Data” means data that can be used to identify, contact or locate you. Personal Data can include your name, e-mail address, billing address, shipping address, phone number, facebook ID, Line ID, review history, purchase history, favorites Products and credit card information. Personal Data shall be deemed to include any data that you have provided to us when placing an Order, regardless of whether you have an account with us.

        1.11        “Platform” means (a) both the web and mobile versions of the website operated and/or owned by EGG Mall which is presently located at https://www.EGGMalltech.com. and (b) the mobile applications made available from time to time by EGG Mall, including the iOS and Android versions.

        1.12        “Privacy Policy” means the privacy policy set out at https://www.EGGMalltech.com

        1.13        “Product” means a product (including any installment of the product or any parts thereof) available for sale to Customers on the Platform.

        1.14        “Prohibited Material” means any information, graphics, photographs, data and/or any other material that:

        (a)     contains any computer virus or other invasive or damaging code, program or macro;

        (b)     infringes any third-party Intellectual Property or any other proprietary rights;

        (c)     is defamatory, libelous or threatening;

        (d)     is obscene, pornographic, indecent, counterfeited, fraudulent, stolen, harmful or otherwise illegal under the applicable law; and/or

        (e)     is or may be construed as offensive and/or otherwise objectionable, in our sole opinion.

        1.15        “Services” means services, information and functions made available by us at the Platform.

        1.16        “Submission” is as defined in Clause 8.1 of these Terms of Use.

        1.17        “Terms & Conditions of Sale” means the terms and conditions governing a Customer’s purchase of the Products and are set out at https://www.EGGMalltech.com.

        1.18        “Terms of Use” means the recitals, Clauses 1 to 13 and any Schedules to these terms and conditions.

        1.19        “Trademarks” means the trademarks, service marks, trade names and logos used and displayed on the Platform, whether registered or unregistered, and owned by EGG Mall or any third party.

        1.20        “Username” refers to the unique login identification name or code which identifies a Customer who has an account with EGG Mall.

        1.21        “Voucher” means a voucher for credit which may be used by a Customer, subject to Voucher Terms & Conditions and other terms and conditions, towards the payment of purchases on the Platform.

        1.22        “Voucher Terms & Conditions” are set out at https://www.EGGMalltech.com

        1.23        “WhatsEGG”, “WhatsEGG (Thailand)” refer to WhatsEGG (Thailand) Co., Ltd., a company incorporated pursuant to the laws of Thailand under registration number 0105561196889 and having its registered address at 253, 253 Asoke Building, Floor 10th, Sukhumvit 21 Road, Klongtoey Nua, Wattana, Bangkok 10110 Thailand.

        1.24        “you” and “your” refer to the individuals over the age of 20 or otherwise under the supervision of a parent or legal guardian.

        2.      Interpretation: Any reference in these Terms of Use to any provision of a statute shall be construed as a reference to that provision as amended, re-enacted or extended at the relevant time. In the Agreement, whenever the words “include”, “includes” or “including” are used, they will be deemed to be followed by the words “without limitation”. Unless expressly indicated otherwise, all references to a number of days mean calendar days, and the words “month” or “monthly” as well as all references to a number of months means calendar months. Clause headings are inserted for convenience only and shall not affect the interpretation of these Terms of Use. In the event of a conflict or inconsistency between any two or more provisions under these Terms of Use, whether such provisions are contained in the same or different documents, such conflict or inconsistency shall be resolved in favor of EGG Mall and the provision which is more favorable to EGG Mall shall prevail.



        <h1>Terms & Conditions of Sale</h1>
        <div class="bold">1.        Definitions & Interpretation</div>
        Unless otherwise defined, the definitions and provisions in respect of interpretation set out in Schedule 1 will apply to these Terms & Conditions of Sale. EGG Mall in its definition is also referring to a group of services including EGG Mall, EGG Repair, EGG Shop seller center, and other modules owned by WhatsEGG.

        <div class="bold">2.        Purchase of Products</div>
        2.1     Your compliance: You agree to comply with any and all the guidelines, notices, operating rules and policies and instructions pertaining to the purchase of Products through the Platform, as well as any amendments to the aforementioned, issued by EGG Mall (whether as part of use of the Platform or in relation to the purchase of Products, on behalf of Seller), from time to time. EGG Mall reserves the right to revise these guidelines, notices, operating rules and policies and instructions at any time and you are deemed to be aware of and bound by any changes to the foregoing upon their publication on the Platform.

        2.2     Product description: While Seller endeavours to provide an accurate description of the Products, neither EGG Mall nor Seller warrants that such description is accurate, current or free from error. In the event that the Product you receive is of a fundamentally different nature from the Product as described on the Platform and which you have ordered, Clause 6 of these Terms & Conditions of Sale shall apply.

        2.3     Sellers: Products are sold by “Sellers”. EGG Mall may be a “Seller” for selected Products. “Seller” may also refer to a party other than EGG Mall (such party referred to in these Terms & Conditions of Sale as a “Third Party Vendor”). Whether a particular Product is listed for sale on the Platform by EGG Mall or a Third Party Vendor may be stated on the webpage listing that Product. Products sold to you by Sellers will be governed by individual Customer Contracts (more details below in Clause 2.6) which:

        2.3.1       for Products sold by Third Party Vendors, shall be agreements entered into directly and only between the Third Party Vendor and you; and

        2.3.2       for Products sold by EGG Mall, shall be agreements entered into directly and only between EGG Mall and you.

        2.4     Placing your Order: You may place an Order by completing the Order form on the Platform and clicking on the “Place my Order” button. Seller will not accept Orders placed in any other manner. You shall be responsible for ensuring the accuracy of the Order.

        2.5     Orders are irrevocable and unconditional: All Orders will be deemed to be irrevocable and unconditional upon transmission through the Platform and Seller shall be entitled (but not obliged) to process such Order(s) without your further consent and without any further reference or notice to you. Nevertheless, in certain circumstances as stated in Clause 8, you may request to cancel or amend the Order which Seller will endeavour to give effect to on a commercially reasonable effort basis. However, notwithstanding the foregoing, Seller is not obliged to give effect to any request to cancel or amend any Order.

        2.6     Seller’s reservation of rights in respect of Orders: All Orders shall be subject to Seller’s acceptance in its sole discretion and each Order accepted by Seller (such accepted Order to be referred to as a “Customer Contract”) shall constitute a separate contract. You acknowledge that unless you receive a notice from Seller accepting your Order, Seller shall not be party to any legally binding agreements or promises made between Seller and you for the sale or other dealings with the Product(s) and accordingly Seller shall not be liable for any Losses which may be incurred as a result. For the avoidance of doubt, Seller reserves the right to decline to process or accept any Order received from or through the Platform in its absolute discretion.

        2.7     Termination by Seller in the event of pricing error: Seller reserves the right to terminate the Customer Contract, in the event that a Product has been mispriced on the Platform, in which event EGG Mall shall, on behalf of Seller, notify you of such cancellation by giving three days’ notice.

        2.8     Product Warranty: You agree and acknowledge that EGG Mall is not the producer of the products sold and thus does not take responsibility of the product warranty. EGG Mall will transfer the warranty conditions by the brand owners to the sellers. The warranties with respect to a Product (“Product Warranty”) sold under a Customer Contract shall be as stated by Seller via the Platform, under the “Specifications” tab in the fields “Warranty Type” and “Warranty Period” for the relevant Product, and shall be limited by the terms and conditions therein. The warranties and conditions, remedies for breach of warranty or condition, or other terms stated in the Product Warranty are, unless expressly prohibited by applicable mandatory law, in lieu of all other terms, warranties and conditions, whether expressed or implied, statutory or otherwise. Except as expressly provided in such Product Warranty, Seller excludes (unless expressly prohibited by applicable mandatory law) all other expressed or implied terms, warranties or conditions with respect to the Products supplied.

        2.9     Customer’s acknowledgement: You acknowledge and warrant that you have not relied on any term, condition, warranty, undertaking, inducement or representation made by or on behalf of Seller which has not been stated expressly in a Customer Contract or upon any descriptions or illustrations or specifications contained in any document including any catalogues or publicity material produced by either EGG Mall or Seller. You also acknowledge and agree that the exclusion of warranties, exclusion of liability and exclusion of remedies in these Terms & Conditions of Sale and Customer Contracts allocate risks between the parties and permit Seller to provide the Products at lower fees or prices than Seller otherwise could and you agree that such exclusions on liability are reasonable.

        2.10        No representations or warranties: Without prejudice to the generality of the foregoing Clause 2.9:

        2.10.1      No condition is made or to be implied nor is any warranty given or to be implied as to the life or wear of the Products supplied or that they will be suitable for any particular purpose or use under any specific conditions, notwithstanding that such purpose or conditions may be known or made known to Seller;

        2.10.2      Seller binds itself only to deliver Products in accordance with the general description under which they were sold, whether or not any special or particular description shall have been given or shall be implied by law. Any such special or particular description shall be taken only as the expression of Seller's opinion in that behalf. Neither EGG Mall nor Seller gives any warranty as to the quality, state, condition or fitness of the Products;

        2.10.3      EGG Mall shall be under no liability for the following measures and actions taken by the Customer or third parties and the consequences thereof: improper remedy of defects, alteration of the Products without the prior agreement of EGG Mall, addition and insertion of parts, in particular of spare parts which do not come from EGG Mall;

        2.10.4      Seller shall be under no liability in respect of any defect arising from unsuitable or improper use, defective installation or commissioning by the Customer or third parties, fair wear and tear, wilful damage, negligence, abnormal working conditions, defective or negligent handling, improper maintenance, excessive load, unsuitable operating materials and replacement materials, poor work, unsuitable foundation, chemical, electro- technical/electronic or electric influences, the Customer or third parties’ failure to follow EGG Mall's instructions (whether oral or in writing) misuse or alteration or repair of the Products without EGG Mall's approval;

        2.10.5      Seller is not liable for any Losses suffered by any third party directly or indirectly caused by repairs or remedial work carried out without EGG Mall’s prior written approval and the Customer shall indemnify Seller against all Losses arising out of such claims;

        2.10.6      Seller shall be under no liability under the above warranty (or any other warranty, condition or guarantee) if the total price for the Products has not been paid in cleared funds by the due date for payment; and

        2.10.7      Seller shall be under no liability whatsoever in respect of any defect in the Products arising after the expiry of the applicable Product Warranty, if any.

        2.11        Intellectual Property:

        2.11.1      Unless the prior written consent of EGG Mall has been obtained, the Customer shall not remove or alter the trade marks, logos, copyright notices, serial numbers, labels, tags or other identifying marks, symbols or legends affixed to any Products.

        2.11.2      Where software applications, drivers or other computer programmes and/or all other design details, technical handbooks or manuals, drawing or other data (all collectively referred to as “Product Materials”) are supplied to the Customer by Seller in connection with the Order, the use and retention of the Product Materials are subject to the terms and conditions of license or use (such as end-user licenses, restrictions or conditions of use) as may be prescribed by Seller or its licensors and must not be used other than strictly in accordance with such terms and conditions.

        2.11.3      The Customer agrees and acknowledges that the Product Materials shall remain the property of Seller or its licensors. The Customer further agrees that any and all Intellectual Property embodied in or relating to the Product Materials shall remain the sole and exclusive property of Seller or its licensors. Unless otherwise expressly provided in the Order or the prior written consent of EGG Mall has been obtained, the Customer undertakes to return the Product Materials and/or any copies thereof upon EGG Mall’s request.

        <div class="bold">3.        Delivery of Products</div>
        3.1     Address: Delivery of the Products shall be made to the address you specify in your Order either by Seller or by EGG Mall (or its agents) on behalf of Seller.

        3.2     Delivery & packing charges: Delivery and packing charges shall be as set out in the Order.

        3.3     Tracking: You may track the status of the delivery at the “Order Tracking” page of the Platform.

        3.4     Delivery timeframe: You acknowledge that delivery of the Products is subject to availability of the Products. Seller will make every reasonable effort to deliver the Product to you within the delivery timeframe stated on the relevant page on which the Product is listed, but you acknowledge that while stock information on the Platform is updated regularly, it is possible that in some instances a Product may become unavailable between updates. All delivery timeframes given are estimates only and delays can occur. If the delivery of your Product is delayed Seller will inform you accordingly via e-mail and your Product will be dispatched as soon as it becomes available to Seller. The time for delivery shall not be of the essence, and Seller (nor any of its agents) shall not be liable for any delay in delivery howsoever caused. The detail shipping fee and delivery time you may refer to the shipping policy listed in https://www.EGGMalltech.com.

        3.5     Deemed receipt: In the event you do not receive the Product by the projected delivery date and provided that you inform EGG Mall within reasonable period from such projected delivery date, Seller will try, to the best of Seller’s ability, to locate and deliver the Product. If EGG Mall does not hear from you within 5 working days from such projected delivery date, you shall be deemed to have received the Product and your order status in application will be updated to closed by system. the Aftersale service period will be calculated from the order closed date onwards.

        3.6     Customer’s failure to take delivery: If the Customer fails to take delivery of the Products (otherwise than by reason of any cause beyond the Customer's reasonable control or by reason of Seller’s fault) then without prejudice to any other right or remedy available to Seller, Seller may terminate the Customer Contract.

        <div class="bold">4.        Prices of Products</div>
        4.1     Listing Price: The price of the Products payable by a Customer shall be the Payable Price at the time at which the Order placed by the Customer is transmitted to Seller (through the Platform).

        4.2     Taxes: All Listing Prices are including taxes, unless otherwise stated. Seller reserves the right to amend the Listing Prices at any time without giving any reason or prior notice, except in case of the controlled-price goods, in which case we will give a prior notice as required by applicable law.

        <div class="bold">5.        Payment</div>
        5.1     General: You may pay for the Product using any of the payment methods prescribed by EGG Mall from time to time. When you place an Order, actual payment will be only charged upon Seller’s acceptance (for both third party vendor and EGG Mall fulfillment) of your Order and formation of a Customer Contract. All payments shall be made to EGG Mall, either accepting payment in its own right or as Seller’s agent (where Seller is a Third Party Vendor). You acknowledge that EGG Mall is entitled to collect payments from you on behalf of Third Party Vendors.

        5.2     Additional terms: The payment methods may be subject to additional terms as prescribed by EGG Mall from time to time.

        5.3     Payment methods: You agree that you are subject to the applicable user agreement of your payment method. You may not claim against Seller or any of its agents (which may include EGG Mall), for any failure, disruption or error in connection with your chosen payment method. EGG Mall reserves the right at any time to modify or discontinue, temporarily or permanently, any payment method without notice to you or giving any reason.


        5.4     Invoicing: Seller may invoice you upon the due date of any payment under a Customer Contract. If the orders are confirmed by third party vendor to you then the invoice will be issued by third party vendor.

        5.5     Failure to pay: If the Customer fails to make any payment pursuant to the terms and conditions of the payment method elected or payment is cancelled for any reason whatsoever, then without prejudice to any other right or remedy available to Seller, Seller shall be entitled to cancel the Customer Contract or suspend delivery of the Products until payment is made in full.

        5.6     Refund of Payment:

        (a)     All refunds shall be processed, to the person who made the original payment via an approved payment method, through EGG Mall Wallet. The refund amount can be withdrawn from the EGG Mall Wallet account balance, by following the instructions set out in here. If the payment is done via online payment or Cash equivalent payment, the refund will be paid back to customer in the same way within 15 days deducting any fees charged by the bank or any other financial institute.

        (b)     We offer no guarantee of any nature for the timeliness of the refunds reaching your account. The processing of payment may take time and it is subject to the company’s internal processing timeline.

        (c)     All refunds are conditional upon our acceptance of a valid return of the Product.

        (d)     We reserve the right to modify the mechanism of processing refunds at any time without notice.

        <div class="bold">6.        Returns / Repair / Replacement</div>
        6.1     Return Policy: You may exercise the right to terminate the Customer Contract within 7 calendar days after having received the Product. To be eligible for a return, the condition of the Product must be in accordance with the instructions set out in the Return Policy at https://www.eggmalltech.com/en/returnPolicy.html. We, on behalf of the Seller, will submit your request to the Seller for their consideration on whether the return is acceptable.

        6.2     Permitted returns: Subject to Clause 6.1, within 14 days after the delivery of the Product, you may return the Product when you:

        6.2.1       receive the Product that is fundamentally different in nature from the Product specified in the Customer Contract; or the vehicle application information is incorrect based on the selected car model or VIN number or OE product information; or

        6.2.2       receive a faulty or damaged Product.
        For other process details, please refer to the Return Policy at . Shipping fee in your order are nonrefundable and you will be responsible for paying for your own costs for returning the Product. If you receive a refund, your own return costs will be excluded.

        We will inspect the Product and proceed with the refund within 15 days after you have exercised the rights to terminate the Customer Contract. You may contact our customer service by email: customerservice@whats-egg.com to check the status of your refund.

        6.3     Repair, replacement, discount: Subject to Clause 6.2, you may request us to repair the Product or replace the new Product. Your request cannot be withdrawn if we have already received your request, which means you cannot change your decision to return the Product thereafter. In case the Product is not repaired or replaced within a reasonable time, we, at our exclusive discretion, may provide you with a discount in proportion to the reduced value of the Product. However, in any case, the discount will not exceed 15% of the price of the Product in question. When a repair, replacement or discount has been provided, you shall not have any further claims against the Seller.

        6.4     When EGG Mall has provided the Customer a refund or replacement, the non-conforming Products or parts thereof shall become Seller’s property and upon request such Products or parts thereof should be shipped back to Seller at Customer’s cost.

        6.5     Risk of damage or loss: Risk of damage to or loss of the Products shall pass to the Customer at the time of delivery, or if the Customer wrongfully fails to take delivery of the Products at the time when Seller (via EGG Mall or EGG Mall’s agents) has tendered delivery of the Products.

        <div class="bold">7.        Questions and complaints</div>
        If you have any questions or complaints, please contact EGG Mall using the “Contact Us” page on the Platform or with EGG Mall hotline and line contacts. EGG Mall will liaise with Sellers on your questions and complaints.

        <div class="bold">8.        Termination</div>
        8.1     Cancellation by you: You may cancel the Customer Contract within 7 days upon receipt of the Goods by submitting a written notice to EGG Mall at https://eggmalltech.com.

        8.2     Cancellation by Seller: Without prejudice to any other right of termination elsewhere in these Terms & Conditions of Sale, Seller, or EGG Mall acting on Seller’s behalf, may stop any Products in transit, suspend further deliveries to the Customer and/or terminate the Customer Contract with immediate effect by written notice to the Customer on or at any time after the occurrence of any of the following events:

        8.2.1       the Products under the Customer Contract being unavailable for any reason;

        8.2.2       the Customer being in breach of an obligation under the Customer Contract;

        8.2.3       the Customer passing a resolution for its winding up or a court of competent jurisdiction making an order for the Customer’s winding up or dissolution;

        8.2.4       the making of an administration order in relation to the Customer or the appointment of a receiver over or an encumbrancer taking possession of or selling any of the Customer’s assets; or

        8.2.5       the Customer making an arrangement or composition with its creditors generally or applying to a Court of competent jurisdiction for protection from its creditors.

       <div class="bold">9.     Risk and property of the Goods</div>
        9.1     Risk of damage to or loss of the Goods shall pass to the Customer at the time of delivery or if the Customer wrongfully fails to take delivery of the Goods at the time when EGG Mall or Seller has tendered delivery of the Goods.

        9.2     Notwithstanding delivery and the passing of risk in the Goods or any other provision of these Conditions, the property in the Goods shall not pass to the Customer until EGG Mall has received in cash or cleared funds payment in full of the price of the Goods and all other goods agreed to be sold by Seller to the Customer for which payment is then due.

        9.3     Until such time as the property in the Goods passes to the Customer, the Customer shall hold the Goods as Seller's fiduciary agent and bailee and shall keep the Goods separate from those of the Customer.

        9.4     During the entire time the property of the Goods remains with Seller, the Customer agrees with Seller that the Customer shall immediately notify EGG Mall of any matter from time to time affecting Seller’s title to the Goods and the Customer shall provide EGG Mall with any information relating to the Goods as EGG Mall may reasonably require from time to time.

        9.5     Until such time as the property in the Goods passes to the Customer (and provided the Goods are still in existence and have not been resold) EGG Mall shall be entitled at any time to demand the Customer to deliver the Goods back to EGG Mall. In the event of non-compliance EGG Mall reserves it’s right to take legal action against the Customer for the delivery up the Goods and also reserves its right to seek damages and all other costs including but not limited to legal fees against the Customer.

        9.6     The Customer shall not be entitled to pledge or in any way charge by way of security for any indebtedness any of the Goods which remain the property of Seller but if the Customer does so all moneys owing by the Customer to Seller shall (without prejudice to any other right or remedy of Seller) forthwith become due and payable.

        9.7     If the provisions in Clause 9 are not effective according to the law of the country in which the Goods are located, the legal concept closest in nature to retention of title in that country shall be deemed to apply mutatis mutandis to give effect to the underlying intent expressed in this condition, and the Customer shall take all steps necessary to give effect to the same.

        9.8     The Customer shall indemnify Seller against all loss damages costs expenses and legal fees incurred by the Customer in connection with the assertion and enforcement of Seller's rights under this condition.

        <div class="bold">10.       Limitation of liability</div>
        10.1        Sole remedies of customer: the remedies set out in Clause 6 are the customer’s sole and exclusive remedies for non-conformity of or defects in the products.

        10.2        Maximum liability: notwithstanding any other provision of these terms & conditions of sale, seller’s maximum cumulative liability to you or to any other party for all losses under, arising out of or relating to the sale of products under each customer contract, will not exceed the sums that you have paid to seller under such customer contract.

        10.3        Exclusion of liability: Except for liability resulting from our wilful misconduct or gross negligence, EGG Mall indemnitees shall not be liable to you for any losses caused (regardless of the form of action) arising directly or indirectly in connection with: (i) amounts due from other users of the platform in connection with the purchase of any product; (ii) the sale of the products to you, or its use or resale by you; and (iii) any defect arising from fair wear and tear, wilful damage, misuse, negligence, accident, abnormal storage and or working conditions, alteration or modification of the products or failure to comply with seller’s instructions on the use of the products (whether oral or written).

        <div class="bold">11.       General</div>
        11.1        References to “EGG Mall”: References to “EGG Mall” in these Terms and Conditions of Sale apply both to EGG Mall’s actions on its own behalf as Seller and/or as the operator of the Platform or as the agent of Third Party Vendors as Sellers in respect of each and every Customer Contract.

        11.2        Right to subcontract: Third Party Vendors shall be entitled to delegate and/or subcontract any rights or obligations under these Terms & Conditions of Sale to EGG Mall or any of EGG Mall’s designated service providers, subcontractors and/or agents.

        11.3        Cumulative rights and remedies: Unless otherwise provided under these Terms & Conditions of Sale, the provisions of these Terms & Conditions of Sale and Seller’s rights and remedies under these Terms & Conditions of Sale are cumulative and are without prejudice and in addition to any rights or remedies Seller may have in law or in equity, and no exercise by Seller of any one right or remedy under these Terms & Conditions of Sale, or at law or in equity, shall (save to the extent, if any, provided expressly in these Terms & Conditions of Sale or at law or in equity) operate so as to hinder or prevent Seller’s exercise of any other such right or remedy as at law or in equity.

        11.4        No waiver: Seller’s failure to enforce these Terms & Conditions of Sale shall not constitute a waiver of these terms, and such failure shall not affect the right later to enforce these Terms & Conditions of Sale. Seller would still be entitled to use its rights and remedies in any other situation where you breach these Terms & Conditions of Sale.

        11.5        Severability: If at any time any provision of these Terms & Conditions of Sale shall be or shall become illegal, invalid or unenforceable in any respect, the legality, validity and enforceability of the remaining provisions of this Agreement shall not be affected or impaired thereby, and shall continue in force as if such illegal, invalid or unenforceable provision was severed from these Terms & Conditions of Sale.

        11.6        Rights of third parties: A person or entity who is not a party to these Terms & Conditions of Sale shall have no right under any legislation in any jurisdiction to enforce any term of these Terms & Conditions of Sale, regardless of whether such person or entity has been identified by name, as a member of a class or as answering a particular description. For the avoidance of doubt, nothing in this Clause shall affect the rights of any permitted assignee or transferee of these Terms & Conditions of Sale.

        11.7        Governing law: These Terms & Conditions of Sale shall be governed by, and construed in accordance with the laws of Thailand and you hereby submit to the exclusive jurisdiction of the Courts of Thailand.

        11.8        Except as provided for in Clause 11.7, any dispute, controversy or claim arising out of or relating to this contract, or the breach, termination or invalidity thereof may be settled by arbitration in accordance with the Rules for Arbitration of the Thai Arbitration Institute (TAI). The arbitral tribunal shall consist of a sole arbitrator, to be appointed by the Chairman of the TAI. The place of arbitration shall be Bangkok. Any award by the arbitration tribunal shall be final and binding upon the parties.

        11.9        Injunctive relief: Seller may seek immediate injunctive relief if Seller makes a good faith determination that a breach or non-performance is such that a temporary restraining order or other immediate injunctive relief is the only appropriate or adequate remedy.

        11.10       Amendments: Seller may by notice issued jointly with EGG Mall through the Platform or by such other method of notification as Seller may designate solely through EGG Mall (which may include notification by way of e-mail), vary the terms and conditions of these Terms & Conditions of Sale. Sellers will provide you with advance notice of material revisions to these Terms & Conditions of Sale. Sellers will not make revisions that have an immediate effect unless Sellers are legally required to do so or to protect you and other users of the Services. If you use the Platform or the Services after such effective date, you are deemed to have accepted such variation. If you do not accept the variation, you must stop access or using the Platform and the Services and terminate these Terms & Conditions of Sale. Seller’s right to vary these Terms & Conditions of Sale in the manner aforesaid will be exercised with the joint involvement of EGG Mall (either via the Portal or such other means as EGG Mall prescribes) and subject to the foregoing, may be exercised without the consent of any person or entity who is not a party to these Terms & Conditions of Sale. The version of Terms & Conditions of Sale applicable to any particular Order is the latest version in force.

        11.11       Correction of errors: Any typographical, clerical or other error or omission in any acceptance, invoice or other document on Seller’s part shall be subject to correction without any liability on Seller’s part.

        11.12       Currency: Money references under these Terms & Conditions of Sale shall be in Thai Baht.

        11.13       Language: In the event that these Terms & Conditions of Sale is executed or translated in any language other than English (“Foreign Language Version”), the English language version of these Terms & Conditions of Sale shall govern and shall take precedence over the Foreign Language Version.

        11.14       Entire agreement: These Terms & Conditions of Sale shall constitute the entire agreement between you and Seller relating to the subject matter hereof and supersedes and replaces in full all prior understandings, communications and agreements with respect to the subject matter hereof.

        11.15       Binding and conclusive: You acknowledge and agree that any records (including records of any telephone conversations relating to the Services, if any) maintained by Seller or its service providers relating to or in connection with the Platform and Services shall be binding and conclusive on you for all purposes whatsoever and shall be conclusive evidence of any information and/or data transmitted between Seller and you. You hereby agree that all such records are admissible in evidence and that you shall not challenge or dispute the admissibility, reliability, accuracy or the authenticity of such records merely on the basis that such records are in electronic form or are the output of a computer system, and you hereby waive any of your rights, if any, to so object.

        11.16       Subcontracting and delegation: EGG Mall reserves the right to delegate or subcontract the performance of any of its functions in connection with the performance of its obligations under these Terms & Conditions of Sale and reserves the right to use any service providers, subcontractors and/or agents on such terms as EGG Mall deems appropriate.

        11.17       Assignment: You may not assign your rights under these Terms & Conditions of Sale without Seller’s prior written consent. Seller may assign its rights under these Terms & Conditions of Sale to any third party.

        11.18       Force Majeure: Neither EGG Mall nor Seller shall be liable for non-performance, error, interruption or delay in the performance of its obligations under these Terms & Conditions of Sale (or any part thereof) or for any inaccuracy, unreliability or unsuitability of the Platform's and/or Services’ contents if this is due, in whole or in part, directly or indirectly to an event or failure which is beyond EGG Mall’s or Seller’s reasonable control.



        <h1>Terms & Conditions for Voucher</h1>
        1.      The following terms and conditions (T&Cs) apply to your use and redemption of promotional vouchers (Platform voucher and Shop voucher) or voucher codes distributed by EGG Mall
        2.      Vouchers are valid only on the EGG Mall website or mobile application.
        3.      In order to use the vouchers, voucher codes must be entered at the checkout page.
        4.      Voucher codes are to be used for a one time purchase only.
        5.      EGG Mall reserves the right to cancel or modify orders, or revoke the use of voucher codes, where the vouchers are used in the following circumstances:
        •suspicious or fraudulent voucher use;
        •voucher abuse, including vouchers redeemed using multiple accounts or multiple checkouts associated with the same customer or group of customers; and/ or
        •vouchers used in bad faith (including resold vouchers, or use of vouchers on orders made and intended for resale).

        6.      Unless otherwise stated, vouchers or other promotional discounts are not valid in conjunction with other tactical promotions or discounts, with the exception of pre-discounted products on EGG Mall.
        7.      Additional terms and conditions may be specified in relation to specific voucher codes (for example, duration, discount amount and products covered), and will govern the use and redemption of those vouchers in addition to these terms.
        8.      Vouchers can only be used within EGG Mall or single shop selected promotions. A Voucher is issued to customers based on the purchased and paid order, if customer will return or cancel the payment of the order, voucher may be withdrawn. A Voucher has limited use period, please refer to each promotion’s conditions. A Voucher cannot be used after expiry or exchanged for cash.
        9.      EGG Mall reserves the right to change these terms or cancel any promotions at any time without notice.

        <h1>Terms & Conditions for EGGPoints</h1>
        EGGpoints is a reward point provided by EGG Mall to eligible customers. EGG Mall reserve the right to announce the eligible group of customers from time to time.
        1.      Customer will receive EGGPoints if the order is placed within the promotion period and is paid and settled within the defined period.
        2.      The payable amount with VAT is used as the calculation base for the eligible EGGPoints.
        3.      All received EGGPoints are valid 180 calendar days after receipt; please redeem the points within 180 calendar days. The remaining EGGPoints can be checked in the account information.
        4.      The redemption should be made before the EGGPoint expires. The expired EGGPoints cannot be returned or refunded in any case.
        5.      The value of EGGPoints is subject to the decision of WhatsEGG (Thailand). WhatsEGG (Thailand) reserves the right to decide the value of EGGPoints, which will be announced from time to time.
        6.      In case the customers cancel or return the order after receiving EGGPoints, those points will be withdrawn. If the customers have redeemed the EGGPoints, the cancelled or returned order may be rejected based on WhatsEGG (Thailand)’s decision.
        7.      The EGGPoints can be redeemed for an EGG Mall voucher in accordance with the voucher T&C.
        8.      EGG Mall reserved the right to make the final interpretation of the rules of issuance of EGGPoints.
    </div>
</template>

<script>
export default {
  name:'terms-and-conditions'
}
</script>

<style scoped>
    .bold {
        font-weight: bold;
        color: black;
    }
    .underline {
        text-decoration: underline;
    }
</style>