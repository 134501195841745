<template>
    <div v-if="visible" class="captcha-verify-container">
        <iframe :src="`/app-h5/views/repair/pictureVerify.html?source=EGG_MALL_WEB&langCode=${$store.state.langCode}`" frameborder="0" class="captcha-iframe"></iframe>
    </div>
</template>

<script>
    export default {
        name: 'CaptchaVerify',
        props: {
            visible: {
                type: Boolean,
                default: false
            },
        },
        mounted() {
            window.addEventListener('message', this.handleMessage, false);
        },
        beforeDestroy() {
            window.removeEventListener('message', this.handleMessage)
        },
        methods: {
            handleMessage({ data }) {
                switch (data.event) {
                    case 'jmCaptchaCancel':
                        this.$emit('update:visible', false)
                        break;
                    case 'jmCaptchaSuccess':
                        this.$emit('update:visible', false)
                        this.$emit('success', data.data)
                        break;
                }
            }
        },
    }
</script>

<style scoped lang="less">
.captcha-verify-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 3000;
    background: #00000050;
    text-align: center;
}

.captcha-iframe {
    width: 500px;
    height: 100vh;
}
</style>
