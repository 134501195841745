



















































































































































    import {
        Vue, Component,
    } from 'vue-property-decorator'
    import {State} from 'vuex-class'
    import api from '~/api'
    import CommonDistricCascader from '~/components/common-district-cascader.vue'
    import utils from '~/utils'

@Component({
    components: {
        CommonDistricCascader,
    },
})
    export default class VerifyBox extends Vue {
    @State('userInfo') userInfo

    @State('verifyModalVisible') verifyModalVisible

    @State('token') token

    $refs!: {
        cascader: any,
        frontDoorUploader: any,
        nameCardUploader: any,
        verifyForm: any
    }

    verifyForm: any = {
        workshopName: '',
        completeDistrict: '',
        detailAddress: '',
        stateId: '',
        cityId: '',
        districtId: '',
        frontDoorUploader: '',
        nameCardUploader: '',
    }

    frontDoorUploader: any = {
        uploadUrl: '',
        showImgUrl: '',
    }

    nameCardUploader: any = {
        uploadUrl: '',
        showImgUrl: '',
    }

    getPoints: number = 0

    get verifyFormRules() {
        return {
            workshopName: [{ required: true, message: this.$t('label_workshop_name_placeholder_tip'), trigger: 'change' }],
            completeDistrict: [{ required: true, message: this.$t('label_district_tip'), trigger: 'change' }],
            detailAddress: [{ required: true, message: this.$t('label_detail_address_placeholder_tip'), trigger: 'change' }],
            frontDoorUploader: [{ required: true, message: this.$t('label_front_door_photo_tip'), trigger: 'change' }],
            nameCardUploader: [{ required: true, message: this.$t('label_name_card_photo_tip'), trigger: 'change' }],
        }
    }

    i18nReplace(s, o) {
        return utils.i18nReplace(s, o)
    }

    openDlg() {
        const data = <any> this.$options.data
        Object.assign(this.$data, data.call(this))
        this.$nextTick(() => {
            if (this.$refs.verifyForm) {
                this.$refs.verifyForm.clearValidate()
            }
        })
        if(this.token){
            api.get_points_behavior({type:11}).then((res)=>{
                this.getPoints = res.data
            })
        }
    }

    closeDlg() {
        this.$store.commit('SET_VERIFY_MODAL', false)
    }

    changeDistrict(path) {
        this.verifyForm.stateId = path[0]
        this.verifyForm.cityId = path[1]
        this.verifyForm.districtId = path[2]
    }

    submitVerify() {
        this.$refs.verifyForm.validate(valid => {
            if (valid) {
                 let params = {
                    shopDoorPhoto: this.frontDoorUploader.showImgUrl,
                    businessLicensePhoto: this.nameCardUploader.showImgUrl,
                    shopName: this.verifyForm.workshopName,
                    address: this.verifyForm.detailAddress,
                    stateId: this.verifyForm.stateId,
                    cityId: this.verifyForm.cityId,
                    districtId: this.verifyForm.districtId,
                }
                api.workshop_verify_post(params).then(data => {
                    this.$store.commit('SET_LOGIN_USERINFO', { loginApiVerified: 0 })
                    this.$store.commit('SET_VERIFY_MODAL', false)
                })
            }
        })
    }

    httpRequest(options, type) {
        let fileTypeStatus
        const file = options.file
        const fileName = options.file.name
        const dotIndex: number = fileName.lastIndexOf('.')
        const FILE_TYPE: string = fileName.substr(dotIndex + 1).toLowerCase()

        // 判断文件类型(excel、pdf、图片)
        if (FILE_TYPE === 'png' || FILE_TYPE === 'jpeg' || FILE_TYPE === 'jpg') {
            fileTypeStatus = true
        } else {
            fileTypeStatus = false
        }

        // 判断文件大小
        const fileSizeStatus = file.size / 1024 / 1024 <= 10

        if (!fileTypeStatus) {
            this.$message.error(this.$t('label_file_format_error'))
        }

        if (!fileSizeStatus) {
            this.$message.error(this.$t('label_file_oversized_error'))
        }

        if (fileSizeStatus && fileTypeStatus) {
            let fileParam = new FormData()
            fileParam.append('fileName', options.file.name)
            fileParam.append('file', options.file)
            api.fileUrl_upload(fileParam).then(res => {
                this[type].showImgUrl = this.$dealImgUrl(res.data)
                this.verifyForm[type] = this[type].showImgUrl
                this.$nextTick(() => {
                    this.$refs.verifyForm.clearValidate(type)
                })
                this.$message.success(this.$t('label_file_upload_success'))
            }).catch(() => {
                this.$message.error(this.$t('label_file_format_error'))
            })
        }
    }
    }

