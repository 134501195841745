<template>
	<div>
		<p style="margin-bottom:5.0000pt;text-align:center;background:rgb(255,255,255);"><strong><span style="font-family:Helvetica;color:rgb(56,56,56);font-weight:bold;font-size:16px;">Return Policy V.1.</span></strong><strong><span style="font-family:Helvetica;color:rgb(56,56,56);font-weight:bold;font-size:16px;">5</span></strong></p>
		<p style="margin-bottom:5.0000pt;margin-left:450px;text-indent:36.0000pt;background:rgb(255,255,255);"><span style="font-family:'PingFang SC';color:rgb(166,166,166);font-size:16px;">Updated</span><span style="font-family:Tahoma;color:rgb(166,166,166);font-size:16px;">:</span><span style="font-family:Calibri;color:rgb(166,166,166);font-size:16px;">23-08-2022</span></p>
		<p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;">We believe you would have satisfaction of products you purchase from the EGG Mall. However, that sometimes you may changed your mind, In that unlikely event, we invite you to review the following terms related to returning a&nbsp;product.</span></p>
		<p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;">You have 7 calendar days to return an item from the date you received it. To be eligible for a&nbsp;return, your item must be unused and in the same condition that you received it. We, EGG Mall, on behalf of the supplier, will notice this request to product&rsquo;s original supplier whether the return is acceptable. If yes, please ensure following conditions :</span></p>
		<ul style="list-style-type: undefined;margin-left:26px;">
			<li><span style="font-family:Symbol;color:rgb(56,56,56);font-size:16px;color:rgb(56,56,56);">&middot;&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;color:rgb(56,56,56);">Your item must be in the original packaging.</span></li>
			<li><span style="font-family:Symbol;color:rgb(56,56,56);font-size:16px;color:rgb(56,56,56);">&middot;&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;color:rgb(56,56,56);">Your item is unused and in new condition.</span></li>
			<li><span style="font-family:Symbol;color:rgb(56,56,56);font-size:16px;color:rgb(56,56,56);">&middot;&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;color:rgb(56,56,56);">Your item needs to have the delivery note or receipt&nbsp;</span><span style="font-family:'Times New Roman';color:rgb(56,56,56);font-size:16px;color:rgb(56,56,56);">o</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;color:rgb(56,56,56);">r invoice</span></li>
		</ul>
		<p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;">Shipping&nbsp;fee in your order&nbsp;are nonrefundable and you will be responsible for paying for your own service costs for returning your item.&nbsp;If you receive a refund, the cost of service will be excluded.</span></p>
		<p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;">Once we receive your item, we will inspect&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;">before</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;">&nbsp;returning it to original supplier within 3 working days. Once approved, we will provide you a return approval form. Please attach this form into your return shipment.&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;">&nbsp;</span><span style="font-family:Helvetica;color:rgb(255,0,0);font-size:16px;"><br></span><span style="font-family:Helvetica;color:rgb(255,0,0);font-size:16px;"><br></span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;">After we received the return shipment, we will inspect and refund within 7 working days.&nbsp;</span></p>
		<p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;">Please notice that some product is not-returnable by original supplier, please ensure your purchase before confirmation. For any backorder items</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;">&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;">from suppliers</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:16px;">, we request customer to pay full amount in advance and the order cannot be return, cancel and not refundable.</span></p>
		<p><span style="font-family:Calibri;font-size:15px;">&nbsp;</span></p>
	</div>
</template>

<script>
export default {
  name:'return-policy'
}
</script>

<style>

</style>