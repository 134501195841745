import CryptoJS from 'crypto-js'

// DES加密
export function encryptPassword(message) {
    let key = 'WhatsEGG-CUSTOMER'
    let keyHex = CryptoJS.enc.Utf8.parse(key)
    let option = {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7}
    let encrypted = CryptoJS.DES.encrypt(message, keyHex, option)
    return encrypted.toString()
}
