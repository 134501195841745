







































    import {
        Vue, Component,
    } from 'vue-property-decorator'
    import {State} from 'vuex-class'
    import api from '~/api'

@Component
    export default class VerifyBox extends Vue {
    @State('guideVerifyModalVisible') guideVerifyModalVisible

    mounted() {
    }

    closeDlg() {
        this.$store.commit('SET_GUIDE_VERIFY_MODAL', false)
        window.localStorage.setItem('guideVerifyBox', 'hide')
    }

    gotoVerify() {
        this.$store.commit('SET_GUIDE_VERIFY_MODAL', false)
        this.$store.commit('SET_VERIFY_MODAL', true)
        window.localStorage.setItem('guideVerifyBox', 'hide')
    }

    rejectShow() {
        this.$store.commit('SET_GUIDE_VERIFY_MODAL', false)
        window.localStorage.setItem('guideVerifyBox', 'hide')
        api.update_log_guide_verify();
    }
    }
