<template>
    <div>
        <p style="margin-bottom:5.0000pt;text-align:center;background:rgb(255,255,255);"><strong><u><span style="font-family:Helvetica;color:rgb(56,56,56);font-weight:bold;text-decoration:underline;font-size:12px;">Shipping &nbsp;Policy</span></u></strong><strong><u><span style="font-family:Helvetica;color:rgb(56,56,56);font-weight:bold;text-decoration:underline;font-size:12px;">&nbsp;</span></u></strong><strong><u><span style="font-family:Helvetica;color:rgb(56,56,56);font-weight:bold;text-decoration:underline;font-size:12px;">V.1.7</span></u></strong></p>
        <p style="margin-bottom:5.0000pt;text-align:center;background:rgb(255,255,255);"><span style="font-family:'PingFang SC';color:rgb(166,166,166);font-size:12px;">Updated</span><span style="font-family:Tahoma;color:rgb(166,166,166);font-size:12px;">:&nbsp;</span><span style="font-family:Calibri;color:rgb(166,166,166);font-size:12px;">03/04/2023</span></p>
        <p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;">You acknowledge that delivery of the Products is subject to availability of the Products. Seller will make every reasonable effort to deliver the Product to you within the delivery timeframe stated on the relevant page on which the Product is listed, but you acknowledge that while stock information on the Platform is updated regularly, it is possible that in some instances a Product may become unavailable between updates. All delivery timeframes given are estimates only and delays can occur. If the delivery of your Product is delayed Seller will inform you accordingly via e-mail and your Product will be dispatched as soon as it becomes available to Seller. The time for delivery shall not be of the essence, and Seller (nor any of its agents) shall not be liable for any delay in delivery howsoever caused.</span></p>
        <p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><strong><em><span style="font-family:Helvetica;color:rgb(56,56,56);font-weight:bold;font-style:italic;font-size:12px;">1. Shipping fee and Delivery time&nbsp;</span></em></strong><strong><em><u><span style="font-family:Helvetica;color:rgb(56,56,56);font-weight:bold;text-decoration:underline;font-style:italic;font-size:12px;">within Bangkok, Pathum Thani, Nonthaburi, Samut Prakan, and Samut Sakhon</span></u></em></strong></p>
        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:36.0000pt;background:rgb(255,255,255);"><strong><span style="font-family:Helvetica;color:rgb(56,56,56);font-weight:bold;font-size:12px;">Normal delivery</span></strong></p>
        <ul class="decimal_type" style="list-style-type: undefined;margin-left:26px;">
            <li><span style="font-family:Symbol;font-size:13px;font-size:10.0000pt;">&middot;&nbsp;</span><span style="font-family:Helvetica;font-size:12px;font-size:9.0000pt;">Free shipping if order payable amount reach THB 10,000 including VAT in one order</span></li>
            <li><span style="font-family:Symbol;font-size:13px;font-size:10.0000pt;">&middot;&nbsp;</span><span style="font-family:Helvetica;font-size:12px;font-size:9.0000pt;">Actual shipping fee will be calculated base on weight and volume with minimum THB 149 will be applied if order payable amount is less than THB 10,000 including VAT.</span></li>
            <li><span style="font-family:Symbol;font-size:13px;font-size:10.0000pt;">&middot;&nbsp;</span><span style="font-family:Helvetica;font-size:12px;font-size:9.0000pt;">Delivery time will be next working day (D+1) if placing order during 09.00 &ndash; 16.00 Hrs. (Mon &ndash; Sat)</span></li>
            <li><span style="font-family:Symbol;font-size:13px;font-size:10.0000pt;">&middot;&nbsp;</span><span style="font-family:Helvetica;font-size:12px;font-size:9.0000pt;">Delivery time will be 2 working day (D+2) if placing order after 16.00 Hrs. (Mon &ndash; Sat)</span></li>
            <li><span style="font-family:Symbol;font-size:13px;font-size:10.0000pt;">&middot;&nbsp;</span><span style="font-family:Helvetica;font-size:12px;font-size:9.0000pt;">Shipping invoice upon request and please select in app when you placed the order</span></li>
        </ul>
        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:36.0000pt;background:rgb(255,255,255);"><strong><span style="font-family:Helvetica;font-weight:bold;font-size:12px;">Faster delivery</span></strong></p>
        <ul class="decimal_type" style="list-style-type: undefined;margin-left:26px;">
            <li><span style="font-family:Symbol;font-size:13px;font-size:10.0000pt;">&middot;&nbsp;</span><span style="font-family:Helvetica;font-size:12px;font-size:9.0000pt;">Additional charge of THB50 from&nbsp;</span><strong><span style="font-family:Helvetica;font-weight:bold;font-size:12px;font-size:9.0000pt;">Normal Delivery</span></strong><span style="font-family:Helvetica;font-size:12px;font-size:9.0000pt;">&nbsp;rate will be applied.</span></li>
            <li><span style="font-family:Symbol;font-size:13px;font-size:10.0000pt;">&middot;&nbsp;</span><span style="font-family:Helvetica;font-size:12px;font-size:9.0000pt;">Delivery time will be next working day (D+1) before 12.00 Hrs., if order confirmed before 14.00 Hrs. (Mon &ndash; Sat)</span></li>
        </ul>
        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:36.0000pt;background:rgb(255,255,255);"><strong><span style="font-family:Helvetica;font-weight:bold;font-size:12px;">Budget delivery</span></strong></p>
        <ul class="decimal_type" style="list-style-type: undefined;margin-left:26px;">
            <li><span style="font-family:Symbol;font-size:13px;font-size:10.0000pt;">&middot;&nbsp;</span><span style="font-family:Helvetica;font-size:12px;font-size:9.0000pt;">Specific Shipping fee will be applied once buyer select this option and select payment before delivery.</span></li>
            <li><span style="font-family:Symbol;font-size:13px;font-size:10.0000pt;">&middot;&nbsp;</span><span style="font-family:Helvetica;font-size:12px;font-size:9.0000pt;">Delivery time will be within 5 working day (D+4) if placing order during 09.00 &ndash; 16.00 Hrs. (Mon-Sat)</span></li>
            <li><span style="font-family:Symbol;font-size:13px;font-size:10.0000pt;">&middot;&nbsp;</span><span style="font-family:Helvetica;font-size:12px;font-size:9.0000pt;">Shipping fee will be as below table :</span></li>
        </ul>
        <table style="border-collapse:collapse;margin-left:18.0000pt;border:none;">
            <tbody>
                <tr>
                    <td style="width: 143.8pt;padding: 0pt 5.4pt;border-width: 1pt;border-style: solid;border-color: windowtext;vertical-align: top;">
                        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;"><span style="font-family:Helvetica;font-size:12px;">Order Value per shop &nbsp;per order (THB)</span></p>
                    </td>
                    <td style="width: 143.85pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: 1pt solid windowtext;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;"><span style="font-family:Helvetica;font-size:12px;">Shipping Fee (THB)</span></p>
                    </td>
                    <td style="width: 143.85pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: 1pt solid windowtext;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;"><span style="font-family:Helvetica;font-size:12px;">Remark</span></p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 143.8pt;padding: 0pt 5.4pt;border-left: 1pt solid windowtext;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;"><span style="font-family:Helvetica;font-size:12px;">&lt;10,000</span></p>
                    </td>
                    <td style="width: 143.85pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;"><span style="font-family:Helvetica;font-size:12px;">149</span></p>
                    </td>
                    <td style="width: 143.85pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;"><span style="font-family:Helvetica;font-size:12px;">Payment before delivery</span></p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 143.8pt;padding: 0pt 5.4pt;border-left: 1pt solid windowtext;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;"><span style="font-family:Helvetica;font-size:12px;">10,000-29,999</span></p>
                    </td>
                    <td style="width: 143.85pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;"><span style="font-family:Helvetica;font-size:12px;">199</span></p>
                    </td>
                    <td style="width: 143.85pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;"><span style="font-family:Helvetica;font-size:12px;">Payment before delivery</span></p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 143.8pt;padding: 0pt 5.4pt;border-left: 1pt solid windowtext;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;"><span style="font-family:Helvetica;font-size:12px;">30,000-99,999</span></p>
                    </td>
                    <td style="width: 143.85pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;"><span style="font-family:Helvetica;font-size:12px;">349</span></p>
                    </td>
                    <td style="width: 143.85pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;"><span style="font-family:Helvetica;font-size:12px;">Payment before delivery</span></p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 143.8pt;padding: 0pt 5.4pt;border-left: 1pt solid windowtext;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;"><span style="font-family:Helvetica;font-size:12px;">&gt;100,000</span></p>
                    </td>
                    <td style="width: 143.85pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;"><span style="font-family:Helvetica;font-size:12px;">499</span></p>
                    </td>
                    <td style="width: 143.85pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;"><span style="font-family:Helvetica;font-size:12px;">Payment before delivery</span></p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:18.0000pt;background:rgb(255,255,255);"><span style="font-family:Helvetica;color:rgb(255,0,0);font-size:12px;">&nbsp;</span></p>
        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;background:rgb(255,255,255);"><span style="font-family:Helvetica;font-size:12px;">&nbsp;</span></p>
        <p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><strong><em><span style="font-family:Helvetica;font-weight:bold;font-style:italic;font-size:12px;">2. Shipping fee and Delivery time in Up-Country and Special Zone (Please see attachment)&nbsp;</span></em></strong></p>
        <p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><strong><span style="font-family:Helvetica;font-weight:bold;font-size:12px;">2.1 Customer choose service provider from WhatsEGG</span></strong></p>
        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:36.0000pt;background:rgb(255,255,255);"><strong><span style="font-family:Helvetica;font-weight:bold;font-size:12px;">Normal delivery to Up-country&nbsp;</span></strong></p>
        <ul class="decimal_type" style="list-style-type: undefined;margin-left:26px;">
            <li><span style="font-family:Symbol;font-size:13px;font-size:10.0000pt;">&middot;&nbsp;</span><span style="font-family:Helvetica;font-size:12px;font-size:9.0000pt;">Actual shipping fee will be calculated base on weight and volume with minimum THB 149</span></li>
            <li><span style="font-family:Symbol;color:rgb(56,56,56);font-size:13px;font-size:10.0000pt;color:rgb(56,56,56);">&middot;&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;font-size:9.0000pt;color:rgb(56,56,56);">Delivery time will be 2 working day (D+2)&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;font-size:9.0000pt;color:rgb(56,56,56);">if placing order during 09.00 &ndash; 16.00 Hrs. (Mon &ndash; Sat)</span></li>
            <li><span style="font-family:Symbol;color:rgb(56,56,56);font-size:13px;font-size:10.0000pt;color:rgb(56,56,56);">&middot;&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;font-size:9.0000pt;color:rgb(56,56,56);">Delivery time will be 3 working day (D+3)&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;font-size:9.0000pt;color:rgb(56,56,56);">if placing order&nbsp;</span><span style="font-family:Helvetica;font-size:12px;font-size:9.0000pt;">out of&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;font-size:9.0000pt;color:rgb(56,56,56);">09.00 &ndash; 16.00 Hrs. (Mon &ndash; Sat)</span></li>
            <li><span style="font-family:Symbol;color:rgb(56,56,56);font-size:13px;font-size:10.0000pt;color:rgb(56,56,56);">&middot;&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;font-size:9.0000pt;color:rgb(56,56,56);">Shipping invoice upon request and please select in app when you place the order&nbsp;</span></li>
        </ul>
        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:36.0000pt;background:rgb(255,255,255);"><strong><span style="font-family:Helvetica;color:rgb(56,56,56);font-weight:bold;font-size:12px;">Normal Delivery to Special Zone (**)</span></strong></p>
        <ul class="decimal_type" style="list-style-type: undefined;margin-left:26px;">
            <li><span style="font-family:Symbol;color:rgb(56,56,56);font-size:13px;font-size:10.0000pt;color:rgb(56,56,56);">&middot;&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;font-size:9.0000pt;color:rgb(56,56,56);">Actual shipping fee will be calculated base on weight and volume with minimum THB 149</span></li>
            <li><span style="font-family:Symbol;color:rgb(56,56,56);font-size:13px;font-size:10.0000pt;color:rgb(56,56,56);">&middot;&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;font-size:9.0000pt;color:rgb(56,56,56);">Delivery time may take longer than 3 working day (D&gt;3)</span></li>
            <li><span style="font-family:Symbol;color:rgb(56,56,56);font-size:13px;font-size:10.0000pt;color:rgb(56,56,56);">&middot;&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;font-size:9.0000pt;color:rgb(56,56,56);">Shipping invoice upon request and please select in app when you place the order&nbsp;</span></li>
        </ul>
        <p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><strong><span style="font-family:Helvetica;color:rgb(56,56,56);font-weight:bold;font-size:12px;">2.2 Customer choose to use own selected 3PL</span></strong></p>
        <ul style="list-style-type: undefined;margin-left:26px;">
            <li><span style="font-family:Symbol;color:rgb(56,56,56);font-size:13px;font-size:10.0000pt;color:rgb(56,56,56);">&middot;&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;font-size:9.0000pt;color:rgb(56,56,56);">Actual shipping fee will be calculated base on weight and volume with minimum THB 149</span></li>
            <li><span style="font-family:Symbol;color:rgb(56,56,56);font-size:13px;font-size:10.0000pt;color:rgb(56,56,56);">&middot;&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;font-size:9.0000pt;color:rgb(56,56,56);">Additional Shipping fee charged by 3PL direct to buyer will also be applied.&nbsp;</span><span style="font-family:Helvetica;font-size:12px;font-size:9.0000pt;">Customer will pay shipping fee from 3PL location to their consignee address directly to 3PL</span></li>
            <li><span style="font-family:Symbol;font-size:13px;font-size:10.0000pt;">&middot;&nbsp;</span><span style="font-family:Helvetica;font-size:12px;font-size:9.0000pt;">Delivery time to 3PL will be 2-3 working days after order is placed and goods are available</span></li>
            <li><span style="font-family:Symbol;font-size:13px;font-size:10.0000pt;">&middot;&nbsp;</span><span style="font-family:Helvetica;font-size:12px;font-size:9.0000pt;">WhatsEGG will not responsible for any lost, damage of the goods that may happen during shipping</span></li>
            <li><span style="font-family:Symbol;font-size:13px;font-size:10.0000pt;">&middot;&nbsp;</span><span style="font-family:Helvetica;font-size:12px;font-size:9.0000pt;">After order shipped, customer cannot exchange, return the goods and cannot be refund</span></li>
        </ul>
        <p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><strong><span style="font-family:Helvetica;color:rgb(56,56,56);font-weight:bold;font-size:12px;">&nbsp;</span></strong></p>
        <p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><strong><em><span style="font-family:Helvetica;color:rgb(56,56,56);font-weight:bold;font-style:italic;font-size:12px;">3. If I have multiple delivery address, what should I do?</span></em></strong></p>
        <ul style="list-style-type: undefined;margin-left:26px;">
            <li><span style="font-family:Symbol;color:rgb(56,56,56);font-size:13px;font-size:10.0000pt;color:rgb(56,56,56);">&middot;&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;font-size:9.0000pt;color:rgb(56,56,56);">You can request another delivery address to WhatsEGG (Thailand) to create for you and provide the documents to show your relationship with this address, and you can select different address for each order. The invoice address will always be the 1</span><sup><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;vertical-align:super;font-size:9.0000pt;color:rgb(56,56,56);">st</span></sup><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;font-size:9.0000pt;color:rgb(56,56,56);">&nbsp;registered address&nbsp;</span></li>
        </ul>
        <p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><strong><em><span style="font-family:Helvetica;color:rgb(56,56,56);font-weight:bold;font-style:italic;font-size:12px;">4. Others</span></em></strong></p>
        <ul class="decimal_type" style="list-style-type: undefined;margin-left:26px;">
            <li><span style="font-family:Symbol;color:rgb(56,56,56);font-size:13px;font-size:10.0000pt;color:rgb(56,56,56);">&middot;&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;font-size:9.0000pt;color:rgb(56,56,56);">Shipping Fee will be paid together to EGGMall once order is placed (unless ship by seller)</span></li>
            <li><span style="font-family:Symbol;color:rgb(56,56,56);font-size:13px;font-size:10.0000pt;color:rgb(56,56,56);">&middot;&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;font-size:9.0000pt;color:rgb(56,56,56);">Shipping Fee invoice will be provided together with the goods delivery if you have selected option &ldquo;request invoice&rdquo; in order.&nbsp;</span></li>
            <li><span style="font-family:Symbol;color:rgb(56,56,56);font-size:13px;font-size:10.0000pt;color:rgb(56,56,56);">&middot;&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;font-size:9.0000pt;color:rgb(56,56,56);">EGGMall may use the other 3PL on behalf of EGGMall to do the shipping with solo discretion&nbsp;</span></li>
            <li><span style="font-family:Symbol;color:rgb(56,56,56);font-size:13px;font-size:10.0000pt;color:rgb(56,56,56);">&middot;&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;font-size:9.0000pt;color:rgb(56,56,56);">Any order out of the working hour&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;font-size:9.0000pt;color:rgb(56,56,56);">0</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;font-size:9.0000pt;color:rgb(56,56,56);">9.00-16.00 Hrs. will be treated as order placed in the next valid working day. For more detail delivery time, you can contact EGGMall Customer service via Chat or hotline 02-026-3298</span></li>
        </ul>
        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;background:rgb(255,255,255);"><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;">&nbsp;</span></p>
        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;background:rgb(255,255,255);"><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;">&nbsp;</span></p>
        <p style="margin-top:5.0000pt;margin-bottom:5.0000pt;background:rgb(255,255,255);"><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;">&nbsp;</span></p>
        <p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><strong><em><span style="font-family:Helvetica;color:rgb(56,56,56);font-weight:bold;font-style:italic;font-size:12px;">Fulfill by other Shops&nbsp;</span></em></strong></p>
        <ul style="list-style-type: undefined;margin-left:26px;">
            <li><span style="font-family:Symbol;color:rgb(56,56,56);font-size:13px;font-size:10.0000pt;color:rgb(56,56,56);">&middot;&nbsp;</span><span style="font-family:Helvetica;color:rgb(56,56,56);font-size:12px;font-size:9.0000pt;color:rgb(56,56,56);">If delivery by seller, please contact the seller for detail delivery time&nbsp;</span></li>
        </ul>

        <p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><strong><em><span style="font-family:Helvetica;font-weight:bold;font-style:italic;font-size:12px;">Definition&nbsp;</span></em></strong><strong><em><span style="font-family:Helvetica;font-weight:bold;font-style:italic;font-size:12px;">and Shipping time</span></em></strong><strong><em><span style="font-family:Helvetica;font-weight:bold;font-style:italic;font-size:12px;">, D, D+1, D+2, D+3</span></em></strong></p>
        <table style="border-collapse:collapse;width:438.4000pt;border:none;">
            <tbody>
                <tr>
                    <td style="width: 91.9pt;padding: 0pt 5.4pt;border-width: 1pt;border-style: solid;border-color: windowtext;vertical-align: top;">
                        <p style="margin-bottom:5.0000pt;"><strong><span style="font-family:Helvetica;font-weight:bold;font-size:12px;">Working Day</span></strong></p>
                    </td>
                    <td style="width: 70.9pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: 1pt solid windowtext;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-bottom:5.0000pt;"><strong><span style="font-family:Helvetica;font-weight:bold;font-size:12px;">Monday</span></strong></p>
                    </td>
                    <td style="width: 70.85pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: 1pt solid windowtext;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-bottom:5.0000pt;"><strong><span style="font-family:Helvetica;font-weight:bold;font-size:12px;">Tuesday</span></strong></p>
                    </td>
                    <td style="width: 58.55pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: 1pt solid windowtext;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-bottom:5.0000pt;"><strong><span style="font-family:Helvetica;font-weight:bold;font-size:12px;">Wednesday</span></strong></p>
                    </td>
                    <td style="width: 73.1pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: 1pt solid windowtext;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-bottom:5.0000pt;"><strong><span style="font-family:Helvetica;font-weight:bold;font-size:12px;">Thursday</span></strong></p>
                    </td>
                    <td style="width: 73.1pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: 1pt solid windowtext;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-bottom:5.0000pt;"><strong><span style="font-family:Helvetica;font-weight:bold;font-size:12px;">Friday</span></strong></p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 91.9pt;padding: 0pt 5.4pt;border-left: 1pt solid windowtext;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-bottom:5.0000pt;"><strong><span style="font-family:Helvetica;font-weight:bold;font-size:11px;">Order before</span></strong><strong><span style="font-family:Helvetica;font-weight:bold;font-size:11px;">&nbsp;</span></strong><strong><span style="font-family:Helvetica;font-weight:bold;font-size:11px;">4.00pm</span></strong></p>
                    </td>
                    <td style="width: 70.9pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-bottom:5.0000pt;"><strong><span style="font-family:Helvetica;font-weight:bold;font-size:12px;">Order Day</span></strong><strong><span style="font-family:Helvetica;font-weight:bold;font-size:12px;">&nbsp;</span></strong><strong><span style="font-family:Helvetica;font-weight:bold;font-size:12px;">(D)</span></strong></p>
                    </td>
                    <td style="width: 70.85pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-bottom:5.0000pt;"><strong><span style="font-family:Helvetica;font-weight:bold;font-size:12px;">D+1</span></strong></p>
                    </td>
                    <td style="width: 58.55pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-bottom:5.0000pt;"><strong><span style="font-family:Helvetica;font-weight:bold;font-size:12px;">D+2</span></strong></p>
                    </td>
                    <td style="width: 73.1pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-bottom:5.0000pt;"><strong><span style="font-family:Helvetica;font-weight:bold;font-size:12px;">D+3</span></strong></p>
                    </td>
                    <td style="width: 73.1pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-bottom:5.0000pt;"><strong><span style="font-family:Helvetica;font-weight:bold;font-size:12px;">D+4</span></strong></p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 91.9pt;padding: 0pt 5.4pt;border-left: 1pt solid windowtext;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-bottom:5.0000pt;"><strong><span style="font-family:Helvetica;font-weight:bold;font-size:11px;">Order after 4.00pm</span></strong></p>
                    </td>
                    <td style="width: 70.9pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-bottom:5.0000pt;"><strong><span style="font-family:等线;font-weight:bold;font-size:12px;">N</span></strong><strong><span style="font-family:等线;font-weight:bold;font-size:12px;">/</span></strong><strong><span style="font-family:等线;font-weight:bold;font-size:12px;">A</span></strong></p>
                    </td>
                    <td style="width: 70.85pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-bottom:5.0000pt;"><strong><span style="font-family:等线;font-weight:bold;font-size:12px;">Order Day (D)</span></strong></p>
                    </td>
                    <td style="width: 58.55pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-bottom:5.0000pt;"><strong><span style="font-family:Helvetica;font-weight:bold;font-size:12px;">D+1</span></strong></p>
                    </td>
                    <td style="width: 73.1pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-bottom:5.0000pt;"><strong><span style="font-family:Helvetica;font-weight:bold;font-size:12px;">D+2</span></strong></p>
                    </td>
                    <td style="width: 73.1pt;padding: 0pt 5.4pt;border-left: none;border-right: 1pt solid windowtext;border-top: none;border-bottom: 1pt solid windowtext;vertical-align: top;">
                        <p style="margin-bottom:5.0000pt;"><strong><span style="font-family:Helvetica;font-weight:bold;font-size:12px;">D+3</span></strong></p>
                    </td>
                </tr>
            </tbody>
        </table>
            <p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><strong><em><span style="font-family:Helvetica;color:rgb(56,56,56);font-weight:bold;font-style:italic;font-size:12px;">&nbsp;</span></em></strong></p>
            <p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><strong><span style="font-family:Helvetica;color:rgb(56,56,56);font-weight:bold;font-size:12px;">Definition: 3PL means 3rd party logistic company</span></strong></p>
            <p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><strong><span style="font-family:Helvetica;color:rgb(255,0,0);font-weight:bold;font-size:12px;">**Attached for Special zone&nbsp;</span></strong></p>
            <p style="margin-bottom:5.0000pt;background:rgb(255,255,255);"><strong><span style="font-family:Helvetica;color:rgb(56,56,56);font-weight:bold;font-size:12px;">&nbsp;</span></strong></p>
            <p><span style="font-family:Calibri;font-size:15px;">&nbsp;</span></p>
        <img src="~assets/images/shipping-policy.png" alt="policy" width="880px">
    </div>
</template>

<script>
export default {
  name:'shipping-policy'
}
</script>