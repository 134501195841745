import Cookies from 'js-cookie'

const COUNTRY_ID = 'countryId'
const COUNTRY_CODE = 'countryCode'
const LANG_CODE = 'langCode'
const PRICE_ID = 'priceId'
const TOKEN = 'token'

export default {
    setCountryInfo(data: any): void {
        if (!data.countryId) {
            return
        }
        Cookies.set(COUNTRY_ID, data.countryId)
        Cookies.set(COUNTRY_CODE, data.countryCode)
    },
    setToken(token: string): void {
        Cookies.set(TOKEN, token)
    },
    setPriceId(priceId: number): void {
        Cookies.set(PRICE_ID, priceId)
    },
    getToken() {
        return Cookies.get(TOKEN)
    },
    getPriceId() {
        return Cookies.get(PRICE_ID)
    },
    getCountryInfo() {
        return {
            countryId: +Cookies.get(COUNTRY_ID),
            countryCode: Cookies.get(COUNTRY_CODE),
        }
    },
    removeAccountInfo(): void {
        Cookies.remove(PRICE_ID)
        Cookies.remove(TOKEN)
    },
}
