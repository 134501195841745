// api request method
export const method = {
    Get: 'get',
    Post: 'post',
    Put: 'put',
    Delete: 'delete',
}

// api url env type
export const envType = {
    Development: 'dev',
    Production: 'rtm',
    uat: 'uat',
    Static: 'static',
}
