
















































    import {
        Vue, Component,
    } from 'vue-property-decorator'
    import api from '~/api'
    import utils from '~/utils'

    const fn = utils.debounce(function () {
        api.get_oe_brandsku({
            keyword: this.oeNoBrandSKUInput,
        }).then(res => {
            this.historyList = (res && res.data && res.data.map(list => list.fieldHighLight)) || []
        }).catch(err => {
            console.log('test', err)
        })
    }, 300)
@Component
    export default class SearchOENoBrandSKUBox extends Vue {
        $refs!: {
            oeNoBrandSKUInput: any
        }

        oeNoBrandSKUInput: string = ''

        historyData: any[] = []

        historyList: any[] = []

        init() {
            this.getSearchHistory()
        }

        getSearchHistory() {
            api.get_search_history({
                searchType: 4,
            }).then(res => {
                this.historyData = (res && res.data) || []
            })
        }

        searchByOENoBrandSKU(value) {
            // 用正则处理
            const msg = value.replace(/<\/?[^>]*>/g, '')

            let keywords = msg.trim()
            if (!keywords || keywords.length < 2) {
                this.$refs.oeNoBrandSKUInput.focus()
                this.$message({
                    customClass: 'common-customized-tip-message',
                    showClose: false,
                    message: this.$t('label_search_product_msg'),
                    type: 'warning',
                    offset: 155,
                })
                return
            }
            utils.fbq('track', 'Search', {
                search_string: keywords,
            })
            let metaParams = {
                keywords,
            }
            window.localStorage.setItem('searchResultNeedBatchSave', JSON.stringify(true));
            this.$router.push(this.localePath({
                name: 'multiConditionSearchPage',
                query: {
                    searchResultInfo: JSON.stringify(metaParams),
                    oeNumberOrBrandSku: keywords,
                    isAccurateSearch: true,
                    sourcePage: 'OE No. & Brand SKU',
                    inputText: this.oeNoBrandSKUInput
                },
            }))
            this.$emit('update:visible', false)
        }

        inputBlur() {
            let el:any = this.$refs.historyDetail
            setTimeout(() => {
                el.style.display = 'none'
            }, 300)
        }

        inputFocus() {
            this.input()
        }

        input() {
            let el:any = this.$refs.historyDetail
            if (!this.oeNoBrandSKUInput.trim().length) {
                el.style.display = 'none'
                return
            }
            el.style.display = 'block'
            fn(this)
        }
    }
