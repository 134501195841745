import Vue from 'vue'


/**
 * 检测图片是否存在
 * @param url
 */
let imageIsExist = url => new Promise(resolve => {
    let img = <any> new Image()
    img.onload = function () {
        if (img.complete == true) {
            resolve(true)
        }
    }
    img.onerror = function () {
        resolve(false)
    }
    img.src = url
})

// 判断当前图片是否能够加载成功，可以加载成功则赋值为img的src属性，否则使用默认图片
Vue.directive('defaultImg', async (el, binding) => {
    // el.setAttribute('src', '../src/assets/images/pic_small.png');
    if (binding.value && (binding.value === binding.oldValue)) {
        return
    }
    let imgURL = binding.value// 获取图片地址
    if (imgURL) {
        let exist = await imageIsExist(imgURL)
        if (exist) {
            el.setAttribute('src', imgURL)
        }
    }
})

// 加入购物车动画
Vue.directive('cart-fly', (el, binding, vnode) => {
    if (binding.value === binding.arg   // 列表中多个飞入购物车指令  
    || binding.value === 'active')      // 单个购物车指令
    {
        const eleFlyCartWrapper: any = document.querySelector('.animation-cart-wrapper')
        const eleFlyCart: any = document.querySelector('.animation-cart')
        const eleAddToCartBtn: any = el
        const eleCart: any = document.querySelector('.icon-shopping-cart')

        const boundBtn = eleAddToCartBtn.getBoundingClientRect()
        const boundCart = eleCart.getBoundingClientRect()
        // 中心点的水平垂直距离
        const offsetX = boundCart.left + boundCart.width / 2 - (boundBtn.left + boundBtn.width / 2)
        const offsetY = boundCart.top + boundCart.height / 2 - (boundBtn.top + boundBtn.height / 2)
        let isRunning = false
        if (isRunning === false) {
            // 购物车图形出现与初始定位
            eleFlyCartWrapper.style.display = 'block'
            eleFlyCartWrapper.style.left = (boundBtn.left + eleAddToCartBtn.clientWidth / 2) + 'px'
            eleFlyCartWrapper.style.top = (boundBtn.top + eleAddToCartBtn.clientHeight / 2) + 'px'
            // 开始动画
            eleFlyCartWrapper.style.transform = 'translateX(' + offsetX + 'px)'
            eleFlyCart.style.transform = 'translateY(' + offsetY + 'px)'
            // 动画标志量
            isRunning = true
            setTimeout(() => {
                eleFlyCartWrapper.style.display = ''
                eleFlyCartWrapper.style.transform = 'translateX(0)'
                eleFlyCart.style.transform = 'translateY(0)';
                (document.querySelector('.shop-cart-icon') as HTMLElement).classList.value = 'el-tooltip iconfont icon-Icon_cart shop-cart-icon animation'
                setTimeout(() => {
                    (document.querySelector('.shop-cart-icon') as HTMLElement).classList.value = 'el-tooltip iconfont icon-Icon_cart shop-cart-icon'
                }, 500)
                isRunning = false
            }, 490)
        }
    }
})