/**
 * SessionStorage和LocalStorage的封装
 */
const storage = {
    setLocal(key, value) {
        window.localStorage.setItem(key, JSON.stringify(value))
    },
    getLocal(key) {
        const value = window.localStorage.getItem(key) || ''
        return value ? JSON.parse(value) : ''
    },
    setSession(key, value) {
        window.sessionStorage.setItem(key, JSON.stringify(value))
    },
    getSession(key) {
        const value = window.sessionStorage.getItem(key) || ''
        return value ? JSON.parse(value) : ''
    },
    clearOneLocal(key) {
        window.localStorage.removeItem(key)
    },
    clearOneSession(key) {
        window.sessionStorage.removeItem(key)
    },
    clearAllLocal() {
        window.localStorage.clear()
    },
    clearAllSession() {
        window.sessionStorage.clear()
    },
}

export default storage
