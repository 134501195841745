












































    import {
        Vue, Component, Prop,
    } from 'vue-property-decorator'
    import {State} from 'vuex-class'
    import Cookies from 'js-cookie'
    import api from '~/api'

@Component(<any> {
    components: {
    },
})
    export default class LoginAuditResult extends Vue {
    @State('langCode') langCode

    @State('loginAuditResultVisible') loginAuditResultVisible

    @State('auditStatus') auditStatus

    closeDlg() {
        this.$store.commit('SET_AUDIT_RESULT_STATUS', {loginAuditResultVisible: false, auditStatus: this.auditStatus})
         if (this.auditStatus && Cookies.get('tokenType') === 'CIAM') {
            api.logout_ciam({
                loginSource: 'EGG_MALL_WEB',
            }).then(res => {
                    let resData = res.data
                    this.$store.commit('CLEAR_DATA', this)
                    window.location.href = resData
                    window.event.returnValue = false
                    Cookies.remove('tokenType')
            })
        }
// this.$emit('updateToken')
    }

    handleApplyAgain() {
        this.$store.commit('SET_LOGIN_MODAL', true)
        this.$store.commit('SET_LOGIN_COMPLETE_ADDRESS', true)
    }
    }
